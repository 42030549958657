let constants = {
    getTimeUrl: "https://us-central1-loksewamcq-f8491.cloudfunctions.net/getTime",
    getQuestionPageUrl: "https://us-central1-loksewamcq-f8491.cloudfunctions.net/getQuestionPage",
    getLiveExamCardUrl: "https://us-central1-loksewamcq-f8491.cloudfunctions.net/getLiveExamDetail",
    getLiveExamDetailFromList:"https://us-central1-loksewamcq-f8491.cloudfunctions.net/getLiveExamDetailFromListewamcq-f8491/us-central1/getLiveExamDetailFromList",
    // generateResult:"http://127.0.0.1:5000/loksewamcq-f8491/us-central1/calculateResult",
    generateResult:"https://us-central1-loksewamcq-f8491.cloudfunctions.net/calculateResult",
    generatePracticeSet: "https://us-central1-loksewamcq-f8491.cloudfunctions.net/generatePracticeExam",
    favIconUrl: "https://firebasestorage.googleapis.com/v0/b/loksewamcq-f8491.appspot.com/o/image%2Fdefault%2FfavIcon.png?alt=media&token=a688107c-4b5c-478b-832f-5bab3453903e",
    logoUrl: "https://firebasestorage.googleapis.com/v0/b/loksewamcq-f8491.appspot.com/o/image%2Fdefault%2Flogo.png?alt=media&token=6357adad-d17c-4e85-931e-31886f5957ea",
}

export default constants