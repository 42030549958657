import { createSlice } from "@reduxjs/toolkit";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";


let loginSlice = createSlice({
  name: "loginInfo",
  initialState: {
    login: false,
    loginAs: "",
    email: "",
    contactNo: "",
    name: "",
    id: "",
    profilePic: "",
    DOB: "",
    gender: "",
    address: "",
    liveExams:[],
    results: [],
  },
  reducers: {
    login: (state, action) => {
      state.login = true;
      state.loginAs = action.payload.loginAs;
      state.email = action.payload.email;
      state.name = action.payload.name;
    },
    //Don't remove setName (It is important for editing profile)
    setName: (state, action) => {
      state.name = action.payload.name;
    },
    setContactNo: (state, action) => {
      state.contactNo = action.payload.contactNo;
    },
    setId: (state, action) => {
      state.id = action.payload.id;
    },
    setProfilePic: (state, action) => {
      state.profilePic = action.payload.profilePic;
    },
    setDOB: (state, action) => {
      state.DOB = action.payload.DOB;
    },
    setGender: (state, action) => {
      state.gender = action.payload.gender;
    },
    setAddress: (state, action) => {
      state.address = action.payload.address;
    },
    setResults: (state, action) => {
      state.results = action.payload.results;
    },
    setLiveExams:(state,action)=>{
      state.liveExams = action.payload.liveExams
    },
    logOut: (state, action) => {
      state.login = false;
      state.loginAs = "";
      state.email = "";
      state.name = "";
      state.id = "";
      state.results = [];
      state.contactNo = ""
      state.liveExams = []
    },
    fetchResults:(state) =>{
      // console.log("is this working")
      getDoc(doc(db,collection("student",state.id))).then((data)=>{
        let res = data.data()
        // console.log("fetchData",res)
        state.results = res.results
        // console.log("results",state.results)
      })
    }
  },
});

export default loginSlice;
