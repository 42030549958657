// import React, { useEffect, useState } from 'react';
// import ChapterSidebar from './components/ChapterSidebar';

// const mockFetchChapters = async () => {
//   // Mocked data for demonstration. Replace this with actual data fetching logic.
//   return [
//     {
//       title: 'Chapter 1',
//       childrens: [
//         { title: 'Section 1.1', childrens: [] },
//         { title: 'Section 1.2', childrens: [] },
//       ],
//     },
//     {
//       title: 'Chapter 2',
//       childrens: [
//         { title: 'Section 2.1', childrens: [] },
//         { title: 'Section 2.2', childrens: [] },
//       ],
//     },
//   ];
// };

// const Chapter = () => {
//   const [initialData, setInitialData] = useState([mockFetchChapters()]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const data = await mockFetchChapters();
//       setInitialData(data);
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <ChapterSidebar data={initialData} />
//     </div>
//   );
// };

// export default Chapter;

// Purano code 
import { useLoaderData } from "react-router";
import { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import Popup from "reactjs-popup";
import { db } from "../../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  query,
  doc,
  where,
  getDocs,
} from "firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import useAuth from "../../../customHooks/useAuth";
import { useNavigate } from "react-router";
import Navbar from "../../../components/Navbar/Navbar";

let Chapter = () => {
  const currentUser = useAuth();
  let navigate = useNavigate();
  let authenticationSuccessed = setTimeout(() => {
    navigate("/admin");
  }, 3 * 1000);
  useEffect(() => {
    return () => {
      clearTimeout(authenticationSuccessed);
    };
  });
  if (currentUser) {
    let name = currentUser.displayName?.split(":");
    if (name ? name[1] === "admin" : false) {
      clearTimeout(authenticationSuccessed);
      return (
        <>
          <Navbar admin={true} />
          <ChapterProtected />
        </>
      );
    }
  }
};

let ChapterProtected = () => {
  const refresh = () => window.location.reload(true);
  let getChapterPreFormat = (chapters) => {
    let result = {};
    for (let data of chapters) {
      let { field, title } = data;
      let inter = result;
      for (let f of field) {
        if (!inter[f]) {
          inter[f] = {};
        }
        inter = inter[f];
      }
      if (inter.title) {
        inter.title = [...inter.title, title];
      } else {
        inter.title = [title];
      }
    }
    return result;
  };

  let realod = async () => {
    let q = query(collection(db, "chapter"));
    let instance = await getDocs(q);
    let result = [];
    instance.forEach((data) => {
      result.push(data.data());
    });
    result = getChapterPreFormat(result);
    setLoad(result);
    setField([]);
    setChapter("");
    setChapterError(false);
    setChapterMessage("");
    setPopup(false);
    setFieldError(false);
    setFieldErrorMessage("");
  };

  let loader = useLoaderData();
  loader = getChapterPreFormat(loader);
  let [load, setLoad] = useState(loader);
  let [field, setField] = useState([]);
  let [chapter, setChapter] = useState("");
  let [chapterError, setChapterError] = useState(false);
  let [chapterMessage, setChapterMessage] = useState("");
  let [popup, setPopup] = useState(false);
  let [fieldError, setFieldError] = useState(false);
  let [fieldErrorMessage, setFieldErrorMessage] = useState("");
  let [snackBarOpen, setSnackBarOpen] = useState(false);
  let [snackBarMessage, setSnackBarMessage] = useState("");
  let getChild = () => {
    let count = 0;
    let l = load;
    for (let i of field) {
      l = l[i];
    }
    let result = Object.keys(l);
    return result.map((m) => {
      if (m === "title") {
        return l.title.map((dat, ind) => {
          return (
            <div
              key={ind}
              style={{
                display: "flex",
                margin: "4px",
                flexDirection: "row",
              }}
            >
              <strong style={{ flex: "0.05 1 10px" }}>{++count}</strong>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: "1 1 20px",
                }}
              >
                <div style={{ margin: "0px 8px 0px 3px", color: "black" }}>
                  {dat}
                </div>
                <DeleteIcon
                  color="error"
                  sx={{
                    fontSize: "20px",
                    marginLeft: "30px",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    try {
                      let q = query(
                        collection(db, "chapter"),
                        where("title", "==", dat),
                        where("field", "==", field)
                      );
                      let docc = await getDocs(q);
                      let result = [];
                      docc.forEach((d) => result.push(d.id));
                      let docs = await getDocs(
                        query(
                          collection(db, "question"),
                          where("chapter", "==", [...field, dat])
                        )
                      );

                      //delete questions here...
                      docs.forEach(async (d) => {
                        await deleteDoc(doc(collection(db, "question"), d.id));
                      });

                      //now delete that chapter...
                      // console.log("got this titile = ", dat);
                      // console.log("got this field = ", field);
                      docc.forEach(async (d) => {
                        // console.log("chapter id = ", d.id);
                        // console.log("chapter to be deleted = ", d.data());
                        await deleteDoc(doc(collection(db, "chapter"), d.id));
                      });

                      // await realod();
                      setSnackBarOpen(true);
                      setSnackBarMessage("Item deleted");
                      setTimeout(() => {
                        refresh();
                      }, 2000);
                    } catch (error) {
                      setSnackBarOpen(true);
                      setSnackBarMessage("Not Deleted. Try Again Please.");
                    }
                  }}
                />
              </div>
            </div>
          );
        });
      }
      return (
        <div
          style={{
            display: "flex",
            margin: "4px",
            flexDirection: "row",
          }}
        >
          <strong style={{ flex: "0.05 1 10px" }}>{++count}</strong>
          <strong style={{ flex: "1 1 20px", color: "black" }}>
            {m.toUpperCase()}
          </strong>
        </div>
      );
    });
  };

  return (
    <>
      <Grid
        container
        alignItems="stretch"
        spacing={1}
        style={{
          fontSize: "15px",
          backgroundColor: "rgb(238,238,228)",
          margin: "5px",
          padding: "5px",
          minHeight: "80vh",
          marginTop: "7rem",
          opacity: popup ? 0.3 : 1,
        }}
      >
        <Grid item xs={12} md={8} lg={9} style={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "3vh 0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "80%",
              }}
            >
              <div>&#x2022; Enter chapter name : </div>
              <div
                style={{
                  margin: "10px 0",
                }}
              >
                <input
                  value={chapter}
                  onChange={(event) => {
                    setChapter(event.target.value);
                  }}
                  placeholder="Chapter Name"
                  style={{
                    backgroundColor: "rgb(238,238,228)",
                    outline: "none",
                    border: "none",
                    height: "35px",
                    width: "100%",
                    fontSize: "15px",
                    padding: "15px",
                  }}
                />
                {chapterError ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "10px",
                    }}
                  >
                    {chapterMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <ChpaterDrawer
              field={field}
              data={load}
              load={load}
              setField={setField}
              position={0}
              setPopup={setPopup}
              setLoad={setLoad}
            />

            {field.map((_x, y) => {
              let l = load;
              for (let i = 0; i <= y; i++) {
                l = l[field[i]];
              }
              return (
                <ChpaterDrawer
                  key={y}
                  field={field}
                  data={l}
                  load={load}
                  setField={setField}
                  position={y + 1}
                  setPopup={setPopup}
                  setLoad={setLoad}
                />
              );
            })}
            {fieldError ? <span>{fieldErrorMessage}</span> : ""}
            <Button
              variant="contained"
              color="success"
              style={{
                margin: "10px",
                fontSize: "12px",
                padding: "4px 50px",
              }}
              onClick={async (event) => {
                if (chapter === "") {
                  setChapterError(true);
                  setChapterMessage("Chapter can not be empty");
                }
                if (field.length === 0) {
                  setFieldError(true);
                  setFieldErrorMessage("Field cannot be empty");
                }
                if (field.length !== 0 && chapter !== "") {
                  let result = await addDoc(collection(db, "chapter"), {
                    title: chapter,
                    field: field,
                  });
                  if (result) {
                    setSnackBarOpen(true);
                    setSnackBarMessage("Successfully added");
                  }
                  await realod();
                  //To show a message when a document has been successfully added
                  refresh();
                }
              }}
            >
              Add
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div
            style={{
              backgroundColor: "white",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              alignItems: "stretch",
              color: "rgb(155,133,255)",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              Fields
            </div>
            {field.map((f, index) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      margin: "4px",
                      flexDirection: "row",
                    }}
                  >
                    <strong
                      style={{
                        flex: "0.05 1 10px",
                      }}
                    >
                      {index + 1}{" "}
                    </strong>
                    <strong
                      style={{
                        flex: "1 1 30px",
                        color: "black",
                      }}
                    >
                      {f.toUpperCase()}
                    </strong>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                margin: "4px",
                textAlign: "center",
                fontSize: "20px",
                marginTop: "10%",
                fontWeight: 600,
              }}
            >
              Childrens
            </div>
            {getChild()}
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBarOpen}
        message={snackBarMessage}
        autoHideDuration={3000}
        onClose={() => {
          setSnackBarOpen(false);
          setSnackBarMessage("");
        }}
      />
    </>
  );
};

const RenderPopupElements = ({ index, fieldValue }) => {
  if (index === 0)
    return (
      <>
        {"1> "} {fieldValue}
      </>
    );
  else {
    let i;
    let indicator = "1";
    for (i = 1; i <= index; i++) {
      indicator += ".1";
    }
    indicator += "> ";
    return (
      <>
        {indicator} {fieldValue}
      </>
    );
  }
};

let ChpaterDrawer = (props) => {
  let [expanded, setExpanded] = useState(false);
  let [message, setMessage] = useState("Select the field");
  let { data, field, setField, position, setPopup, setLoad, load } = props;
  let [list, setList] = useState(Object.keys(data));
  let [stateData, setStateData] = useState(list);
  let [fieldList, setFieldList] = useState([]);
  let [input, setInput] = useState("");

  useEffect(() => {
    setList(Object.keys(data));
    setStateData(Object.keys(data));
    if (field.length === position) {
      setMessage("Select the field");
    } else {
      setStateData(
        Object.keys(data).filter((d) => {
          return !(d === message);
        })
      );
    }
    setStateData(
      stateData.filter((d) => {
        if (d === "title") return false;
        else return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, field, position, message]);
  if (list.length === 0) return "";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80%",
      }}
    >
      <div>&#x2022; Select or add new field : </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "90%",
            margin: "8px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "stretch",
              backgroundColor: "rgb(238,238,228)",
              padding: expanded ? "10px 10px" : "0px",
            }}
          >
            <div
              onClick={() => {
                setExpanded(!expanded);
              }}
              style={{
                width: "100%",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                padding: "6px 15px ",
                justifyContent: "space-between",
              }}
            >
              <div>{message}</div>
              <div>
                {expanded ? (
                  <ExpandLessIcon
                    fontSize="large"
                    onClick={() => {
                      setExpanded(false);
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    fontSize="large"
                    onClick={() => {
                      setExpanded(true);
                    }}
                  />
                )}
              </div>
            </div>
            {expanded
              ? stateData.map((d, i) => {
                return (
                  <div key={i}>
                    <hr />
                    <div
                      onClick={() => {
                        let a;
                        if (message === "Select the field") {
                          a = stateData.filter((da) => {
                            return !(da === d);
                          });
                        } else {
                          a = stateData.map((da) => {
                            return da === d ? message : da;
                          });
                        }
                        setMessage(d);
                        setStateData(a);
                        setExpanded(!expanded);
                        let n = [...field.slice(0, position), d];
                        setField(n);
                      }}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        flexDirection: "row",
                        padding: "6px 15px ",
                        justifyContent: "space-between",
                      }}
                    >
                      {d}
                    </div>
                  </div>
                );
              })
              : ""}
          </div>
        </div>
        <Popup
          trigger={(open) => {
            if (open) {
              setPopup(true);
            } else {
              setPopup(false);
            }
            return (
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                sx={{
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                Add
              </Button>
            );
          }}
          modal
        >
          {(close) => {
            return (
              <div
                style={{
                  backgroundColor: "rgb(155,133,255)",
                  borderRadius: "13px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    height: "8vh",
                    width: "70vw",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "13px",
                  }}
                >
                  <input
                    placeholder="Enter the new field"
                    value={input}
                    onChange={(event) => {
                      setInput(event.target.value);
                    }}
                    style={{
                      width: "75%",
                      margin: "5px",
                      outline: "none",
                      border: "none",
                      fontSize: "200%",
                      padding: "3px 13px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={(event) => {
                      if (input === "") {
                        return;
                      }
                      setFieldList([...fieldList, input]);
                      setInput("");
                    }}
                  >
                    Add Sub field
                  </Button>
                </div>
                {fieldList.map((fld, ind) => {
                  return (
                    <div
                      key={ind}
                      style={{
                        height: "8vh",
                        width: "70vw",
                        backgroundColor: "rgb(155,133,255)",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        placeholder="Enter the new field"
                        style={{
                          width: "75%",
                          margin: "5px",
                          outline: "none",
                          border: "none",
                          fontSize: "200%",
                          padding: "3px 13px",
                          color: "white",
                        }}
                      >
                        <RenderPopupElements index={ind} fieldValue={fld} />
                      </div>

                      <Button
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={() => {
                          setFieldList(fieldList.slice(0, ind));
                          setInput("");
                        }}
                      >
                        remove
                      </Button>
                    </div>
                  );
                })}
                <div style={{ textAlign: "center" }}>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => {
                      let interData = data;
                      for (let d of fieldList) {
                        interData[d] = {};
                        interData = interData[d];
                      }
                      setLoad(load);
                      let f = field.slice(0, position);
                      f = [...f, ...fieldList];
                      setMessage(fieldList[0]);
                      setField([...f]);
                      close();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            );
          }}
        </Popup>
      </div>
    </div>
  );
};

export default Chapter;