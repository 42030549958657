import React from "react";
import "./LiveExamComponent.css";
import HomeExamCard from "../HomeExamCard/HomeExamCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getDataFromServer } from "../../pages/main/LiveExamPage/handler";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const LiveExamComponent = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1500, min: 993 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 993, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let [liveExamData, setLiveExamData] = useState([]);
  useEffect(() => {
    getDataFromServer("", setLiveExamData);
  }, []);

  if (liveExamData.length < 1) {
    return;
  }

  return (
    <div className="LiveExamComponent">
      <div className="LiveExamComponent_top">
        <h2 className="LiveExamComponent_top_header">Live Exams For You</h2>
        <Link to="./live-exam-page"
          className="LiveExamComponent_link Link">
          See All
        </Link>
      </div>
      <Carousel
        responsive={responsive}
        infinite={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        className="carousel"
      >
        {
          liveExamData.map((set, index) => {
            let price = set.price;
            try {
              price = parseInt(price);
            } catch (error) {
              // no nothing... int parse error...
            }
            return (
              <Link to="/live-exam-detail" key={index}
                className="Link" state={set}>
                <HomeExamCard
                  key={index}
                  id={set.id}
                  time={set.date}
                  title={set.title}
                  description={set.description.toString().substring(0, 80)}
                  price={price === 0 ? "Free" : `Rs. ${price}`}
                  icon={1}
                />
              </Link>
            );
          })
        }
      </Carousel>
    </div>
  );
};

export default LiveExamComponent;
