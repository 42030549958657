import React from "react";
import "./FeatureCard.css";

const FeatureCard = ({ image, title, subTitle }) => {
  return (
    <div className="feature_card">
      <img src={image} alt="" className="feature_card_image" />
      <h1 className="feature_card_header">
        {title}
      </h1>
      <span className="feature_card_text">
        {subTitle}
      </span>
    </div>
  );
};

export default FeatureCard;
