import React, { useState } from "react";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../Logo";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import loginSlice from "../../state/slices/loginSlice";
import SideNav from "../SideNav/SideNav";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const NavbarAdmin = ({ admin }) => {
  let dispatch = useDispatch();
  const [openNav, setOpenNav] = useState(false);

  const login = useSelector((state) => state.loginInfo.login);

  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
    setOpenNav((prev) => !prev);
  };

  return (
    <nav className="nav">
      {openNav && <SideNav Close={navToggle} admin={admin} />}
      <div className="nav_logo">
        <Link to="/admin/profile">
          <Logo width={70} />
        </Link>
      </div>

      <div className="nav__menu__mid">
        <li className="nav__item">
          <Link to="/admin/question">Questions</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/add-tags">Tags</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/chapter">Chapters</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/live-exam-page">Live Exam</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/practice-set-page">Practice Set</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/syllabus-page">Syllabus</Link>
        </li>
        <li className="nav__item">
          <Link to="/admin/vacancy-page">Vacancy</Link>
        </li>
      </div>

      <div className="nav__menu__right">
        <li className="nav__item">
          <Link
            to={login ? "/admin/profile" : "/admin"}
            className="flex_item margin_right"
          >
            <div>{login ? "Profile" : "Login"}</div>
          </Link>
        </li>
        <li className="nav__item">
          <Link to={login ? "/admin" : "/admin/signup"} className="flex_item">
            <div
              onClick={() => {
                dispatch(loginSlice.actions.logOut());
                signOut(auth);
              }}
            >
              {login ? "Log Out" : "Sign Up"}
            </div>
          </Link>
        </li>
      </div>

      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};
export default NavbarAdmin;
