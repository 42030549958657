import LiveQuestions from './components/LiveQuestions'
import style from './LiveExamQuestionPageStyle'
import { useLocation, useLoaderData } from 'react-router';
import { useState, useEffect } from 'react';
import PopUpContent from './components/PopUpContent';
import { useNavigate } from 'react-router';
import useAuth from '../../../customHooks/useAuth';
import Navbar from '../../../components/Navbar/Navbar';

let LiveExamQuestionPage = () => {
    const currentUser = useAuth();
    let navigate = useNavigate()
    let authenticationSuccessed = setTimeout(() => {
        navigate('/admin');
    }, 3 * 1000)
    useEffect(() => {
        return () => {
            clearTimeout(authenticationSuccessed);
        }
    })
    if (currentUser) {
        let name = currentUser.displayName?.split(':');
        if (name ? name[1] === "admin" : false) {
            clearTimeout(authenticationSuccessed)
            return (
                <>
                    <Navbar admin={true} />
                    <LiveExamQuestionPageProtected />
                </>
            );
        }
    }
}

let LiveExamQuestionPageProtected = () => {
    let { state } = useLocation();
    let listOfTags = useLoaderData()
    let [questions, setQuestions] = useState(state.questions);
    let [newQuestion, setNewQuestions] = useState({
        question: "",
        answer: ['', '', '', ''],
        correct: -1,
        marks: 0,
        description: ""
    })
    let bttnHandler = (i) => {
        questions.splice(i, 1);
        setQuestions([...questions]);
    }
    let addBttnHandler = () => {
        setQuestions([...questions, newQuestion])
        setNewQuestions({
            question: "",
            answer: ['', '', '', ''],
            correct: -1,
            marks: 0,
            description: ""
        })
    }

    let [mistake, setMistake] = useState(false)
    let [questionMarks, setQuestionMarks] = useState(0);

    useEffect(() => {
        setMistake(parseInt(questionMarks) === parseInt(state.fullMarks))
    }, [questionMarks, state.fullMarks])

    useEffect(() => {
        let marks = 0;
        questions.forEach(element => {
            marks += parseInt(element.marks)
        });
        setQuestionMarks(marks);
    }, [questions])

    let handler = (i, data) => {
        questions[i] = { ...questions[i], ...data };
        setQuestions([...questions])
    }
    let newHandler = (i, data) => {
        setNewQuestions({ ...newQuestion, ...data });
    }
    return (
        <>
            <div style={{
                ...style.infoSection, marginTop: "85vh", marginLeft: "80vw",
                backgroundColor: (mistake) ? "#2998EC" : "red"
            }}>
                <div>Total questions : {questions.length}</div>
                <div>Total marks : {state.fullMarks}</div>
                <div>Questions marks : {questionMarks}</div>
                <div></div>
            </div>
            <div style={style.mainPage}>
                {questions.map((q, i) => {
                    return <LiveQuestions question={{ ...q, index: i }} key={i} handler={handler} bttnHandler={bttnHandler} addNew={false} />
                })}
                <LiveQuestions question={{ ...newQuestion, index: questions.length }} handler={newHandler} bttnHandler={addBttnHandler} addNew={true} />
            </div>
            {(mistake) ? <div style={style.bttnContainer}>
                <PopUpContent listOfTags={listOfTags} data={{ ...state, questions: questions }} />
            </div> : <div style={style.errElement}>Full Marks and total marks does not match </div>}
        </>
    )
}

export default LiveExamQuestionPage;
