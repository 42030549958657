import { useNavigate } from 'react-router';
import useAuth from '../../../customHooks/useAuth';
import { useEffect, useState } from 'react';
import '../../main/ProfilePage/ProfilePage.css'
import { useSelector } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import Navbar from '../../../components/Navbar/Navbar';
import capitalizeFirstLetter from '../../../utils/CapatilizeFirstLetter';
import "./AdminProfile.css";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import Popup from "reactjs-popup";
import { Typography } from '@mui/material';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase';
import { doc, getDoc } from "firebase/firestore";
import { simplifyDate } from '../../../customHooks/dateHook';
import { arrayUnion, deleteDoc, updateDoc } from 'firebase/firestore';

let AdminProfile = () => {
    const currentUser = useAuth();
    let navigate = useNavigate()
    let authenticationSuccessed = setTimeout(() => {
        navigate('/admin');
    }, 3 * 1000)
    useEffect(() => {
        return () => {
            clearTimeout(authenticationSuccessed);
        }
    })
    if (currentUser) {
        let name = currentUser.displayName?.split(':');
        if (name ? name[1] === "admin" : false) {
            clearTimeout(authenticationSuccessed)
            return <AdminProfileProtected />
        }
    }
}

let AdminProfileProtected = () => {

    let navigate = useNavigate()
    let { name, email, type } = useSelector((state) => {
        return {
            name: state.loginInfo.name,
            email: state.loginInfo.email,
            type: state.loginInfo.loginAs
        }
    })

    let LogOutHandler = async () => {
        let auth = getAuth();
        await signOut(auth);
        navigate('/login');
    }

    // let [loading, setLoading] = useState(false);
    let [pendingPayment, setPendingPayment] = useState([]);
    let [pendingUserData, setPendingUserData] = useState([]);

    useEffect(() => {
        function fetchPendingData() {
            getDocs(collection(db, "pendingPayment"))
                .then((querySnapshot) => {
                    let l = []
                    querySnapshot.forEach((doc) => {
                        l.push({ ...doc.data(), id: doc.id });
                    });
                    setPendingPayment(l);
                });
        }
        fetchPendingData();
    }, []);

    useEffect(() => {
        //use stdId to get std info...
        async function fetchStdInfo() {
            let l = [];
            for (let index = 0; index < pendingPayment.length; index++) {
                const data = pendingPayment[index];
                const docRef = doc(db, "student", data.stdId);
                await getDoc(docRef).then((docSnap) => {
                    l.push(docSnap.data());
                });
            }
            setPendingUserData(l);
        }
        fetchStdInfo();
    }, [pendingPayment]);

    let denyPayment = async (payment) => {
        await deleteDoc(doc(db, "pendingPayment", payment.id))
        setPendingPayment(pendingPayment.filter((data) => {
            return payment.id !== data.id
        }))
    }

    let acceptPayment = async (payment) => {
        // console.log(payment)
        let student = doc(db, "student", payment.stdId)
        await updateDoc(student, {
            liveExams: arrayUnion(payment.examId)
        })
        await deleteDoc(doc(db, "pendingPayment", payment.id))
        setPendingPayment(pendingPayment.filter((data) => {
            return payment.id !== data.id
        }))
    }
    return (
        <>
            <Navbar admin={true} />
            <div className="profilePage">
                <div className="profilePage_left">
                    <div className="profilePage_left_top">
                        <div className="profilePage_left_top_grey"></div>
                        <div className="profilePage_left_top_letter">
                            {name[0]}
                        </div>
                    </div>
                    <div className="profilePage_left_name">
                        <span className="profilePage_left_name_nm">{capitalizeFirstLetter(name)}</span>
                        <div className="line">
                            <span className="profilePage_left_name_pf">{capitalizeFirstLetter(type)}</span>
                        </div>
                    </div>
                    <div className="profilePage_left_bottom">
                        <div className="profilePage_left_bottom_item">
                            <div>Gmail: {email}</div>
                        </div>
                        {
                            email === "sanjayshri112@gmail.com" ?
                                <div className="profilePage_left_bottom_item" onClick={(_event) => {
                                    navigate('/admin/add-admin');
                                }}>
                                    Add Admin
                                </div>
                                : ""
                        }
                        <div className="profilePage_left_bottom_item" onClick={() => { navigate('/change-password') }}>
                            <span>Change Passowrd</span>
                        </div>
                        <div className="profilePage_left_bottom_item" onClick={LogOutHandler}>
                            <span> <LogoutIcon className="margin-b-5" /> Log Out</span>
                        </div>
                    </div>
                </div>
                <div className="profilePage_right">
                    <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
                        <h3 style={{ fontWeight: "bold" }}>{email === "sanjayshri112@gmail.com"? "Pending Exam Purchases" : "Want to Add Live Exam ?"}</h3>
                        <a href="/admin/live-exam-page">
                            Add New Exam
                        </a>
                    </div>

                    <div className="profilePage_right_exams">
                        {email === "sanjayshri112@gmail.com" ?
                            pendingPayment.length < 1 ?
                                "You don't have any pending payment." :
                                <div className='pending_cards_grid'>
                                    {
                                        pendingPayment.map((data, index) => {
                                            let userData = pendingUserData[index];
                                            return (
                                                <div key={index} className='pending_card'>
                                                    <div><b>Name:</b> {userData?.name || ""} </div>
                                                    <div><b>Email:</b> {userData?.email || ""}</div>
                                                    <div><b>Phone Num:</b> {userData?.contactNumber || ""}</div>
                                                    <div><b>Exam ID:</b> {data?.examId || ""}</div>
                                                    <div><b>Date:</b> {simplifyDate({ dateObject: data?.date }) || ""}</div>
                                                    <div style={{
                                                        display: "flex", justifyContent: "space-around",
                                                    }}>
                                                        <Popup
                                                            trigger={
                                                                <div className='pending_card_btn pending_card_red_btn'>
                                                                    <DeleteIcon /> Spam
                                                                </div>
                                                            }
                                                            modal
                                                        >
                                                            {(close) => {
                                                                return (
                                                                    <div className='pending_card_popup'>
                                                                        <Typography variant="h6" sx={{ mb: "15px" }}>
                                                                            Are you sure you want to <b>Delete</b> this pending?
                                                                        </Typography>
                                                                        <div style={{
                                                                            display: "flex", justifyContent: "center"
                                                                        }}>
                                                                            <div className="pending_card_btn pending_card_popup_btn
                                                                             pending_card_green_btn"
                                                                                onClick={async () => {
                                                                                    await denyPayment(data)
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Yes
                                                                            </div>
                                                                            <div className="pending_card_btn pending_card_popup_btn
                                                                             pending_card_red_btn"
                                                                                onClick={async () => {
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Close
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Popup>
                                                        <Popup
                                                            trigger={
                                                                <div className='pending_card_btn pending_card_popup_btn
                                                                 pending_card_green_btn'>
                                                                    <DoneIcon /> Approve
                                                                </div>
                                                            }
                                                            modal
                                                        >
                                                            {(close) => {
                                                                return (
                                                                    <div className='pending_card_popup'>
                                                                        <Typography variant="h6" sx={{ mb: "15px" }}>
                                                                            Are you sure you want to <b>Approve</b> this pending?
                                                                        </Typography>
                                                                        <div style={{
                                                                            display: "flex", justifyContent: "center"
                                                                        }}>
                                                                            <div className="pending_card_btn pending_card_popup_btn
                                                                             pending_card_green_btn"
                                                                                onClick={async () => {
                                                                                    await acceptPayment(data)
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Yes
                                                                            </div>
                                                                            <div className="pending_card_btn pending_card_popup_btn
                                                                             pending_card_red_btn"
                                                                                onClick={async () => {
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Close
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Popup>
                                                    </ div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            : ""
                        }
                    </div>

                </div>
            </div >
        </>
    )
}

export default AdminProfile;