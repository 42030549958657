import React from "react";
import "./HomeHero.css";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { Link } from "react-router-dom";
import ImageSlider from "../../pages/main/HomePage/components/ImageSlider/ImageSlider";

const HomeHero = () => {
  return (
    <div className="hero_container">
      <div className="hero_container_left">
        <div className="hero_container_left_box">
          <h1 className="hero_container_left_header">
            Preparing{" "}
            <span className="text_color">Online is now much easier</span>
          </h1>
          <p className="hero_container_left_text">
            <span className="text_color">LOKSEWABOOK,</span> a platform that
            teaches in an interactive way.
          </p>
          <div className="hero_container_left_box_bottom">
            <Link to="./mcq-book-page"
              className="hero_container_left_box_bottom_button">
              Get Started
            </Link>
            <Link to="./practice-set-page"
              className="hero_container_left_box_bottom_button_two">
              <PlayCircleFilledWhiteIcon className="hero_container_left_box_bottom_button_two_icon" />
              <div>View Practice Sets</div>
            </Link>
          </div>
        </div>
      </div>
      <ImageSlider />
    </div>
  );
};

export default HomeHero;
