import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, addDoc, collection, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import {updateAnswerHandler,updateMarkForReviewHandler} from "../../pages/other/ExamPlace/handler";

const fetchLiveExam = createAsyncThunk(
  "liveExamSlice/fetchLiveExam",
  async (action) => {
    let result = await getDocs(
      query(
        collection(db, "liveExamResult"),
        where("examId", "==", action.id),
        where("student", "==", action.student)
      )
    );
    if (result.empty) {
      let answerData= action.loader.questions.map((_d)=>{
        return {
          option:-1,
          markedForReview:false
        }
      })
      let addedDoc = await addDoc(collection(db, "liveExamResult"), {
        examId: action.id,
        student: action.student,
        answerData:answerData
      });
      return {...action,id:addedDoc.id,answerData:{
        answerData:answerData,
        markedForReview:answerData
      }}
    } else {
      let r = [];
      let id = [];
      result.forEach((d) => {
        r.push(d.data());
        id.push(d.id);
      });
      return {
        ...action,
        answerData :r[0].answerData,
        id:id[0],
      };
    }
  }
);

let liveExamSlice = createSlice({
  name: "liveExamSlice",
  initialState: {
    index: 0,
    answerData: [],
    isInitialized: false,
    load: {},
    timeLeft:0,
    answered: 0,
    resultId:"",
    endOfExam:false
  },
  reducers: {
    updateIndex: (state, action) => {
      let inter = state.index + action.payload.position;
      if (inter >= 0 && inter < state.load.questions.length) {
        state.index = inter;
      }
    },
    updateIndexByPosition: (state,action)=>{
      state.index = action.payload.index
    },
    updateAnswered:(state,action)=>{
      state.answered= state.answered+action.payload.answered;
    },
    updateAnswerOption: (state, action) => {
      let x = [...state.answerData];
      if (action.payload.option > -1) {
        x[state.index] = {
          ...x[state.index],
          option: action.payload.option,
        };
      } else {
        x[state.index] = {
          ...x[state.index],
          option: action.payload.option,
        };
      }
      state.answerData = x;
      updateAnswerHandler(state.resultId,state.position,action.payload.option,state.answerData)
    },
    updateMarkForReview: (state, ) => {
      let x = [...state.answerData];
      let y = !x[state.index].markedForReview
      x[state.index] = {
        ...x[state.index],
        markedForReview: y,
      };
      state.answerData = x;
      updateMarkForReviewHandler(state.resultId,state.position,y,state.answerData)
    },
    updateLeftTime:(state,action)=>{
      //TODO: to perfrom automatic submission when the timer is up;
      state.timeLeft = state.timeLeft -1 ;
      if(state.timeLeft === 0){
        state.endOfExam = true
      }
    },
    updateTimeFromServer:(state,action)=>{
      //TODO: to perform the automatic submission when the timer is up;
      let date = state.load.date;
      let endTime= state.load.endTime;
      let start = new Date(`${date}:${action.payload.hours}:${action.payload.minutes}:${action.payload.seconds}`);
      let end = new Date(`${date}:${endTime}`);
      state.timeLeft = (end-start)/1000;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchLiveExam.pending, (state) => {
      state.isInitialized = false;
    });
    builder.addCase(fetchLiveExam.fulfilled, (state, action) => {
      // console.log(action)
      state.isInitialized = true;
      state.load = action.payload.loader;
      if(Array.isArray(action.payload.answerData)){
        state.answerData = action.payload.answerData.map((d) => {
          return d;
        });
      }else{
        state.answerData= action.payload.loader.questions.map((_d)=>{
          return {
            option:-1,
            markedForReview:false
          }
        })
      }
      let date = action.payload.loader.date;
      let endTime= action.payload.loader.endTime;
      let currentDate = new Date(Date.now());
      currentDate.setMilliseconds(0);
      let start = new Date(currentDate);
      let end = new Date(`${date}:${endTime}`);
      state.timeLeft = (end-start)/1000;
      state.resultId= action.payload.id
    });
    builder.addCase(fetchLiveExam.rejected, (state) => {
      state.isInitialized = false;
    });
  },
});

export { liveExamSlice, fetchLiveExam };
export default liveExamSlice;
