import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Navbar from "../../../components/Navbar/Navbar";
import "./AllQuestions.css";
import fetchAllQuestions from "./utils/fetchAllQuestions";

const PAGE_SIZE = 15;

const AllQuestions = () => {
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
        fetchQuestionsByPage();
    }, [currentPage]);

    async function fetchQuestionsByPage() {
        setLoading(true);
        try {
            const fetchedQuestions = await fetchAllQuestions(lastDoc, setLastDoc, PAGE_SIZE);
            setQuestions(fetchedQuestions);
        } catch (error) {
            console.error('Error fetching questions: ', error);
        } finally {
            setLoading(false);
        }
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <>
            <Navbar />
            <div className="display_all_questions">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="daq_questions_div">
                        {questions.map((question, index) => (
                            <div key={index} className="daq_question_div"
                                onClick={() => {
                                    const cleanTitle = question.data.question.replace(/ /g, '-');
                                    const encodedTitle = encodeURIComponent(cleanTitle);
                                    navigate(`/ask-question/${question.id}?title=${encodedTitle}`);
                                }}
                            >
                                <div className="daq_question" >
                                    Q. {question.data.question}
                                </div>
                                <div className="daq_answers">
                                    {question.data.answer.map((ans, index) => (
                                        <div className="daq_answer" key={index}>
                                            {index + 1}. {ans}
                                        </div>
                                    ))}
                                </div>
                                <hr />
                            </div>
                        ))}
                    </div>
                )}
                <div className="daq_pagination">
                    <button className="daq_pagination_btn" onClick={handlePrevPage} disabled={currentPage === 1}>
                        Prev
                    </button>
                    <span style={{ margin: "0 3rem" }} >{currentPage}</span>
                    <button className="daq_pagination_btn" onClick={handleNextPage}>
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default AllQuestions;