import { db } from "../../../../firebase";
import { collection, query, orderBy, getDocs, startAfter, limit } from "firebase/firestore";

async function fetchAllQuestions(startIndex, setLastDoc, pageSize) {
    let listo = [];

    try {
        const querySnapshot = await getDocs(
            query(
                collection(db, "question"),
                orderBy("timestamp"),
                startAfter(startIndex),
                limit(pageSize)
            )
        );

        querySnapshot.forEach((doc) => {
            // listo.push(doc.data());
            listo.push({ id: doc.id, data: doc.data() });
        });

        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastDoc(last)

    } catch (error) {
        console.error('Error fetching questions: ', error);
        throw error;
    }

    return listo;
}

export default fetchAllQuestions;
