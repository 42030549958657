import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import LiveQuestions from '../LiveExamQuestions/components/LiveQuestions';
import style from './add_question_csv_style.jsx';
import { Button, Snackbar, Alert } from '@mui/material';
import { db, storage } from '../../../firebase.js';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";

const AddQuestionCSV = () => {
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [description, setDescription] = useState("");
  const [questionMarks, setQuestionMarks] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const chapter = localStorage.getItem('chapterForcsv');
  const getMarks = localStorage.getItem('selectedMarks');
  const MarksValue = parseInt(getMarks, 10);

  const [alertShown, setAlertShown] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const location = useLocation();
  const { csvQuestionsData, path } = location.state || {};

  useEffect(() => {
    console.log("aqc: eData = ", csvQuestionsData);
    if (csvQuestionsData) {
      setDescription(csvQuestionsData.description);
      setQuestions(csvQuestionsData);
    } else {
      if (!alertShown) {
        alert("No Data found. Add Question Manually.");
        setAlertShown(true);
      }
    }
  }, [alertShown]);

  useEffect(() => {
    const totalMarks = questions.reduce((sum, question) => sum + question.marks, 0);
    setQuestionMarks(totalMarks);
  }, [questions]);

  const bttnHandler = (i) => {
    const updatedQuestions = questions.filter((_, index) => index !== i);
    setQuestions(updatedQuestions);
  };

  const addBttnHandler = () => {
    const newId = (questions.length + 1).toString();
    setQuestions([...questions, { ...newQuestion, id: newId, index: questions.length }]);
    setNewQuestion({
      id: (questions.length + 2).toString(),
      question: "",
      answer: ['', '', '', ''],
      correct: -1,
      marks: 0,
      description: "",
      index: questions.length + 1,
    });
  };

  const handler = (i, data) => {
    const updatedQuestions = questions.map((q, index) =>
      index === i ? { ...q, ...data } : q
    );
    console.log("updated questions = ", updatedQuestions);
    setQuestions(updatedQuestions);
  };

  const newHandler = (data) => {
    setNewQuestion({ ...newQuestion, ...data });
  };

  const [newQuestion, setNewQuestion] = useState({
    id: (questions.length + 1).toString(),
    question: "",
    answer: ['', '', '', ''],
    correct: -1,
    marks: 0,
    description: "",
    index: questions.length,
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  let uploadedImage = async ({ file, name }) => {
    try {
      let referece = ref(storage, `image/question/${name}`);
      await uploadBytes(referece, file);
      let result = await getDownloadURL(referece);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!submitLoading) {
      setSubmitLoading(true);
      try {
        const questionsCollection = collection(db, 'question');
        await Promise.all(
          questions.map(async (question) => {
            question.chapter = path;
            delete question.id;
            question.timestamp = serverTimestamp();

            let questionImages = [];
            if (question.questionImages) {
              questionImages = await Promise.all(
                question.questionImages.map(async (imgFile) => {
                  let fileName = `${crypto.randomUUID()}---${imgFile.name}`;
                  return {
                    name: fileName,
                    data: await uploadedImage({ file: imgFile, name: fileName }),
                  };
                })
              );
            }

            let explanationImages = [];
            if (question.explanationImages) {
              explanationImages = await Promise.all(
                question.explanationImages.map(async (imgFile) => {
                  let fileName = `${crypto.randomUUID()}---${imgFile.name}`;
                  return {
                    name: fileName,
                    data: await uploadedImage({ file: imgFile, name: fileName }),
                  };
                })
              );
            }

            delete question.questionImages;
            delete question.explanationImages;

            question.questionImage = questionImages;
            question.explanationImage = explanationImages;

            console.log("question data = ", question)

            await addDoc(questionsCollection, question);
          })
        );
        setSnackbarMessage("Questions submitted successfully!");
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        //  
        // Navigate back to the mcqbookpage after a short delay
        setTimeout(() => {
          setSubmitLoading(false);
          navigate('/admin/question');
        }, 1000); // Adjust the delay as needed
      } catch (error) {
        console.error("Error adding document: ", error);
        setSnackbarMessage("Failed to submit questions. Please try again.");
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      <Navbar admin={true} />
      <div style={style.mainPage}>
        {questions.map((q, i) => (
          <LiveQuestions
            question={{ ...q, index: i }}
            key={i}
            handler={handler}
            bttnHandler={bttnHandler}
            addNew={false}
          />
        ))}
        <LiveQuestions
          question={{ ...newQuestion, index: questions.length }}
          handler={(_, data) => newHandler(data)}
          bttnHandler={addBttnHandler}
          addNew={true}
        />
      </div>
      <div style={style.bttnContainer}>
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          {submitLoading ? "Loading..." : "Submit"}
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddQuestionCSV;
