import { createSlice } from "@reduxjs/toolkit";

let resultSlice = createSlice({
    name: "resultInfo",
    initialState: {
        id: "",
        title: "",
        fullMarks: 0,
        passMarks: 0,
        result: [],
        student: {
            email: "",
            id: "",
            name: "",
        },
    },
    reducers: {
        setData: (state, action) => {
            state.id = action.payload.id;
            state.title = action.payload.title;
            state.fullMarks = action.payload.fullMarks;
            state.passMarks = action.payload.passMarks;
            state.result = action.payload.result;
            state.student.id = action.payload.student.id;
            state.student.name = action.payload.student.name;
            state.student.email = action.payload.student.email;
        },
    },
});

export default resultSlice;
