import React, { useState } from "react";

const ScoreBoardQuestion = ({ data, index, answerData }) => {
    let [isCorrect, setIsCorrect] = useState(false)
    // console.log("answerData = ")
    // console.log(answerData);

    return (<>
        <div className="question">
            <div className="question_left">{`${index}.`}</div>
            <div className="question_right">
                <div className="question_right_top" style={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "space-between"
                }}>
                    <div className="question_div">{data.question}</div>
                </div>

                {/* Images for questions here... */}
                <div className="displayImg">
                    {
                        data.questionImage ? data.questionImage.map((imgData, index) => {
                            return (
                                <a key={index} href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                                    <img key={index} src={imgData.data} alt="question" className="questionImg" />
                                </a>
                            );
                        }) : ""
                    }
                </div>

                <div>
                    Status:
                    {(answerData[index - 1].option === -1) ? " Not Answered" :
                        (answerData[index - 1].option === data.correct) ? " Correct" : " Incorrect"
                    }
                </div>

                <div className="question_right_bottom">
                    <div
                        className={
                            (data.correct === 0) ? "right_ans question_right_bottom_answer" :
                                ((data.correct !== answerData[index - 1].option
                                    && answerData[index - 1].option === 0) ?
                                    " incorrect_ans question_right_bottom_answer" :
                                    "question_right_bottom_answer")
                        }
                    >
                        {data.answer[0]}
                    </div>
                    <div className={
                        (data.correct === 1) ? "right_ans question_right_bottom_answer" :
                            ((data.correct !== answerData[index - 1].option
                                && answerData[index - 1].option === 1) ?
                                " incorrect_ans question_right_bottom_answer" :
                                "question_right_bottom_answer")
                    }>{data.answer[1]}</div>
                    <div className={
                        (data.correct === 2) ? "right_ans question_right_bottom_answer" :
                            ((data.correct !== answerData[index - 1].option
                                && answerData[index - 1].option === 2) ?
                                " incorrect_ans question_right_bottom_answer" :
                                "question_right_bottom_answer")
                    }
                    >
                        {data.answer[2]}
                    </div>
                    <div className={
                        (data.correct === 3) ? "right_ans question_right_bottom_answer" :
                            ((data.correct !== answerData[index - 1].option
                                && answerData[index - 1].option === 3) ?
                                " incorrect_ans question_right_bottom_answer" :
                                "question_right_bottom_answer")
                    }>{data.answer[3]}</div>
                </div>
            </div>
        </div>
        <div style={{
            width: "100%",
            color: isCorrect ? "green" : "red",
            padding: "5px 12%",
            fontSize: "15px"
        }}>
            <b>EXPLANATION :</b>
            <div className="explanation_div" style={{ color: "gray" }}>
                {data.explanation === "" ? (data.explanationImage ? "" : "No Explanation") : data.explanation}
                {/* Images for explanation here... */}
                <div className="displayImg">
                    {
                        data.explanationImage ? data.explanationImage.map((imgData, index) => {
                            // console.log("img = ")
                            // console.log(imgData);
                            return (
                                <a key={index} href={imgData.data} target="_blank" rel="noreferrer"
                                    style={{ textDecoration: " none", color: "black" }}>
                                    <img key={index} src={imgData.data} alt="question"
                                        className="questionImg" />
                                </a>
                            );
                        }) : ""
                    }
                </div>
            </div>
        </div>
    </>
    );
};

export default ScoreBoardQuestion;
