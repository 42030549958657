import React from "react";
import NavbarStudent from "./NavbarStudent";
import NavbarAdmin from "./NavbarAdmin";

const Navbar = (props) => {
  let admin = props.admin || false;
  if (!admin) {
    return <NavbarStudent admin={props.admin} />;
  } else {
    return <NavbarAdmin admin={props.admin} />;
  }
};

export default Navbar;
