import React from "react";
import "./GenerateMCQPage.css";
import SelectComponent from "../../../components/SelectComponent/SelectComponent";
import Navbar from "../../../components/Navbar/Navbar";
import { useLoaderData, useNavigate } from "react-router";
import { useState, useEffect } from 'react'
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import constants from "../../../constants";
import { db } from "../../../firebase";
import { getCountFromServer, query, collection, where } from "firebase/firestore";
import Popup from "reactjs-popup";

const GenerateMCQPage = () => {
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [title, setTitle] = useState("")
  let [titleError, setTitleError] = useState("");
  let [description, setDescription] = useState("")
  let [descriptionError, setDescriptionError] = useState("");
  let [time, setTime] = useState(90)
  const [topicList, setTopicList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(0)
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("some random message");
  let [severity, setSeverity] = useState("info");
  let [onClose, setOnClose] = useState((data) => {
    return data;
  })
  let [selectTags, setSelectTags] = useState([])
  let data = useLoaderData().chapter;
  let tags = useLoaderData().tags;
  let [selected, setSelected] = useState([]);
  let [added, setAdded] = useState([]);
  let [addedError, setAddedError] = useState("")
  let getChapterPreFormat = (chapters) => {
    let result = {};
    for (let data of chapters) {
      let { field, title } = data;
      let inter = result;
      for (let f of field) {
        if (!inter[f]) {
          inter[f] = {};
        }
        inter = inter[f];
      }
      if (inter.title) {
        inter.title = [...inter.title, title]
      } else {
        inter.title = [title]
      }
    }
    return result;
  }

  //This function returns true when there is error
  let checkError = () => {
    let res = false
    if (title === "") {
      setTitleError("Title is a required field")
      res = true
    } else {
      setTitleError("")
    }
    if (description === "") {
      setDescriptionError("Description is a required field")
      res = true
    } else {
      setDescriptionError("")
    }
    if (added.length === 0) {
      setAddedError("No question has been selected please select the questions to create a prcatice set")
      res = true
    } else {
      setAddedError("")
    }
    return res
  }


  let resetState = () => {
    setTitle("")
    setDescription("")
    setTopicList([]);
    setChecked([]);
    setSelectedChapter(0)
    setSelectedQuestion(0)
    setOpen(false);
    setMessage("some random message");
    setSeverity("info")
    setOnClose((data) => {
      return data;
    })
    setSelected([])
    setAdded([]);
  }

  data = getChapterPreFormat(data)

  let getAtAPosition = (index) => {
    let result = data
    for (let i = 0; i < index; i++) {
      result = result[selected[i]]
    }
    let keys = Object.keys(result)
    if(keys.length > 1 && keys.includes("title")){
      keys = keys.filter(item => item!== "title")
      return [true, keys.map((d) => {
        return {
          value: d,
          label: d
        }
      }),result.title]
    }
    if (keys[0] === "title") {
      return [false, result["title"],[]];
    }
    return [true, keys.map((d) => {
      return {
        value: d,
        label: d
      }
    }),[]]
  }
  function checkAdded(x, y) {
    for (let elem of added) {
      if (elem.field.length === selected.length) {
        for (let i = 0; i < elem.field.length; i++) {
          if (elem.field[i] === selected[i]) {
            continue;
          } else {
            return false;
          }
        }
        if (elem.chapter === x) {
          if (y.include) {
            elem.number = y.number
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false
      }
    }
  }
  useEffect(() => {
    setSelectedChapter(added.length)
    let x = 0;
    for (let i of added) {
      // console.log(i.number);
      x = x + parseInt(i.number)
    }
    setSelectedQuestion(x)
  }, [added])

  useEffect(() => {
    let a = getAtAPosition(selected.length);
    if (!a[0]) {
      setTopicList([...a[1]])
      let x = []
      for (let i = 0; i < a[1].length; i++) {
        x[i] = {
          added: checkAdded(a[1][i]),
          number: 0,
          count: 0,
        }
      }
      setChecked(x)
    } else {
      setTopicList(a[2])
      let x = []
      for (let i = 0; i < a[2].length; i++) {
        x[i] = {
          added: checkAdded(a[2][i]),
          number: 0,
          count: 0,
        }
      }
      setChecked(x)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, setTopicList, setAdded])

  return (
    <>
      <Navbar admin={true} />
      <div className="generateMCQ">
        <div className="generateMCQ_top">
          <div className="generateMCQ_top_left">
            <p className="generateMCQ_top_left_header">Filter</p>
            <div className="generateMCQ_top_left_items">
              <label className="generateMCQ_label">Select Field:</label>
              <SelectComponent options={getAtAPosition(0)[1]} selected={selected}
                setSelected={setSelected} index={0} />
            </div>
            {
              selected.map((_, index) => {
                let option = getAtAPosition(index + 1)
                if (option[0]) {
                  return <div className="generateMCQ_top_left_items" key={index}>
                    <label className="generateMCQ_label">Select Field:</label>
                    <SelectComponent selected={selected} setSelected={setSelected} index={index + 1} options={option[1]} />
                  </div>
                } else {
                  return ""
                }
              })
            }
            <div className="generateMCQ_top_left_bottom">
              <button className="generateMCQ_top_left_bottom_button">
                Filter
              </button>
            </div>
          </div>
          <div className="generateMCQ_top_right">
            <div className="inputBlock">
              <span>Enter the tittle : </span>
              <input type="text" value={title} onChange={(event) => {
                setTitle(event.target.value)
              }} /><span style={{ color: "red", fontSize: "10px" }}>{titleError}</span>
              <span>
                Enter the description :
              </span>
              <textarea value={description} rows={10} onChange={(event) => {
                setDescription(event.target.value)
              }} />
              <span style={{ color: "red", fontSize: "10px" }}>{descriptionError}</span>
              <span>Enter the time for the examination in minutes: </span>
              <input type="number" min="10" max="240" value={time} onChange={(e) => { setTime(e.target.value) }}></input>
              <h3>Choose Tags for the practice set</h3>
              <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", justifyContent: "start", width: "100%" }}>
                {tags.map((t, i) => {
                  return <Button key={i} onClick={() => {
                    if (selectTags.includes(t)) {
                      let index = selectTags.indexOf(t)
                      selectTags.splice(index, 1)
                      setSelectTags([...selectTags])
                    } else {
                      setSelectTags([...selectTags, t])
                    }
                  }} variant="contained" color={selectTags.includes(t) ? "success" : "info"}>{t}</Button>
                })}
              </div>
            </div>
            <hr></hr>
            <h3 className="generateMCQ_top_right_header">Chapters</h3>
            {added.length > 0 ? <table style={{ width: "100%" }}>
              <th style={{ textAlign: "center" }}>S.N.</th>
              <th style={{ textAlign: "center" }}>Chapter</th>
              <th style={{ textAlign: "center" }}>Number</th>
              <th style={{ textAlign: "center" }}>Remove</th>
              {
                added.map((a, index) => {
                  return <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{a.chapter}</td>
                    <td style={{ textAlign: "center" }}>{a.number}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button sx={{ fontSize: "16px", margin: "0", padding: "0" }} color="error"
                        onClick={() => {
                          a = added.filter((_, i) => {
                            if (i === index) return false
                            else return true
                          })
                          setAdded([...a])
                        }}>X</Button></td>
                  </tr>
                })
              }
            </table> : ""}
            <div className="generateMCQ_top_right_main">
              <div className="generateMCQ_top_right_main_left">
                <div className="generateMCQ_top_right_main_left_content">
                  Select Multiple Chapters
                </div>

                {topicList.map((item, index) => (
                  <div key={index} className="generateMCQ_top_right_main_left_content">
                    <div>
                      <input type="checkbox"
                        className="generateMCQ_checkbox"
                        checked={checked[index].added ??= false}
                        onChange={async () => {
                          const coll = collection(db, "question");
                          const q = query(coll, where("chapter", "array-contains", item));
                          const snapshot = await getCountFromServer(q);
                          const count = snapshot.data().count;
                          // console.log('count: ', count);

                          let x = checked;
                          x[index].added = !x[index].added
                          if (x[index].added) {
                            x[index].count = count;
                          } else {
                            x[index].count = 0;
                          }
                          setChecked([...x])
                        }} />
                    </div>
                    <label htmlFor="">
                      {item}
                    </label>
                  </div>
                ))}
              </div>
              <div className="generateMCQ_top_right_main_right">
                <div className="generateMCQ_top_right_main_right_content">
                  Enter the Number of Questions
                </div>
                {topicList.map((_, index) => (
                  <div className="generateMCQ_top_right_main_right_content" key={index}>
                    {checked[index].added ?
                      <div style={{ marginRight: "8px", minWidth: "60px" }}>Max : {checked[index].count}</div>
                      : <div style={{ marginRight: "8px", minWidth: "60px" }}></div>
                    }
                    <input
                      type="number"
                      min="0"
                      max={checked[index].count}
                      placeholder="Enter"
                      value={checked[index].number}
                      className="generateMCQ_input"
                      onChange={(event) => {
                        let x = checked;
                        x[index].number = event.target.value
                        setChecked([...x])
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="generateMCQ_top_right_bottom">
              <button className="generateMCQ_top_right_bottom_button" onClick={() => {
                let a = []
                checked.forEach((b, i) => {
                  if (b.added) {
                    if (!checkAdded(topicList[i], { include: true, number: b.number })) {
                      a.push({
                        field: selected,
                        chapter: topicList[i],
                        number: b.number
                      })
                    }
                  }
                })
                setAdded([...added, ...a])
              }}>
                Include
              </button>
            </div>
            <div style={{ color: "red", fontSize: "10px", marginTop: "10px" }}>{addedError}</div>
          </div>
        </div>
        <div className="generateMCQ_bottom">
          <div className="generateMCQ_bottom_items">
            <span className="generateMCQ_bottom_items_span">
              Selected Chapters: {selectedChapter}
            </span>
          </div>
          <div className="generateMCQ_bottom_items">
            <span className="generateMCQ_bottom_items_span">
              Total Questions: {selectedQuestion}
            </span>
          </div>
          <div className="generateMCQ_bottom_items">
            <button className="generateMCQ_bottom_items_button"
              onClick={async () => {
                if (checkError()) {
                  return
                }
                if (loading === false) {
                  setLoading(true);
                  try {
                    setOpen(true)
                    setMessage("Please wait, Generating Practice Set.")
                    setSeverity("info")
                    let result = await fetch(constants.generatePracticeSet, {
                      method: "POST",
                      headers: {
                        'Content-Type': "application/json"
                      },
                      body: JSON.stringify({
                        list: added,
                        title: title,
                        time: time,
                        description: description,
                        tags: selectTags
                      })
                    })
                    result = await result.json();
                    if (result.status) {
                      setOpen(true);
                      setMessage("Practice set has been successfully created.");
                      setSeverity("success")
                      setOnClose(() => () => {
                        setOpen(false)
                        setMessage("")
                        resetState()
                      })
                      setTimeout(() => {
                        setLoading(false);
                        navigate('/admin/practice-set-page');
                      }, 4500);
                    } else {
                      setLoading(false);
                      setOpen(true);
                      setMessage("Practice set failed to create");
                      setSeverity("error");
                      setOnClose(() => () => {
                        setOpen(false)
                        setMessage("")
                      })
                    }
                  } catch (error) {
                    setLoading(false);
                    setOpen(true);
                    setMessage("Practice set failed to create");
                    setSeverity("error");
                    setOnClose(() => () => {
                      setOpen(false)
                      setMessage("")
                    })
                  }
                }
              }}>
              {loading ? "Loading..." : "Generate Set"}
            </button>
          </div>
        </div>
        <Snackbar open={open} onClose={onClose} autoHideDuration={2000}>
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default GenerateMCQPage;
