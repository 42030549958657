import React from "react";
import "./ExamCard.css";
import { Link, useNavigate } from "react-router-dom";
import { giveExtraSpace } from "../../utils/GiveExtraSpace";
import Popup from "reactjs-popup";
import { Button } from "@mui/material";
import { db, storage } from "../../firebase";
import { doc, getDoc, deleteDoc, query, collection, where, getDocs, updateDoc, arrayRemove } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useState } from "react";
import { Alert, Snackbar, Typography } from "@mui/material"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const ExamCard = (props) => {
  const refresh = () => window.location.reload(true);

  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);

  let navigate = useNavigate();
  let date = new Date(props.data.date);
  let start = new Date(`${props.data.date}:${props.data.startTime}`);
  let end = new Date(`${props.data.date}:${props.data.endTime}`);

  let forProfile = props.forProfile || false;
  let admin = props.admin || false;

  props.data.profile = true
  let [loading, setLoading] = useState(false);
  // console.log("props", props);
  props.data.contains = props.contains

  let handelDelete = async ({ docId }) => {
    //now get document here...
    // console.log(docId)
    const docRef = doc(db, "liveExam", docId);
    const docSnap = await getDoc(docRef);

    // if doc exists...
    if (docSnap.exists()) {
      const data = docSnap.data();
      // delete question images...
      for await (const question of data.questions) {
        if (question.questionImages) {
          const imgs = question.questionImages;
          for (const img of imgs) {
            const imgRef = ref(storage, `image/liveExamQuestions/${img.name}`);
            try {
              await deleteObject(imgRef);
            } catch (err) {
              throw Error("Question Image Deletion Failed.");
            }
          }
        }
        if (question.explanationImages) {
          const imgs = question.explanationImages;
          for (const img of imgs) {
            const imgRef = ref(storage, `image/liveExamQuestions/${img.name}`);
            try {
              await deleteObject(imgRef);
            } catch (err) {
              throw Error("Explanation Image Deletion Failed.");
            }
          }
        }
      }

      // delete live exam purchased by student...
      let q = query(collection(db, "student"), where("liveExams", "array-contains", docId))
      let students = await getDocs(q)
      let std = []
      students.forEach((d) => {
        std.push({ ...d.data(), id: d.id })
      })
      for await (const student of std) {
        let ref = doc(db, "student", student.id)
        let index = -1
        // console.log("std  = ", student.results);
        for (let i = 0; i < student.results.length; i++) {
          let r = student.results[i]
          if (r.examId === docId) {
            index = i;
            break;
          }
        }

        if (index > -1) {

          let ress = student.results.splice(index, 1)
          // console.log("ress = ", ress);
          // console.log("resultId1 =", ress[0].resultId)
          // console.log("sss = ", student.results);

          let ressRef = doc(collection(db, "result"), ress[0].resultId)
          //try deleting result from "result" collection
          try {
            await deleteDoc(ressRef)
          } catch (error) {
            throw Error("Deletion from result collection failed.");
          }

          //try deleting result from "student" collection
          try {
            await updateDoc(ref, {
              liveExams: arrayRemove(docId),
              results: student.results
            })
          } catch (error) {
            throw Error("Deletion from student collection failed.");
          }
        } else {
          try {
            await updateDoc(ref, {
              liveExmas: arrayRemove(docId)
            })
          } catch (err) {
            // console.log("error in the arrayRemove")
            // console.log(err)
            throw Error("Deletion of associated result with this exam failed.");
          }
        }
      }

      // delete intermidiate live exam result...
      q = query(collection(db, "liveExamResult"), where("examId", "==", docId))
      let result = await getDocs(q)
      let res = []
      result.forEach((data) => {
        res.push({ ...data.data(), id: data.id })
      })
      // console.log(res)
      for await (const r of res) {
        try {
          await deleteDoc(doc(db, "liveExamResult", r.id))
        } catch (err) {
          // console.log(err)
          throw Error("Deletion of associated result with this exam failed.");
        }
      }

      // delete pending payment if any...
      q = query(collection(db, "pendingPayment"), where("examId", "==", docId))
      let payment_s = await getDocs(q)
      let payment = []
      payment_s.forEach((p) => {
        payment.push({
          ...p.data(),
          id: p.id
        })
      })
      // console.log(payment)
      for await (const pay of payment) {
        try {
          await deleteDoc(doc(db, "pendingPayment", pay.id))
        } catch (err) {
          // console.log(err)
          throw Error("Deletion of pending payment failed.");
        }
      }

      // delete qrcodes for this live exam if any...
      const qrRef = doc(db, "qrCodes", docId);
      const qrSnap = await getDoc(qrRef);

      if (qrSnap.exists()) {
        // console.log("Document data:", qrSnap.data());
        // let d = qrSnap.data();
        //delete image from storage...
        const imgRef = ref(storage, `image/qrCode/${docId}`);
        try {
          //delete image from storage...
          await deleteObject(imgRef);
          //delete img link from docs...
          await deleteDoc(qrRef)
        } catch (error) {
          // console.log("i triggered it.")
          throw Error("Deletion of Qr Code Failed.");
        }

      } else {
        // console.log("QR Code Doesn't Exist For This Exam!");
      }

      // delete thumbnail for this live exam if any...
      const thumbnailRef = doc(db, "examThumbnail", docId);
      const thumbnailSnap = await getDoc(thumbnailRef);

      if (thumbnailSnap.exists()) {
        // let d = thumbnailSnap.data();
        //delete image from storage...
        const imgRef = ref(storage, `image/examThumbnail/${`liveExam---${docId}`}`);
        try {
          //delete image from storage...
          await deleteObject(imgRef);
          //delete img link from docs...
          await deleteDoc(thumbnailRef)
        } catch (error) {
          throw Error("Deletion of thumbnail Failed");
        }

      } else {
        // console.log("thumbnail Doesn't Exist For This Exam!");
      }

      //then delete all the document for this live exam...
      try {
        await deleteDoc(docRef);
      } catch (error) {
        throw Error("Deletion of Live Exam Failed.");
      }
    } else {
      throw Error("No such Exam Exist!");
    }

    refresh();
  };

  let price = props?.data?.price || 0;
  try {
    price = parseInt(price);
  } catch (error) {
    // no nothing... int parse error...
  }

  return (
    <div className="examCard">
      <div className="examCard_top">
        <div className="examCard_top_header">
          {forProfile ?
            <span style={{ fontWeight: "bolder" }}>
              {props?.data?.title}
            </span>
            : <span className="examCard_top_header_content">
              {props?.data?.title}
              {props?.data?.title.length < 40 ?
                <>{giveExtraSpace(55)}</>
                : <></>
              }
            </span>
          }
          {admin ?
            <div className="exam_add_photo_div"
              onClick={() => {
                if (admin) {
                  navigate("../add-thumbnail", {
                    state: {
                      type: "liveExam",
                      examId: props?.data?.id,
                    },
                  });
                }
              }} >
              <AddPhotoAlternateIcon className="exam_add_photo" /> </div> : ""
          }
          {/* <div className="examCard_bottom examCard_bottom_button"
            onClick={() => {
              if (admin) {
                navigate("../add-qr-code", {
                  state: {
                    price: props?.data?.price || 0,
                    examId: props?.data?.id,
                  },
                });
              }
            }}>
            Add QR Code
          </div> */}
        </div>
        <div className="examCard_top_content">
          <span className="examCard_top_content_p">
            Available: <span>{date.toDateString()}</span>
          </span>
          <span className="examCard_top_content_p">
            Available Time: <span>{start.toLocaleTimeString().toString().slice(0, 5) + start.toLocaleTimeString().toString().slice(8)} to {end.toLocaleTimeString().toString().slice(0, 5) + end.toLocaleTimeString().toString().slice(8)}</span>
          </span>
          {/* <span className="examCard_top_content_p">
            Duration: <span>2 hours</span>
          </span> */}
          {
            forProfile ? "" :
              <>
                <span className="examCard_top_content_p">
                  Price: <span>{price === 0 ? "Free" : `Rs. ${price}`}</span>
                </span>
                <span className="examCard_top_content_p">
                  By: <span>{props?.data?.author}</span>
                </span>
              </>
          }
        </div>
      </div>
      {admin ?
        <div className="examCard_btns">
          <div className="examCard_bottom examCard_bottom_button"
            onClick={() => {
              if (admin) {
                navigate("../add-qr-code", {
                  state: {
                    price: props?.data?.price || 0,
                    examId: props?.data?.id,
                  },
                });
              }
            }}>
            Add QR Code
          </div>
          <Popup trigger={<button className="examCard_bottom examCard_bottom_button" >
            Delete</button>} modal>{
              (close) => {
                return <div className="delete_popup">
                  <p>Are you sure you want to delete ?</p>
                  <div>
                    <Button variant="contained" sx={{ marginRight: "15px" }}
                      color="error"
                      onClick={async () => {
                        if (!loading) {
                          setLoading(true);
                          try {
                            await handelDelete({ docId: props?.data.id });
                          } catch (error) {
                            console.log("error caught = ", error.message);
                            setAutoHideDuration(3000)
                            setMessage("Please Try Again.");
                            setSeverity("error")
                            setOpen(true)
                            setLoading(false);
                          }
                          close();
                        }
                      }}
                    >Yes , I am sure</Button>
                    <Button onClick={close}
                      variant="contained" >Cancel</Button>
                  </div>
                </div>
              }
            }
          </Popup>
        </div>
        :
        <div className="examCard_bottom">
          <Link className="examCard_bottom_button" to="/live-exam-detail" state={props.data}>
            View More Details
          </Link>
        </div>
      }
      <Snackbar open={open} autoHideDuration={autoHideDuration}
        onClose={() => {
          setOpen(false)
          setMessage("")
          setSeverity("info")
          setAutoHideDuration(60 * 3000)
        }}>
        <Alert severity={severity}>
          <Typography variant='h6'>{message}</Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ExamCard;
