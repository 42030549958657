const dataSlider = [
  {
    id: 1,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    img: "https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png",
    review: {
      name: "Prakriti Lama",
      rating: 5,
      totalReview: 12,
      msg: "The platform offers everything that students need to succeed, and the team behind it is dedicated to helping students achieve their career goals.",
    },
  },
  {
    id: 2,
    title: "Lorem ipsum",
    subTitle: "Lorem",
    img: "https://upload.wikimedia.org/wikipedia/en/8/80/Wikipedia-logo-v2.svg",
    review: {
      name: "Prakriti Lama",
      rating: 5,
      totalReview: 12,
      msg: "The platform is ridiculously brilliant in providing the knowledge required to clear the exam. I could recommend every on to use this.",
    },
  },
];

export default dataSlider;
