import { Box } from "@mui/material";

import logo from "../assets/logo.png";

export default function Logo(props) {

    const w = props.width;

    return (
        <Box marginY={1} marginX={1} >
            <img src={logo}
                alt="Logo" width={w} />
        </Box>
    );
}
