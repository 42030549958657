import { useState } from 'react'
import { auth } from '../../../firebase';
import { updatePassword} from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import CopyRight from '../../../components/CopyRight';
import Snackbar from '@mui/material/Snackbar';
// import './addAdmin.css';
import useAuth from '../../../customHooks/useAuth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

//TODO: to implement the authentication process;
let ChangePassword=()=>{
    const currentUser = useAuth();
    let navigate = useNavigate()
    let as = useSelector((state)=>{
        return state.loginInfo.loginAs
    })
    let authenticationSuccessed = setTimeout(() => {
        if(as === "admin") navigate('/admin');
        else navigate('/login')
    }, 3 * 1000)
    useEffect(() => {
      return () => {
        clearTimeout(authenticationSuccessed);
      }
    })
    if (currentUser) {
      let name = currentUser.displayName?.split(':');
      if (name ? name[1] === "student" : false) {
        clearTimeout(authenticationSuccessed)
        return <ChangePasswordProtected />
      }
    }
}


let ChangePasswordProtected = () => {

    let navigate = useNavigate();

    let [open,setOpen] = useState(false);
    let [snackBarMessage,setSnackBarMessage]= useState("");
    let [error, setError] = useState({});
    let [newPassword , setNewPassword] = useState("");
    let [confirmPassword,setConfirmPassword] = useState("");

    let changePasswordClickHandler = async ()=>{
        if(newPassword.length < 8){
            setError("Password should be 8 characters long");
            return;
        }
        if(newPassword === confirmPassword) {
            try{
                await updatePassword(auth.currentUser,newPassword);
                setOpen(true);
                setSnackBarMessage("password changed successfully")
            }catch(error){
                setError("Failed to update the password click on send Email to change the password")
            }
        }
    }

    return (
        <div className="signin">
            <div className="signin_div">
                <div className="signin_div_top">
                    <img src={logo} alt="" className="project_logo" />
                </div>
                <div className="signin_div_form">
                    <div className="signin_div_form_item">
                        <input
                            value={newPassword}
                            onChange={(event) => { setNewPassword(event.target.value) }}
                            type="password"
                            className="signin_div_form_item_input "
                            placeholder="New Password*"
                        />
                           <input
                            value={confirmPassword}
                            onChange={(event) => { setConfirmPassword(event.target.value) }}
                            type="password"
                            className="signin_div_form_item_input "
                            placeholder="ConfirmNew Password*"
                        />
                    </div>
                </div>
                <div style={{width:"100%",color:"red",fontSize:"14px"}}>{error.message}</div>
                <div className="signin_div_button">
                    <button onClick={changePasswordClickHandler}>Change Password</button>
                </div>

                <CopyRight />
            <Snackbar open={open} autoHideDuration={2000}
            message={snackBarMessage}
            onClose={()=>{
                if(window.location.pathname.includes("admin")){
                    navigate('/admin')
                }else{
                    navigate('/login')
                }
            }}/>
            </div>
        </div>
    );
}

export default ChangePassword