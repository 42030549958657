import React, { useState } from "react";
import "./Question.css";
import DeleteIcon from '@mui/icons-material/Delete';
import Popup from "reactjs-popup";
import { Button } from "@mui/material";
import { doc, deleteDoc, updateDoc, } from 'firebase/firestore';
import { db, storage, } from '../../firebase'
import { deleteObject, ref, uploadBytes, getDownloadURL, } from "firebase/storage";
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from "react-redux";

const Question = ({ admin, data, document, setSnackBar, setSnackBarMessage, index }) => {
  let [answered, setAnswered] = useState(false)
  let [isCorrect, setIsCorrect] = useState(false)
  let [ans, setAns] = useState(-1)
  let [hidden] = useState(false)
  if (hidden) return ""
  return (<>
    <div className="question">
      <div className="question_left">{`${index}.`}</div>
      <div className="question_right">
        <div className="question_right_top" style={{
          display: "flex",
          cursor: "pointer",
          justifyContent: "space-between"
        }}>
          <div className="question_div">{data.question}</div>

          {/* hide delete icon if not admin... */}
          {admin ? (
            <div className="question_btns">
              {/* <Popup modal trigger={<EditIcon color="success" fontSize={"large"} />}>
                {
                  (close) => {
                    return <div className="delete_popup">

                    </div>
                  }
                }
              </Popup> */}
              <EditQuestionPopUp
                data={data}
                setSnackBar={setSnackBar}
                setSnackBarMessage={setSnackBarMessage}
              />
              <div style={{ margin: "0 0.8rem" }} />
              <Popup modal trigger={<DeleteIcon color="error" fontSize={"large"} />}>
                {
                  (close) => {
                    return <div className="delete_popup">
                      <div className="delete_popup_title">Are you  sure you want to delete the question ?</div>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly"
                      }}>
                        <Button variant="contained" color="error"
                          onClick={async () => {
                            //delete question img...
                            if (data.questionImage) {
                              data.questionImage.map((imgFile, index) => {
                                const imgRef = ref(storage, `image/question/${imgFile.name}`);
                                deleteObject(imgRef);
                              });
                            }

                            //delete explanation img...
                            if (data.explanationImage) {
                              data.explanationImage.map((imgFile, index) => {
                                const imgRef = ref(storage, `image/question/${imgFile.name}`);
                                deleteObject(imgRef);
                              });
                            }

                            //then delete docs...
                            await deleteDoc(doc(db, "question", document))
                            setSnackBar(true);
                            setSnackBarMessage("Question removed")
                            close()
                          }}>
                          Delete
                        </Button>

                        <Button variant="contained" sx={{ marginLeft: "10px" }} color="primary" onClick={() => {
                          close()
                        }}>Cancel</Button></div>
                    </div>
                  }
                }
              </Popup>
            </div>
          ) : ""}
        </div>

        {/* Images for questions here... */}
        <div className="displayImg">
          {
            data.questionImage ? data.questionImage.map((imgData, index) => {
              return (
                <a href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                  <img key={index} src={imgData.data} alt="question" className="questionImg" />
                </a>
              );
            }) : ""
          }
        </div>

        <div className="question_right_bottom">
          <div onClick={() => { setAnswered(true); setAns(0); if (data.correct === 0) { setIsCorrect(true); setAnswered(!answered); } else { setIsCorrect(false) } }}
            className={
              answered && (ans === 0 || data.correct === 0)
                ? `question_right_bottom_answer ${(0 !== data.correct) ? "wrong_ans" : "right_ans"}`
                : "question_right_bottom_answer"
            }
          >
            {data.answer[0]}
          </div>
          <div onClick={() => { setAnswered(true); setAns(1); if (data.correct === 1) { setIsCorrect(true); setAnswered(!answered); } else { setIsCorrect(false) } }} className={
            answered && (ans === 1 || data.correct === 1)
              ? `question_right_bottom_answer ${(1 !== data.correct) ? "wrong_ans" : "right_ans"}`
              : "question_right_bottom_answer"
          }>{data.answer[1]}</div>
          <div onClick={() => { setAnswered(true); setAns(2); if (data.correct === 2) { setIsCorrect(true); setAnswered(!answered); } else { setIsCorrect(false) } }}
            className={
              answered && (ans === 2 || data.correct === 2)
                ? `question_right_bottom_answer ${(2 !== data.correct) ? "wrong_ans" : "right_ans"}`
                : "question_right_bottom_answer"
            }
          >
            {data.answer[2]}
          </div>
          <div onClick={() => { setAnswered(true); setAns(3); if (data.correct === 3) { setIsCorrect(true); setAnswered(!answered); } else { setIsCorrect(false) } }} className={
            answered && (ans === 3 || data.correct === 3)
              ? `question_right_bottom_answer ${(3 !== data.correct) ? "wrong_ans" : "right_ans"}`
              : "question_right_bottom_answer"
          }>{data.answer[3]}</div>
        </div>
      </div>
    </div>
    <div style={{
      width: "100%",
      color: isCorrect ? "green" : "red",
      padding: "5px 12%",
      fontSize: "15px"
    }}>
      {answered ? <><b>EXPLANATION :</b>
        <div className="explanation_div" style={{ color: "gray" }}>
          {data.explanation === "" ? (data.explanationImage ? "" : "No Explanation") : data.explanation}
          {/* Images for explanation here... */}
          <div className="displayImg">
            {
              data.explanationImage ? data.explanationImage.map((imgData, index) => {
                return (
                  <a href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                    <img key={index} src={imgData.data} alt="question" className="questionImg" />
                  </a>
                );
              }) : ""
            }
          </div>
        </div></> : ""}
    </div>
  </>
  );
};

let EditQuestionPopUp = ({ setSnackBar, setSnackBarMessage, data }) => {

  // console.log("data got this = ", data);
  const refresh = () => window.location.reload(true);

  let [questionError, setQuestionError] = useState(false);
  let [questionErrorMessage, setQuestionErrorMessage] = useState("");
  let [marksError, setMarksError] = useState(false);
  let [marksErrorMessage, setMarksErrorMessage] = useState("");
  let [answerError, setAnswerError] = useState(false);
  let [answerErrorMessage, setAnswerErrorMessage] = useState("");
  let [question, setQuestion] = useState(data.question ?? "");
  let [answer, setAnswer] = useState(data.answer ?? ["", "", "", ""]);
  let [correct, setCorrect] = useState(data.correct ?? -1);
  let [explanation, setExplanation] = useState(data.explanation ?? "");
  let [marks, setMarks] = useState(data.marks ?? 1);
  let [questionImgFile, setQuestionImgFile] = useState([]);
  let [explanationImgFile, setExplanationImgFile] = useState([]);
  let [explanationImgs, setExplanationImgs] = useState(data.explanationImage ?? []);
  let [questionImgs, setquestionImgs] = useState(data.questionImage ?? []);

  let findQuestion = useSelector((state) => {
    return state.findQuestion;
  });

  let chapter = useSelector((state) => {
    return state.chapterInfo;
  });

  let inputStyle = {
    margin: "5px 0 0 0",
    width: "10vw",
    height: "40px",
    backgroundColor: "snow",
    fontSize: "20px",
    padding: "5px",
    outline: "none",
    border: "2px solid rgb(155,133,255)",
  };
  let questionInputStyle = {
    margin: "5px 0 10px 0",
    border: "2px solid rgb(155,133,255)",
    outline: "none",
    backgroundColor: "snow",
    width: "75vw",
    height: "8vh",
    resize: "none",
    padding: "3px 7px",
  };
  let explanationInputStyle = {
    margin: "5px 0 10px 0",
    border: "2px solid rgb(155,133,255)",
    outline: "none",
    backgroundColor: "snow",
    width: "45vw",
    height: "10vh",
    resize: "none",
    padding: "3px 7px",
  };

  let uploadedImage = async ({ file, name }) => {
    try {
      let referece = ref(storage, `image/question/${name}`);
      await uploadBytes(referece, file);
      let result = await getDownloadURL(referece);
      return result;
    } catch (error) {
      throw error;
    }
  };

  let onSubmit = async () => {
    // handeling errror...
    let error = false;
    if (question === "") {
      setQuestionError(true);
      setQuestionErrorMessage("Question can not be empty");
      error = true;
    } else {
      error = false;
    }
    // console.log(error, "at question");

    answer.forEach((ans) => {
      if (ans === "") {
        setAnswerError(true);
        setAnswerErrorMessage("All answer field should be filled");
        error = true;
      } else {
        if (!error) error = false;
      }
    });
    // console.log(error, "at answer");

    if (!(correct >= 0 && correct <= 3)) {
      setAnswerError(true);
      setAnswerErrorMessage("Check the correct answer");
      error = true;
    } else {
      if (!error) error = false;
    }
    // console.log(error, "correct error", correct);

    if (!(marks > 0 && marks < 5)) {
      setMarksError(true);
      setMarksErrorMessage("Marks field must be filled");
      error = true;
    } else {
      if (!error) error = false;
    }
    // console.log(error, "marks error");

    if (!findQuestion) {
      error = true;
    } else {
      if (!error) error = false;
    }
    // console.log(error);

    //now do other things...
    if (!error) {
      //upload question images...
      let questionImages = [];
      questionImages = await Promise.all(
        questionImgFile.map(async (imgFile) => {
          let fileName = `${crypto.randomUUID()}---${imgFile.name}`;

          return {
            name: fileName,
            data: await uploadedImage({ file: imgFile, name: fileName }),
          };
        })
      );

      //upload explanation images...
      let explanationImages = [];
      explanationImages = await Promise.all(
        explanationImgFile.map(async (imgFile) => {
          let fileName = `${crypto.randomUUID()}---${imgFile.name}`;

          return {
            name: fileName,
            data: await uploadedImage({ file: imgFile, name: fileName }),
          };
        })
      );

      const questionRef = doc(db, "question", data.id);
      let result = await updateDoc(questionRef, {
        question: question,
        answer: answer,
        correct: correct,
        marks: marks,
        explanation: explanation,
        chapter: chapter,
        timestamp: Date.now(),
        questionImage: [...questionImages, ...questionImgs],
        explanationImage: [...explanationImages, ...explanationImgs],
      }, { merge: true });
      if (result) {
        setSnackBar(true);
        setSnackBarMessage("Question edited.");
      }
      // console.log(result);
      // console.log("done");
      setAnswer(data.answer ?? ["", "", "", ""]);
      setAnswerError(false);
      setQuestion(data.question ?? "");
      setQuestionError(false);
      setMarks(data.marks ?? 1);
      setQuestionErrorMessage("");
      setCorrect(data.correct ?? -1);
      setExplanation(data.explanation ?? "");
      setQuestionImgFile([]);
      setExplanationImgFile([]);
      setExplanationImgs(data.explanationImage ?? []);
      setquestionImgs(data.questionImage ?? []);
      setAnswerErrorMessage("");
      setMarksError(false);
      setMarksErrorMessage("");
      refresh();
    }
  };

  return (
    <Popup
      modal
      trigger={<EditIcon color="success" fontSize={"large"} />}
    >
      {(close) => {
        return (
          <div
            style={{
              height: "80vh",
              width: "90vw",
              backgroundColor: "snow",
              display: "flex",
              border: "solid 1px black",
              fontSize: "20px",
              borderRadius: "14px",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: "2%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Enter Question :</div>
                <textarea
                  style={questionInputStyle}
                  value={question}
                  onChange={(event) => {
                    setQuestion(event.target.value);
                  }}
                ></textarea>
                {questionError ? (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {questionErrorMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Enter Marks :</div>
                <input
                  type="number"
                  style={{ ...inputStyle, textAlign: "center" }}
                  min="0"
                  max="4"
                  value={marks}
                  onChange={(event) => {
                    setMarks(event.target.value);
                  }}
                ></input>
                {marksError ? (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {marksErrorMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Upload Question Image :</span>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ margin: "5px", border: "none" }}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    const selectedFilesArray = Array.from(selectedFiles);
                    setQuestionImgFile((previousImages) =>
                      previousImages.concat(selectedFilesArray)
                    );
                    event.target.value = "";
                  }}
                />
              </div>
              {/* display question directly from db */}
              {questionImgs.map((img, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={img.data}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = questionImgs.filter(
                          (e) => e !== img
                        );
                        setquestionImgs(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
              {/* now display the imgs selected manually... */}
              {questionImgFile.map((imgFile, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={URL.createObjectURL(imgFile)}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = questionImgFile.filter(
                          (e) => e !== imgFile
                        );
                        setQuestionImgFile(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>Enter Answers : </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 0}
                    onChange={(value) => {
                      setCorrect(0);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[0]}
                    onChange={(event) => {
                      answer[0] = event.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 1}
                    onChange={(event) => {
                      setCorrect(1);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    value={answer[1]}
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    onChange={(event) => {
                      answer[1] = event.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={2 === correct}
                    onChange={(value) => {
                      setCorrect(2);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[2]}
                    onChange={(val) => {
                      answer[2] = val.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 3}
                    onChange={() => {
                      setCorrect(3);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[3]}
                    onChange={(val) => {
                      answer[3] = val.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
              </div>
              {answerError ? (
                <div style={{ color: "red", fontSize: "10px" }}>
                  {answerErrorMessage}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div>Explanation :</div>
                <textarea
                  style={{ ...explanationInputStyle, width: "100%" }}
                  value={explanation}
                  onChange={(val) => {
                    try {
                      setExplanation(val.target.value);
                    } catch (err) {
                      // console.log(err);
                    }
                  }}
                ></textarea>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Upload Explanation Image :</span>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ margin: "5px", border: "none" }}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    const selectedFilesArray = Array.from(selectedFiles);
                    setExplanationImgFile((previousImages) =>
                      previousImages.concat(selectedFilesArray)
                    );
                    event.target.value = "";
                  }}
                />
              </div>
              {explanationImgs.map((img, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={img.data}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = explanationImgs.filter(
                          (e) => e !== img
                        );
                        setExplanationImgs(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
              {explanationImgFile.map((imgFile, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={URL.createObjectURL(imgFile)}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = explanationImgFile.filter(
                          (e) => e !== imgFile
                        );
                        setExplanationImgFile(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onSubmit();
                  close();
                }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{ margin: "0 6px" }}
                onClick={() => {
                  setAnswer(data.answer ?? ["", "", "", ""]);
                  setAnswerError(false);
                  setQuestion(data.question);
                  setQuestionError(false);
                  setMarks(data.marks ?? 1);
                  setQuestionErrorMessage("");
                  setCorrect(data.correct ?? -1);
                  setExplanation(data.explanation ?? "");
                  setAnswerErrorMessage("");
                  setMarksError(false);
                  setMarksErrorMessage("");
                  close();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

export default Question;
