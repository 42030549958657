import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "./McqBookPage.css";
import { Button, Snackbar, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import {
  query,
  collection,
  getDocs,
  where,
  orderBy,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useLoaderData } from "react-router";
import SidebarMob from "../../../components/SidebarMob/SidebarMob";
import Navbar from "../../../components/Navbar/Navbar";
import DisplayQuestions from "./component/DisplayQuestions";
import { Helmet, HelmetProvider } from "react-helmet-async";
import csvFileHandler from "./csvFileHandler";
import PopupCreator from "./component/PopupCreator";
import useMediaQuery from '@mui/material/useMediaQuery';
import ShuffleGivenList from "../../../utils/shuffleGivenList";

const McqBookPage = (props) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [openFilterModal, setOpenFilterModal] = useState(!isDesktop);
  const [marksFilter, setMarksFilter] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const filterButtonClicked = () => {
    setOpenFilterModal(true);
  };

  const closeModal = () => {
    setOpenFilterModal(false);
  };

  const getChapterPreFormat = (chapters) => {
    let result = {};
    for (let data of chapters) {
      let { field, title } = data;
      let inter = result;
      for (let f of field) {
        if (!inter[f]) {
          inter[f] = {};
        }
        inter = inter[f];
      }
      if (inter.title) {
        inter.title = [...inter.title, title];
      } else {
        inter.title = [title];
      }
    }
    return result;
  };

  const getChapterFormat = (obj) => {
    let res = [];
    for (let data of Object.entries(obj)) {
      let a = {};
      if (data[0] === "title") {
        res = [
          ...res,
          ...data[1].map((d) => {
            return { title: d };
          }),
        ];
      } else {
        a = {
          title: data[0],
          childrens: getChapterFormat(data[1]),
        };
        res = [...res, a];
      }
    }
    return res;
  };

  const findQuestion = useSelector((state) => state.findQuestion);
  const chapter = useSelector((state) => state.chapterInfo);

  const [question, setQuestion] = useState([]);
  const [id, setId] = useState([]);

  let loader = useLoaderData();
  loader = getChapterPreFormat(loader);
  loader = getChapterFormat(loader);

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [total, setTotal] = useState(0);
  const [questionLoading, setQuestionLoading] = useState(false);

  useEffect(() => {
    if (findQuestion) {
      setQuestionLoading(true);
      getCountFromServer(
        query(collection(db, "question"), where("chapter", "==", chapter))
      ).then((data) => {
        setTotal(data._data.count);
      });
      let q;
      q = query(
        collection(db, "question"),
        where("chapter", "==", chapter),
        orderBy("timestamp", "desc")
      );
      getDocs(q).then((data) => {
        let d = [];
        let datum = [];
        data.forEach((da) => {
          datum.push(da.id);
          d.push({ ...da.data(), id: da.id });
        });

        //shuffle the data:
        d = ShuffleGivenList(d);

        setQuestion(d);
        setId(datum);
        setQuestionLoading(false);
        // if (openFilterModal) {
        //   // console.log("close 1 mcqpage")
        //   closeModal();
        // }
      });
    }
  }, [findQuestion, chapter, snackBar]);

  const handleCsvUpload = (event) => {
    csvFileHandler(
      event,
      (csvQuestionsData) => {
        let filteredQuestions = [];

        if (marksFilter == 1) {
          filteredQuestions = csvQuestionsData.filter(question => question.marks === 1);
        } else if (marksFilter == 2) {
          filteredQuestions = csvQuestionsData.filter(question => question.marks === 2);
        } else {
          filteredQuestions = csvQuestionsData;
        }

        navigate("/admin/add-question-csv", { state: { csvQuestionsData: filteredQuestions, path: breadcrumbs } });
      },
      (error) => setSnackBarMessage(error),
      () => setSnackBar(true)
    );
  };

  const handleMarksFilterChange = (event) => {
    let newMarkValue = event.target.value;
    setMarksFilter(newMarkValue);
  };

  const handleSidebarClick = (newBreadcrumbs) => {
    setBreadcrumbs(newBreadcrumbs);
  };

  const admin = props.admin || false;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Practice Loksewa MCQs for Free - LoksewaBook | Loksewa Exam Questions</title>
          <meta name="description" content="Practice Loksewa MCQs for free on LoksewaBook. Find Loksewa exam questions, explanations, and answers by subject and faculty. Prepare for Loksewa exams effectively." />
          <meta name="keywords" content="Loksewa MCQs, Practice Loksewa, LoksewaBook, Loksewa exam questions, Loksewa answers, Loksewa practice, Loksewa exam preparation, Loksewa MCQs for free, loksewa aayog gk practice" />
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Practice Loksewa MCQs for Free - LoksewaBook",
              "description": "Practice Loksewa MCQs for free on LoksewaBook. Find Loksewa exam questions, explanations, and answers by subject and faculty. Prepare for Loksewa exams effectively.",
              "url": "https://loksewabook.com/mcq-book-page",
              "inLanguage": "en",
              "datePublished": "2022-12-13",
              "dateModified": "2023-10-31",
              "author": {
                "@type": "Person",
                "name": "Your Name"
              },
              "publisher": {
                "@type": "Organization",
                "name": "LoksewaBook",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://loksewabook.com/logo.png"
                }
              }
            }
            `}
          </script>
        </Helmet>
        <Navbar admin={admin} />
        <div className="practice_page">
          {openFilterModal && <SidebarMob items={loader}
            closeModal={closeModal} onItemClick={handleSidebarClick} />}
          <div className="practice_page_body">
            <div className="practice_page_body_left">
              <Sidebar items={loader} onItemClick={handleSidebarClick} closeModal={closeModal} />
            </div>
            <div className="practice_page_body_right">
              <div className="practice_page_body_right_top">
                <button
                  className="practice_page_body_right_top_button"
                  onClick={filterButtonClicked}
                >
                  Filter
                </button>
              </div>
              <div style={{
                fontSize: "1.8rem",
                margin: "2rem 0 0 3rem",
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }} >
                Questions
              </div>
              <div style={{
                display: "flex",
                justifyContent: "center"
              }} >
                <div className="breadcrumbs">
                  {
                    breadcrumbs.length == 0 ? "" :
                      <span className="breadcrumbs_highlight"
                        style={{ marginRight: "1rem" }}>
                        {">> "}</span>
                  }
                  {breadcrumbs.map((crumb, index) => (
                    <span key={index} className={index == breadcrumbs.length - 1 ? "breadcrumbs_highlight" : ""}>
                      {crumb}
                      <span className="breadcrumbs_highlight">
                        {index < breadcrumbs.length - 1 && " / "}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
              <div className="practice_page_body_right_content">
                {findQuestion && admin && (
                  <div className="action_buttons">
                    <PopupCreator
                      setSnackBar={setSnackBar}
                      setSnackBarMessage={setSnackBarMessage}
                    />
                    <Button
                      variant="outlined"
                      style={{
                        border: "2px solid blue",
                        fontSize: "15px",
                        fontWeight: 800,
                        marginLeft: "10px",
                      }}
                      component="label"
                    >
                      Upload CSV
                      <input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={handleCsvUpload}
                      />
                    </Button>
                    <Select
                      value={marksFilter}
                      onChange={handleMarksFilterChange}
                      displayEmpty
                      style={{ marginLeft: "10px" }}
                    >
                      <MenuItem value="0">Both 1 & 2 Marks</MenuItem>
                      <MenuItem value="1">1 Mark Questions</MenuItem>
                      <MenuItem value="2">2 Marks Questions</MenuItem>
                    </Select>
                  </div>
                )}
                {questionLoading ? (
                  <div>Loading...</div>
                ) : (
                  <DisplayQuestions
                    maxItemPerPage={2}
                    data={question}
                    admin={admin}
                    id={id}
                    setSnackBar={setSnackBar}
                    setSnackBarMessage={setSnackBarMessage}
                  />
                )}
              </div>
            </div>
          </div>
          <Snackbar
            open={snackBar}
            message={snackBarMessage}
            autoHideDuration={3000}
            onClose={() => {
              setSnackBar(false);
              setSnackBarMessage("");
            }}
          />
        </div>
      </HelmetProvider>
    </>
  );
};

export default McqBookPage;
