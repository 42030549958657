import React, { useState, useEffect } from "react";
import "./SidebarItems.css";
import { useSelector, useDispatch } from "react-redux";
import chapterSlice from "../../state/slices/chapterSlice";
import findQuestionSlice from "../../state/slices/findQuestionSlice";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const SidebarItems = ({ item, index, onItemClick, closeModal }) => {
  const unSelectedSpanStyle = { color: "black", cursor: "pointer" };
  const selectedSpanStyle = { color: "blue", cursor: "pointer" };

  const [open, setOpen] = useState(false);
  const [position] = useState(index);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.chapterInfo);

  useEffect(() => {
    if (state[position] === item.title) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state, position, item.title]);

  const handleClick = () => {
    setOpen(!open);
    const newBreadcrumbs = [...state.slice(0, position), item.title];

    if (!open) {
      dispatch(chapterSlice.actions.update(newBreadcrumbs));
      onItemClick(newBreadcrumbs);
    } else {
      dispatch(chapterSlice.actions.update(state.slice(0, position)));
    }

    if (!item.childrens) {
      dispatch(findQuestionSlice.actions.update(true));
      if (closeModal) closeModal();
    }
  };

  if (item.childrens) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={handleClick}>
          <span>{item.title}</span>
          {open ? (
            <RemoveIcon className="sidebar-title_icon toggle-btn" />
          ) : (
            <AddIcon className="sidebar-title_icon toggle-btn" />
          )}
        </div>
        <div className="sidebar-content">
          {item.childrens.map((child, index) => (
            <SidebarItems
              key={index}
              item={child}
              index={position + 1}
              onItemClick={onItemClick}
              closeModal={closeModal}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={open ? "sidebar-item open" : "sidebar-item"}
        style={item.title === state[state.length - 1] ? selectedSpanStyle : unSelectedSpanStyle}
        onClick={handleClick}
      >
        <div className="sidebar-title">
          <span>{item.title}</span>
        </div>
      </div>
    );
  }
};

export default SidebarItems;
