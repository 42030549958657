// Create the csvFileHandler.js file with the following content:
import { parse } from 'csv-parse/browser/esm';

const csvFileHandler = (event, setQuestions, setFileError, setIsProcessingComplete) => {
  const reader = new FileReader();

  reader.onload = (data) => {
    if (data.target?.result) {
      const csvString = data.target.result.toString().trim();
      const lines = csvString.split('\n').slice(1).join('\n');

      parse(
        lines,
        {
          delimiter: ',',
          relax_quotes: true,
          columns: [
            'question',
            'answer1',
            'answer2',
            'answer3',
            'answer4',
            'correct',
            'marks',
            'description',
          ],
        },
        (err, data) => {
          if (err) {
            setFileError('There is a problem in the CSV file.');
          } else {
            const parsedQuestions = data.map((row, index) => ({
              id: (index + 1).toString(),
              question: row.question,
              chapter: '',
              answer: [row.answer1, row.answer2, row.answer3, row.answer4],
              correct: parseInt(row.correct),
              marks: parseInt(row.marks),
              explanation: row.description,
            }));

            setQuestions(parsedQuestions);
            setIsProcessingComplete(true);
          }
        }
      );
    }
  };

  if (event.target.files?.[0]) {
    reader.readAsText(event.target.files[0]);
  }
};

export default csvFileHandler;
