import constants from '../../../constants';

export let getDataFromServer = async (page,setData)=>{
    let response = await fetch(constants.getLiveExamCardUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start: 0,
        }),
      });
      response = await response.json();
      setData(response.data);
}

