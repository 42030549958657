import Popup from "reactjs-popup"
import { Button } from "@mui/material"
import "./FilterPopup.css"
/*
     We are writing this function this function accepts the content that is to be displayed in the
     popup and also contains the trigger and accepts two different functions one to add the selected 
     field into the tags and one to fetch data if the tags have been selected
*/


function Filter({ trigger, content, select, setSelect, applyFilter }) {
    return <Popup trigger={trigger} modal >
        {(close) => {
            return (
                <div className="filter_div">
                    <div className="filter_tags_div" >
                        {content.map((k, i) => {
                            return (
                                <div key={i} className={select.includes(k) ?
                                    'filter_tag_btn filter_tag_selected' : 'filter_tag_btn filter_tag_not_selected'}
                                    onClick={() => {
                                        if (select.includes(k)) {
                                            let index = select.indexOf(k)
                                            select.splice(index, 1)
                                            setSelect([...select])
                                        } else {
                                            setSelect([...select, k])
                                        }
                                    }}>{k}
                                </div>
                            );
                        })}
                    </div>
                    <div className="filter_btns_div" >
                        <div className="filter_apply_btn filter_button"
                            onClick={() => {
                                applyFilter()
                                close()
                            }}>
                            Apply
                        </div>
                        <div className="filter_cancel_btn filter_button"
                            onClick={() => {
                                setSelect([])
                                close()
                            }}>
                            Cancel
                        </div>
                    </div>
                </div>
            );

        }}
    </Popup>
}

export default Filter