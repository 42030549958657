import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import ExamCard from "../../../components/ExamCard/ExamCard";
import useAuth from '../../../customHooks/useAuth';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./ProfilePage.css";
import { useSelector } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import { getStudentData } from './handler';
import Navbar from "../../../components/Navbar/Navbar";
import capitalizeFirstLetter from "../../../utils/CapatilizeFirstLetter";
import ResultTable from "./components/ResultTable";
import { db } from "../../../firebase";
import { doc, getDoc} from "firebase/firestore";


const ProfilePage = () => {
  const currentUser = useAuth();
  let navigate = useNavigate()
  let authenticationSuccessed = setTimeout(() => {
    navigate('/login');
  }, 3 * 1000)
  useEffect(() => {
    return () => {
      clearTimeout(authenticationSuccessed);
    }
  })
  if (currentUser) {
    let name = currentUser.displayName?.split(':');
    if (name ? name[1] === "student" : false) {
      clearTimeout(authenticationSuccessed)
      return <ProtectedProfilePicture />
    }
  }
};


const ProtectedProfilePicture = () => {
  let user = useSelector((state) => {
    return state.loginInfo;
  })

  let [examDetail, setExamDetail] = useState([]);

  useEffect(() => {
    getStudentData(user, setExamDetail);
  }, [user, setExamDetail])

  let navigate = useNavigate()
  let { name, email, type, results, contactNo, profilePic, liveExams } = useSelector((state) => {
    return {
      name: state.loginInfo.name,
      email: state.loginInfo.email,
      type: state.loginInfo.loginAs,
      results: state.loginInfo.results,
      contactNo: state.loginInfo.contactNo,
      profilePic: state.loginInfo.profilePic,
      liveExams: state.loginInfo.liveExams
    }
  })

  useEffect(() => {
    const fetchLiveExams = async () => {
      try {
        const data = await Promise.all(liveExams.map(async (id) => {
          const snapshot = await getDoc(doc(db, "liveExam", id));
          if (snapshot.exists()) {
            const { author, date, description, endTime, fullMarks, negativeMarks, passMarks, price, startTime, title } = snapshot.data();
            return { author, date, description, endTime, fullMarks, negativeMarks, passMarks, price, startTime, title, id };
          }
          return null;
        }));
        setExamDetail(data.filter((item) => item !== null));
      } catch (error) {
        console.error(error);
      }
    };
    fetchLiveExams();
  }, [liveExams]);

  function containsResult(id){
    for(let i of results){
      if(i.examId === id){
        // console.log("The value is true")
        return true
      }
    }
    // console.log("The value is false")
    return false
  } 

  let LogOutHandler = async () => {
    let auth = getAuth();
    await signOut(auth);
    navigate('/login');
  }

  return (
    <>
      <Navbar />
      <div className="profilePage">
        <div className="profilePage_left">
          <div className="profilePage_left_top">
            <div className="profilePage_left_top_grey"></div>
            {
              profilePic ?
                <img
                  src={profilePic}
                  className="profilePage_left_top_image"
                  alt=""
                /> :
                <div className="profilePage_left_top_letter">
                  {name[0]}
                </div>
            }
          </div>
          <div className="profilePage_left_name">
            <span className="profilePage_left_name_nm">{capitalizeFirstLetter(name)}</span>
            <div className="line">
              <span className="profilePage_left_name_pf">{capitalizeFirstLetter(type)}</span>
            </div>
          </div>
          <div className="profilePage_left_bottom">
            <div className="profilePage_left_bottom_item">
              <div>Gmail: {email}</div>
            </div>
            <div className="profilePage_left_bottom_item">
              <div>Contact: {contactNo}</div>
            </div>
            {/*  {<div className="profilePage_left_bottom_item">;
              Live Exam Given:{" "};
              <span className="profilePage_left_bottom_item_span">23</span>;
            </div>} */}
            <div className="profilePage_left_bottom_item" onClick={() => { navigate('/change-password') }}>
              <span><LockIcon className="margin-b-5" /> Change Password </span>
            </div>
            <div className="profilePage_left_bottom_item" onClick={() => { navigate('/edit-profile') }}>
              {<span><EditIcon className="margin-b-5" /> Edit Your Profile</span>}
            </div>
            <div className="profilePage_left_bottom_item" onClick={LogOutHandler}>
              <span><LogoutIcon className="margin-b-5" /> Log Out </span>
            </div>
          </div>
        </div>
        <div className="profilePage_right">

          <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
            <h2 style={{ fontWeight: "bold" }}>Your Live Exams</h2>
            {examDetail.length !== 0 ?
              <a href="/live-exam-page">
                Explore More {"->"}
              </a> :
              <a href="/live-exam-page">
                Explore Exams
              </a>
            }
          </div>
          <div className="profilePage_right_exams">
            {examDetail.length === 0 ?
              <div style={{ marginBottom: "20px", fontSize: "15px" }}>
                <div> You haven't purchased any live Exam.</div>
              </div> :
              <>
                {
                  examDetail.map((detail, i) => {
                    // console.log(detail)
                    return <ExamCard forProfile={true} data={detail} key={i} contains = {containsResult(detail.id)}/>
                  })
                }
              </>
            }
          </div>

          <div className="profilePage_right_result_heading">
            <h2 style={{ fontWeight: "bold" }}>Your recent top results</h2>
          </div>
          {
            results.length < 1 ? <>You don't have any result. Please give exam first. Thank you.</> :
              <ResultTable results={results} liveExams={liveExams} />
          }
        </div>
      </div >
    </>
  )

}

export default ProfilePage;
