import { Link, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import "./PracticeExamCard.css";
import Popup from "reactjs-popup";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import { doc, deleteDoc, getDoc, } from "firebase/firestore";
import { giveExtraSpace } from "../../utils/GiveExtraSpace";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

const PracticeExamCard = (props) => {
  let navigate = useNavigate();
  const refresh = () => window.location.reload(true);
  let admin = props.admin || false;

  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);

  let [loading, setLoading] = useState(false);

  let handelDelete = async ({ docId }) => {
    const docRef = doc(db, "practiceSet", docId);

    // delete thumbnail for this live exam if any...
    const thumbnailRef = doc(db, "examThumbnail", docId);
    const thumbnailSnap = await getDoc(thumbnailRef);

    if (thumbnailSnap.exists()) {
      // let d = thumbnailSnap.data();
      //delete image from storage...
      const imgRef = ref(storage, `image/examThumbnail/${`practiceSet---${docId}`}`);
      try {
        //delete image from storage...
        await deleteObject(imgRef);
        //delete img link from docs...
        await deleteDoc(thumbnailRef)
      } catch (error) {
        throw Error("Deletion of thumbnail Failed");
      }

    } else {
      // console.log("thumbnail Doesn't Exist For This Exam!");
    }

    try {
      await deleteDoc(docRef);
    } catch (error) {
      throw Error("Practice Set Deletion Failed.");
    }
  };

  return (
    <div className="practiceExamCard">
      <Snackbar open={open} autoHideDuration={autoHideDuration}
        onClose={() => {
          setOpen(false)
          setMessage("")
          setSeverity("info")
          setAutoHideDuration(60 * 3000)
        }}>
        <Alert severity={severity}>
          <Typography variant='h6'>{message}</Typography>
        </Alert>
      </Snackbar>
      <div className="practiceExamCard_top">
        <div className="practiceExamCard_top_header">
          <span className="practiceExamCard_top_header_content">
            {props.data.title}
            {props.data.title.length < 40 ? <>{giveExtraSpace(55)}</> : <></>}
          </span>
          {admin ?
            <div className="exam_add_photo_div"
              onClick={() => {
                if (admin) {
                  navigate("../add-thumbnail", {
                    state: {
                      type: "practiceSet",
                      examId: props?.data?.id,
                    },
                  });
                }
              }} >
              <AddPhotoAlternateIcon className="exam_add_photo" /> </div> : ""
          }
        </div>
        <div className="practiceExamCard_top_content">
          <span>{props.data.description.substring(0, 190)}...</span>
          <span className="practiceExamCard_top_content_p">
            Price: <span>Free</span>
          </span>
        </div>
      </div>
      {admin ? (
        <Popup
          trigger={
            <button className="practiceExamCard_bottom practiceExamCard_bottom_button">
              Delete
            </button>
          }
          modal
        >
          {(close) => {
            return (
              <div className="delete_popup">
                <p>Are you sure you want to delete ?</p>
                <div>
                  <Button
                    variant="contained"
                    sx={{ marginRight: "15px" }}
                    color="error"
                    onClick={async () => {
                      try {
                        if (!loading) {
                          setLoading(true);
                          await handelDelete({ docId: props?.data.id });
                          refresh();
                        }
                      } catch (error) {
                        console.log("error caught = ", error.message);
                        setAutoHideDuration(3000)
                        setMessage("Please Try Again.");
                        setSeverity("error")
                        setOpen(true)
                        setLoading(false);
                      }
                      close();
                    }}
                  >
                    {loading ? "Loading..." : "Yes, I am sure."}
                  </Button>
                  <Button onClick={close} variant="contained">
                    Cancel
                  </Button>
                </div>
              </div>
            );
          }}
        </Popup>
      ) : (
        <div className="practiceExamCard_bottom">
          <Link
            className="practiceExamCard_bottom_button"
            sx={{ textDecoration: "none", color: "white" }}
            href={`/practice-set-detail/${props?.data.id}`}
          >
            View More Details
          </Link>
        </div>
      )}
    </div>
  );
};

export default PracticeExamCard;
