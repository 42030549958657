import React from "react";
import "./RecentVacancy.css";

const RecentVacancy = ({ thumbnail, title, otherVacancies }) => {
  return (
    <div className="recentVacancy">
      <div className="recentVacancy_top">
        <img
          src={thumbnail.data}
          alt=""
          className="recentVacancy_top_image"
        />
      </div>
      <div className="recentVacancy_bottom">
        <p className="recentVacancy_bottom_para">
          {title}
        </p>
      </div>
    </div>
  );
};

export default RecentVacancy;
