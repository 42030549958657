import React from "react";
import "./AboutLokSewa.css";
import Group17 from "../../assets/Group17.png";
import { Link } from "react-router-dom";

const AboutLokSewa = () => {
  return (
    <div className="about_loksewa">
      <div className="about_loksewa_top">
        <h1 className="about_loksewa_top_header">What is LOKSEWABOOK?</h1>
        <span className="about_loksewa_top_text">
          Loksewabook is an excellent online platform for those preparing for
          civil services exams in Nepal. Its comprehensive range of study
          materials and assessment tools make it an ideal choice for students
          looking to achieve their career goals.
        </span>
      </div>
      <div className="about_loksewa_mid">
        <div className="about_loksewa_mid_left">
          <span className="about_loksewa_mid_text">FOR BEGINEERS</span>
          <Link to="./mcq-book-page" className="about_loksewa_mid_link">
            Explore MCQ's
          </Link>
        </div>
        <div className="about_loksewa_mid_right">
          <span className="about_loksewa_mid_text">FOR ASPIRANTS</span>
          <Link to="./live-exam-page" className="about_loksewa_mid_link coloured_button">
            Explore Live Exams
          </Link>
        </div>
      </div>
      <div className="about_loksewa_bottom">
        <div className="about_loksewa_bottom_left">
          <h1 className="about_loksewa_bottom_left_header margin_bottom-2">
            <div className="decoration"></div>
            <div className="decoration_two"></div>
            Everything you can do in a physical classroom,
            <span className="primary_color"> you can do with LOKSEWABOOK</span>
          </h1>
          <span className="subtext_color margin_top">
            Loksewabook is a one-stop online platform for those preparing for
            civil services exams in Nepal. The platform offers a comprehensive
            range of study materials, including articles, notes, videos, and
            mock tests. It also provides easy access to syllabus and vacancy
            details.
          </span>
          <a href="#" className="about_loksewa_bottom_left_link">
            Learn More...
          </a>
        </div>
        <div className="about_loksewa_bottom_right">
          <img
            src={Group17}
            alt=""
            className="about_loksewa_bottom_right_image"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutLokSewa;
