import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import "./ScoreBoard.css";
import Navbar from "../../../components/Navbar/Navbar";
import { getResultData } from "./components/getResultData";
import DisplayResults from "./components/displayQuestions";

const ScoreBoard = () => {
  let { title, fullMarks, resultId, passMarks,
    totalQuestions, totalMarkks, totalCorrect } = useLocation().state.result;
  // console.log("what we got = ");
  // console.log(totalMarkks);
  // console.log(useLocation().state.result);
  useEffect(() => {
    getResultData({ resultId: resultId });
  }, [resultId]);

  let { result, studentName, studentEmail } = useSelector((state) => {
    return {
      result: state.resultInfo.result,
      studentId: state.resultInfo.student.id,
      studentName: state.resultInfo.student.name,
      studentEmail: state.resultInfo.student.email,
    }
  })

  // console.log("std Name = ");
  // console.log(studentName);

  return (
    <>
      <Navbar />
      <div className="scoreboard">
        {/* <div className="scoreboard_header">
          <h1>{title}</h1>
        </div> */}
        <div className="scoreboard_top">
          <div className="scoreboard_top_left">
            <div className="scoreboard_top_left_part">
              <div className="scoreboard_top_left_top_title">Name: </div>
              <div className="scoreboard_top_left_top_value">{studentName}</div>
            </div>
            <div className="scoreboard_top_left_part">
              <div className="scoreboard_top_left_top_title">Email: </div>
              <div className="scoreboard_top_left_top_value">{studentEmail}</div>
            </div>
            <div className="scoreboard_top_left_part">
              <div className="scoreboard_top_left_top_title">Exam: </div>
              <div className="scoreboard_top_left_top_value">{title.substring(0, 40)}</div>
            </div>
          </div>
          <div className="scoreboard_top_right">
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Obtained Marks
              </div>
              <div className="scoreboard_top_right_top_value">{totalMarkks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Full Marks
              </div>
              <div className="scoreboard_top_right_top_value">{fullMarks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Pass Marks
              </div>
              <div className="scoreboard_top_right_top_value">{passMarks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Total Questions
              </div>
              <div className="scoreboard_top_right_top_value">{totalQuestions}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Correct Answers
              </div>
              <div className="scoreboard_top_right_top_value">{totalCorrect}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Incorrect Answers
              </div>
              <div className="scoreboard_top_right_top_value">{totalQuestions - totalCorrect}</div>
            </div>
          </div>
        </div>

        <div className="scoreboard_bottom">
          <div className="scoreboard_bottom_header">
            <h2>Your Results</h2>
          </div>
          <div className="scoreboard_bottom_mistakes">
            <div className="scoreboard_bottom_mistakes_div">
              <DisplayResults data={result} maxItemPerPage={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScoreBoard;
