import React from "react";
import DateRangeIcon from '@mui/icons-material/DateRange';

const VacancyCard = ({ thumbnail, time, title, description }) => {
    return (
        <div className="homeExamCard">
            <div className="image_container">
                <img src={thumbnail} alt="" className="homeExamCard_image" />
            </div>
            <div className="homeExamCard_time">
                <DateRangeIcon className="homeExamCard_time_icon" />
                <span className="homeExamCard_time_text">{time}</span>
            </div>
            <h2 className="homeExamCard_title">{title}</h2>
            <span className="homeExamCard_content">{description}...</span>
            {/* <div className="homeExamCard_price">
                <div href="#" className="homeExamCard_price_amount">
                    {price}
                </div>
            </div> */}
        </div>
    );
};

export default VacancyCard;
