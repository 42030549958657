import React, { useState } from "react";

const ScoreBoardQuestion = ({ data, index }) => {
    let [isCorrect, setIsCorrect] = useState(false)

    // console.log("index = ", index);
    // console.log("data = ", data.answeredIndex);

    return (<>
        <div className="question">
            <div className="question_left">{`${index}.`}</div>
            <div className="question_right">
                <div className="question_right_top" style={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "space-between"
                }}>
                    <div className="question_div">{data.question}</div>
                </div>

                {/* Images for questions here... */}
                <div className="displayImg">
                    {
                        data.questionImages ? data.questionImages.map((imgData, index) => {
                            return (
                                <a key={index} href={imgData.data} rel="noreferrer" target="_blank" style={{ textDecoration: " none", color: "black" }}>
                                    <img key={index} src={imgData.data} alt="question" className="questionImg" />
                                </a>
                            );
                        }) : ""
                    }
                </div>

                <div className="status_and_marks">
                    <div>
                        Status:
                        {(data.answeredIndex > 3 || data.answeredIndex < 0) ? <span> Not Answered</span> :
                            (data.answeredIndex.toString() === data.correct) ?
                                <span className="correct_status"> Correct</span>
                                : <span className="incorrect_status"> Incorrect</span>
                        }
                    </div>
                    <div className="question_div">Marks: {data.marks}</div>
                </div>

                <div className="question_right_bottom">
                    <div
                        className={
                            (data.correct === "0") ? "right_ans question_right_bottom_answer" :
                                (data.answeredIndex === 0) ? "question_right_bottom_answer incorrect_ans" :
                                    "question_right_bottom_answer"
                        }
                    >
                        {data.answer[0]}
                    </div>
                    <div className={
                        (data.correct === "1") ? "right_ans question_right_bottom_answer" :
                            (data.answeredIndex === 1) ? "question_right_bottom_answer incorrect_ans" :
                                "question_right_bottom_answer"
                    }>{data.answer[1]}</div>
                    <div className={
                        (data.correct === "2") ? "right_ans question_right_bottom_answer" :
                            (data.answeredIndex === 2) ? "question_right_bottom_answer incorrect_ans" :
                                "question_right_bottom_answer"
                    }
                    >
                        {data.answer[2]}
                    </div>
                    <div className={
                        (data.correct === "3") ? "right_ans question_right_bottom_answer" :
                            (data.answeredIndex === 3) ? "question_right_bottom_answer incorrect_ans" :
                                "question_right_bottom_answer"
                    }>{data.answer[3]}</div>
                </div>
            </div>
        </div>
        <div style={{
            width: "100%",
            color: isCorrect ? "green" : "red",
            padding: "5px 12%",
            fontSize: "15px"
        }}>
            <b>EXPLANATION :</b>
            <div className="explanation_div" style={{ color: "gray" }}>
                {data.description === "" ? (data.explanationImages ? "" : "No Explanation") : data.description}
                {/* Images for explanation here... */}
                <div className="displayImg">
                    {
                        data.explanationImages ? data.explanationImages.map((imgData, index) => {
                            return (
                                <a key={index} href={imgData} rel="noreferrer" target="_blank" style={{ textDecoration: " none", color: "black" }}>
                                    <img key={index} src={imgData} alt="question" className="questionImg" />
                                </a>
                            );
                        }) : ""
                    }
                </div>
            </div>
        </div>
    </>
    );
};

export default ScoreBoardQuestion;
