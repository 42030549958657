import React, { useState } from "react";
import "./SuccessComponent.css";
import FeatureCard from "../FeatureCard/FeatureCard";
import group79 from "../../assets/Group79.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const SuccessComponent = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className="success">
      <div className="success_top">
        <h1 className="success_top_header">Our Success</h1>
        <span className="success_top_text">
          Welcome to LoksewaBook, your gateway to success in civil service examinations. We are dedicated to providing you with comprehensive and accessible exam preparation tools. Our platform offers a wide range of resources, including a vast collection of MCQs and practice sets, live exams, detailed result analysis, and up-to-date syllabus information and vacancy details. LoksewaBook is here to make your dream of a successful civil service career a reality.
        </span>

      </div>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className="success_button">
          <div className="success_button_item">
            <span className="Success_button_item_header success_num">
              {counterOn && (
                <CountUp
                  start={0}
                  end={190}
                  duration={2}
                  delay={0}
                  separator=""
                />
              )}
              +
            </span>
            <span className="success_button_item_text">Active Users</span>
          </div>
          <div className="success_button_item">
            <span className="Success_button_item_header success_num">
              {counterOn && (
                <CountUp
                  start={0}
                  end={57}
                  duration={2}
                  delay={0}
                  separator=""
                />
              )}
              +
            </span>
            <span className="success_button_item_text">No. of Faculties</span>
          </div>
          <div className="success_button_item">
            <span className="Success_button_item_header success_num">
              {counterOn && (
                <CountUp
                  start={0}
                  end={35}
                  duration={2}
                  delay={0}
                  separator=""
                />
              )}
              +
            </span>
            <span className="success_button_item_text">Practice Sets</span>
          </div>
          <div className="success_button_item">
            <span className="Success_button_item_header success_num">
              {counterOn && (
                <CountUp
                  start={0}
                  end={28}
                  duration={2}
                  delay={0}
                  separator=""
                />
              )}
              +
            </span>
            <span className="success_button_item_text">Total Chapters</span>
          </div>
          <div className="success_button_item">
            <span className="Success_button_item_header success_num">
              {counterOn && (
                <CountUp
                  start={0}
                  end={1300}
                  duration={2}
                  delay={0}
                  separator=""
                />
              )}
              +
            </span>
            <span className="success_button_item_text">Total Questions</span>
          </div>
        </div>
      </ScrollTrigger>

      <div className="success_below_container">
        <h1 className="success_below_container_header">
          All-In-One{" "}
          <span className="primary_color">Online Learning Platform.</span>
        </h1>
        <span className="success_below_container_text">
          LOKSEWABOOK is one of the best online platform to crack civil exams.
          We provide knowledge sufficient to make your dream come true.
        </span>
      </div>

      <div className="success_features">
        <FeatureCard
          image={group79}
          title={"Online Free MCQs & Practice Sets"}
          subTitle={
            "These tools are convenient, accessible, and effective for exam preparations."
          }
        />
        <FeatureCard
          image={group79}
          title={"Online Live Exams & Result Analysis"}
          subTitle={
            "These tools are convenient, accessible, and effective for exam preparations."
          }
        />
        <FeatureCard
          image={group79}
          title={"All Syllabus Info & New Vacancy Details"}
          subTitle={
            "These tools are convenient, accessible, and effective for exam preparations."
          }
        />
      </div>
    </div>
  );
};

export default SuccessComponent;
