import { db } from '../../../../firebase';
import { doc, } from 'firebase/firestore';
import { getDoc, } from 'firebase/firestore';
import store from "../../../../state/store"
import resultSlice from '../../../../state/slices/resultSlice';

export let getResultData = async (resultId, setter) => {
    // console.log("resultId");
    // console.log(resultId);
    try {
        const docRef = doc(db, "result", resultId.resultId);
        let data = await getDoc(docRef);
        data = data.data();
        // console.log("data = ")
        // console.log(data.title);
        store.dispatch(resultSlice.actions.setData({
            id: resultId.resultId,
            title: data.title,
            fullMarks: data.fullMarks,
            passMarks: data.passMarks,
            result: data.result,
            student: data.student,
        }));
    } catch (error) {
        // console.log("error =")
        // console.log(error);
    }

}