import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import CopyRight from "../CopyRight";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer_top">
          <div className="footer_top_div top_left">
            <div className="footer_top_div_image">
              {/* <img src={logo} alt="" /> */}
              <Link to="/">
                <Logo width={100} />
              </Link>
            </div>
            <div className="footer_top_div_content">
              Nepal's biggest online MCQ Exam Platform
            </div>
            <div className="footer_top_div_icons">
              <a target="_blank" rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=61551017233492">
                <FacebookIcon className="footer_top_div_icons_media" />
              </a>
              <InstagramIcon className="footer_top_div_icons_media" />
              <TwitterIcon className="footer_top_div_icons_media" />
              <YouTubeIcon className="footer_top_div_icons_media" />
            </div>
          </div>
          <div className="footer_top_div">
            <div className="footer_top_div_heading">Learning Path</div>
            <div className="footer_top_div_links">
              <a href="./mcq-book-page">MCQ Book</a>
            </div>
            <div className="footer_top_div_links">
              <a href="./practice-set-page">Practice Set</a>
            </div>
            <div className="footer_top_div_links">
              <a href="./live-exam-page">Live Exam</a>
            </div>
            <div className="footer_top_div_links">
              <a href="./syllabus-page">Syllabus</a>
            </div>
            <div className="footer_top_div_links">
              <a href="./vacancy-page">Vacancy</a>
            </div>
            <div className="footer_top_div_links">
              <a href="./all-questions">All Questions</a>
            </div>
          </div>
          <div className="footer_top_div">
            {" "}
            <div className="footer_top_div_heading">Popular Faculty</div>
            <div className="footer_top_div_links">
              <a href="#">Engineer</a>
            </div>
            <div className="footer_top_div_links">
              <a href="#">Medical</a>
            </div>
            <div className="footer_top_div_links">
              <a href="#">Accountant</a>
            </div>
            <div className="footer_top_div_links">
              <a href="#">Officer</a>
            </div>
          </div>
          <div className="footer_top_div">
            <div className="footer_top_div_heading">Get In Touch</div>
            <div className="footer_top_div_content">Ekantakkuna, Lalitpur</div>
            <div className="footer_top_div_content">Bagmati, Nepal</div>
            <div className="footer_top_div_content">+977-9800000000</div>
            <div className="footer_top_div_content">contact@loksewabook.com</div>
          </div>
        </div>
      </div>
      <CopyRight />
    </>
  );
};

export default Footer;
