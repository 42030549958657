import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "./login.css";
import loginSlice from '../../../state/slices/loginSlice';
import CopyRight from '../../../components/CopyRight';
import logo from "../../../assets/logo.png";
import { Snackbar } from "@mui/material";

let Login = () => {

    let [open, setOpen] = useState(false);
    let [snackBarMessage, setSnackBarMessage] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [error, setError] = useState("");
    let [showPassword, setShowPassword] = useState(false);
    let [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const clickHandler = () => {
        setIsLoading(true);
        // console.log("Submit has been clicked");
        if (email === "") {
            setIsLoading(false);
            setError("Email cannot be empty.")
        } else if (password === "") {
            setIsLoading(false);
            setError("Password cannot be empty.");
        } else {
            setError("")
            handleLogin();
        }
    }

    const handleLogin = async () => {
        const auth = getAuth();
        try {
            let user = await signInWithEmailAndPassword(auth, email, password);
            // console.log(user);
            setOpen(true);
            setSnackBarMessage("Logged in successfully you will be redirected to profile page.");
        } catch (error) {
            // console.log(error.message)
            if (error.message === "Firebase: Error (auth/wrong-password).") setError("Email or Password did not match.");
            else if (error.message === "Firebase: Error (auth/user-not-found).") setError("User not found for this email.")
            else setError("Some server error was there.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        dispatch(loginSlice.actions.logOut());
    })

    return (
        <div className="signin">
            <div className="signin_div">
                <div className="signin_div_top">
                    <Link to="/">
                        <img src={logo} alt="" className="project_logo" />
                    </Link>
                </div>
                <div className="signin_div_mid">
                    <p>Sign In</p>
                </div>
                <div className="signin_div_form">
                    <div className="signin_div_form_item">
                        <input
                            value={email}
                            onChange={(event) => { setEmail(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input"
                            placeholder="Email Address or Phone Number*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                            type={showPassword ? "text" : "password"}
                            className="signin_div_form_item_input"
                            placeholder="Password*"
                        />
                        <div className='signUp_show_password_div'>
                            <input className='signUp_show_password_checkbox'
                                type="checkbox"
                                onClick={(e) => { setShowPassword(!showPassword); }} />
                            Show Password
                        </div>
                    </div>
                </div>
                <div style={{ color: "red", fontSize: "14px", width: "100%" }}>{error}</div>
                <div className="signin_div_button">
                    <button onClick={(e) => {
                        e.preventDefault();
                        if (!isLoading) {
                            setIsLoading(true);
                            clickHandler();
                        }
                    }}>
                        {isLoading ? "Please Wait..." : "Sign In"}
                    </button>
                </div>
                <div className="signin_div_bottom">
                    <div className="signin_div_bottom_left">
                        <a href="#">Forget Password?</a>
                        {/* //TODO: to handle the forget password */}
                    </div>
                    <div className="signin_div_bottom_right">
                        <Link to="/">Not Admin? Go back</Link>
                    </div>
                </div>
                <Snackbar open={open} message={snackBarMessage} autoHideDuration={1000} onClose={() => {
                    navigate('/admin/profile')
                }} />
            </div>
        </div>
    )
}

export default Login; 