import Navbar from "../../../components/Navbar/Navbar";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { useLoaderData, useNavigate } from "react-router";
import { db, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import "./CreateSyllabus.css";
import {Button} from "@mui/material";

const CreateSyllabus = () => {
  let tags= useLoaderData()
  let [selectedTags,setSelectedTags] = useState([])
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [photoError, setPhotoError] = useState("");
  const [pdfError, setPdfError] = useState("");
  let [open, setOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");

  let cleanup = () => {
    setTitle("");
    setDescription("");
    setPhoto(null);
    setPdf(null);
    setTitleError("");
    setDescription("");
    setPdfError("");
    setPhotoError("");
  };

  let submitHandler = async () => {

    //error handeling here...
    if (title === "") {
      setTitleError("Please enter the title");
      return;
    }
    setTitleError("");
    if (description === "") {
      setDescriptionError("Please enter the description");
      return;
    }
    setDescriptionError("");
    if (photo === null) {
      setPhotoError("Please upload photo");
      return;
    }
    setPhotoError("");
    if (pdf === null) {
      setPdfError("Please upload pdf");
      return;
    }
    setPdfError("");
    let photoRef = await fetch(photo).then((result) => {
      return result.blob();
    });
    setOpen(true);
    setMessage("Wait while uploading ... ");
    setSeverity("info");
    if (!loading) {
      setLoading(true);
    }

    //uploading now...
    try {
      // uploading image first...
      let imageName = `${crypto.randomUUID()}---${title}`;
      let imgRef = ref(
        storage,
        `image/syllabus/${imageName}`
      );
      await uploadBytes(imgRef, photoRef);
      let photoUrl = await getDownloadURL(imgRef);

      // then uploading pdf...
      let pdfName = `${crypto.randomUUID() + "---" + title}.pdf`;
      let pdfRef = ref(storage, `pdf/${pdfName}`);
      let pdfBlob = await fetch(pdf);
      pdfBlob = await pdfBlob.blob();
      await uploadBytes(pdfRef, pdfBlob);
      let pdfUrl = await getDownloadURL(pdfRef);

      //now writing data in firebase..
      await addDoc(collection(db, "Syllabus"), {
        title: title,
        date: new Date(),
        description: description,
        photoName: imageName,
        photoUrl: photoUrl,
        pdfName: pdfName,
        pdfUrl: pdfUrl,
        tags:selectedTags
      });
      setOpen(true);
      setMessage("Successfully uploaded to the databse");
      setSeverity("success");
      cleanup();
      setTimeout(() => {
        setLoading(false);
        navigate('/admin/syllabus-page');
      }, 4500);
    } catch (error) {
      // console.log(error);
      setOpen(true);
      setSeverity("error");
      setMessage("Failed to upload to the databse");
      setLoading(false);
    }
  };

  // How do we display picture uploaded by use
  return (
    <div className="createSyllabus">
      <Navbar admin={true} />
      <div className="createSyllabus_div">
        <label className="createSyllabus_div_label">Enter Title :
          {titleError ?
            <span style={{ marginLeft: "10px", color: "red", fontSize: "12px", textAlign: "center" }}>
              {titleError}</span> : ""}
        </label>
        <input
          type="text"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          className="createSyllabus_div_input"
        />

        <label className="createSyllabus_div_label">Enter description :
          {descriptionError ? (
            <span style={{ marginLeft: "10px", color: "red", fontSize: "12px", textAlign: "center" }}>
              {descriptionError}</span>
          ) : (
            ""
          )}
        </label>
        <textarea
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          className="createSyllabus_div_textarea"
        />
        <div>Select Tags :</div>
        <div style={{
              display:"flex",
              flexDirection:"row",
              gap:"12px",
              margin:"10px",
              flexWrap:"wrap"
            }}>{tags.map((t)=>{
              return <Button sx={{padding:"3px 20px",fontSize:"12px"}} variant="contained" color={selectedTags.includes(t)?"success":"secondary"} onClick={()=>{
                if(selectedTags.includes(t)){
                  let index = selectedTags.indexOf(t);
                  selectedTags.splice(index,1);
                  setSelectedTags([...selectedTags])
                }else{
                  setSelectedTags([...selectedTags,t])
                }
              }}>{t}</Button>
            })}</div>

        <div className="createSyllabus_div_upload">

          <div className="createSyllabus_div_upload_option">
            <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }} >
              <div style={{ display: "flex" }}>
                <label className="createSyllabus_div_label_file">
                  Upload photo :
                </label>
                <div>
                  <input
                    type="file"
                    style={{ border: "none", marginLeft: "10px" }}
                    accept="image/*"
                    onChange={(event) => {
                      setPhoto(URL.createObjectURL(event.target.files[0]));
                    }}
                  />
                </div>
              </div>
              <div style={{ color: "gray", fontSize: "11px", textAlign: "start", marginTop: "2.5px", }}>
                {"For perfection use image of aspect ratio 19:26 (h/w)"}
              </div>
              {photoError ? (
                <div style={{ color: "red", fontSize: "11px", textAlign: "start" }}>
                  {photoError} </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="createSyllabus_div_upload_option">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <label className="createSyllabus_div_label_file">
                  Upload pdf :
                </label>
                <div>
                  <input
                    id="upload_pdf"
                    width="20px"
                    type="file"
                    style={{ border: "none", marginLeft: "10px" }}
                    accept="application/pdf"
                    onChange={(event) => {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        setPdf(e.target.result);
                      };
                      reader.readAsDataURL(event.target.files[0]);
                    }}
                  />
                </div>
              </div>
              {pdfError ? (
                <div style={{ marginLeft: "20px", color: "red", fontSize: "12px", textAlign: "start" }}>
                  {pdfError}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="createSyllabus_div_bottom">
          <div className="createSyllabus_div_button_item">
            {
              loading ?
                <button
                  className="createSyllabus_div_button_confirm"
                >
                  Loading...
                </button>
                :
                <button
                  className="createSyllabus_div_button_confirm"
                  onClick={submitHandler}
                >
                  Submit
                </button>
            }
          </div>
          <div className="createSyllabus_div_button_item">
            <button
              className="createSyllabus_div_button_cancel"
              onClick={submitHandler}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          setOpen(false);
          setMessage("");
          setSeverity("info");
        }}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </div>
  );
};

// HOw do we make input field that accepts image file in html
export default CreateSyllabus;
