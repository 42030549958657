import React from "react";
import "./Sidebar.css";
import SidebarItems from "./SidebarItems";

const Sidebar = ({ items, onItemClick, closeModal }) => {
  return (
    <div className="sidebar">
      {items.map((item, index) => (
        <SidebarItems key={index} item={item} index={0}
          closeModal={closeModal} onItemClick={onItemClick} />
      ))}
    </div>
  );
};

export default Sidebar;
