import { Typography, Link } from "@mui/material";

function CopyRight(props) {
    return (
        <Typography variant="h6" color="text.secondary" align="center" {...props} marginBottom={2.5} marginTop={0.5}>
            {'Copyright © '}
            <Link color="inherit" href="/">
                LokSewaBook
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default CopyRight;
