import React, { useState } from "react";
import Pagination from '@mui/material/Pagination';
import { Typography } from "@mui/material";
import ScoreBoardQuestion from "../components/scoreBoardQuestion"

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function DisplayResults({ maxItemPerPage, data, answerData, }) {
    let [page, setPage] = useState(1);
    let [topQuestionIndex, setTopQuestionIndex] = useState(1);

    const count = Math.ceil(data.length / maxItemPerPage);
    const _DATA = usePagination(data, maxItemPerPage);

    const handleChange = (e, p) => {
        setPage(p);
        let newTopQuestionIndex = 1;
        for (let index = 1; index < p; index++) {
            newTopQuestionIndex += maxItemPerPage;
        };
        setTopQuestionIndex(newTopQuestionIndex);
        _DATA.jump(p);
    };

    if (count === 0) {
        return (
            <>
                <Typography variant="h6" >
                    Waiting for Connection.
                </Typography>
            </>
        );
    }

    return (
        <>
            {_DATA.currentData().map((q, i) => {

                return (
                    <ScoreBoardQuestion
                        key={i}
                        data={q}
                        answerData={answerData}
                        index={(i + topQuestionIndex)}
                    />
                );
            })}

            {data.length > 1 ? (
                <>
                    {/* this pagination will be hidden for small device */}
                    <Pagination
                        className="pagination-for-large-device"
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 3.5 }}
                    />
                    {/* this pagination will be hidden for large device */}
                    <Pagination
                        className="pagination-for-small-device"
                        count={count}
                        size="small"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 2 }}
                    />
                </>
            ) : (<></>)}
        </>
    );
}
