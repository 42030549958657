let style = {
    mainStyle:{
        display:"flex",
        flexDirection:"row",
        gap:"10px",
        backgroundColor:"#f4f4f4",
        //backgroundColor:"#F78357",
        padding:"20px",
        margin:"10px 0"
    },
    mainStyleError:{
        display:"flex",
        flexDirection:"row",
        gap:"10px",
        padding:"20px",
        backgroundColor:"#F78357",
        margin:"10px 0"
    },
    contentStyle:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    questionBlockStyle:{
        display:"flex",
        width:"100%",
        gap:"20px",
        flex:"1 1 60%"
    },
    questionBlockLeftStyle:{
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        flex:"10 9 500px"
    },
    questionBlockRightStyle:{
        display:"flex",
        flexDirection:"column",
        gap:"10px",
        flex:"1 1 100px%"
    },
    answerBlockStyle:{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    answerStyle:{
        display:"flex",
        flexDirection:"row",
        flexWrap:"wrap",
        width:"100%"
    },
    answerOption:{
        display:"flex",
        flex:"1 1 45%",
        margin:"5px",
        flexDirection:"row",
        alignItems:"center",
        gap:"20px"
    },
    explanationBlockStyle:{
        display:"flex",
        flexDirection:"column",
        gap:"20px"
    },
    checkboxStyle:{
        transform:"scale(1.7)"
    },
    inputStyle:{
        border:"1px solid black",
        outline:"none",
        padding:"4px 10px",
        resize:"none"
    },
    bttnStyle:{

    },
    errStyle:{
        color:"red",
        margin:"-10px 10px",
        fontSize:"12px"
    }
}

export default style;