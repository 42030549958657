import Navbar from "../../../components/Navbar/Navbar";
import { useState } from "react";
import { Button } from "@mui/material";
import { Alert, Snackbar } from "@mui/material"
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { useLoaderData, useNavigate } from "react-router";
import "./NewVacancy.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

let NewVacancy = () => {
  let navigate = useNavigate();
  let [open, setOpen] = useState(false)
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000)
  let tags = useLoaderData();
  let [title, setTitle] = useState("");
  let [thumbnail, setThumbnail] = useState(null);
  let [description, setDescription] = useState("");
  let [inputType, setInputType] = useState(-1);
  let [inputTitle, setInputTitle] = useState("");
  let [inputData, setInputData] = useState("");
  let [vacancyData, setVacancyData] = useState([]);
  let [titleError, setTitleError] = useState("");
  let [thumbnailError, setThumbnailError] = useState("");
  let [descriptionError, setDescriptionError] = useState("");
  let [selectedTags, setSelelectedTags] = useState([]);
  let [loading, setLoading] = useState(false);

  let uploadedImage = async ({ file, name }) => {
    try {
      let referece = ref(storage, `image/vacancy/${name}`);
      await uploadBytes(referece, file);
      let result = await getDownloadURL(referece);
      return result;
    } catch (error) {
      throw (error);
    }
  };

  let handleSubmit = async () => {
    let error = false;
    if (title === "") {
      setTitleError("Title is a required field");
      error = true;
    }
    if (thumbnail === null) {
      setThumbnailError("Please Select Thumbnail.");
      error = true;
    }
    if (description.length === 0) {
      setDescriptionError("Description must be filled");
      error = true;
    }
    if (!error) {
      setTitleError("");
      setThumbnailError("");
      setDescriptionError("");
    }
    if (!loading) {
      setLoading(true);
    }

    try {
      //upload thumbnail first...
      let thumbnailName = `${crypto.randomUUID()}---${thumbnail.name}`;
      let thubnailData = {
        name: thumbnailName,
        data: await uploadedImage({ file: thumbnail, name: thumbnailName })
      };

      //now upload other imgs...
      let content = [];
      content = await Promise.all(
        vacancyData.map(async (vac, index) => {
          if (vac.type == "image") {
            let imgName = `${crypto.randomUUID()}---${vac.data.name}`;
            return {
              index: index,
              type: "image",
              name: imgName,
              data: await uploadedImage({ file: vac.data, name: imgName }),
            }
          } else {
            return {
              index: index,
              type: vac.type,
              data: vac.data,
            }
          }
        })
      );

      //then write in docs...
      const docRef = await addDoc(collection(db, "vacancy"), {
        title: title,
        date: new Date(),
        thumbnail: thubnailData,
        description: description,
        content: content,
        tags: selectedTags,
      });
      const ref = doc(db, 'vacancy', docRef.id);
      setDoc(ref, { id: docRef.id }, { merge: true });

      setAutoHideDuration(2000);
      setMessage("Successfully Added.");
      setSeverity("success")
      setOpen(true)
      setTimeout(() => {
        setLoading(false);
        navigate('/admin/vacancy-page');
      }, 2000);
    } catch (e) {
      setAutoHideDuration(2000)
      setMessage("Failed to add. Try Again");
      setSeverity("error")
      setOpen(true)
      setLoading(false);
    }
  };

  return (
    <div className="newVacancy">
      <Navbar admin={true} />
      <div className="newVacancy_div">
        <div>
          <div className="newVacancy_div_top">
            <div className="newVacancy_div_top_left">
              <label className="newVacancy_label">Enter title : </label>
              <input
                className="newVacancy_input"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></input>
              <div style={{ color: "red", fontSize: "12px" }}>{titleError}</div>
            </div>
            <div className="newVacancy_div_top_right">
              <label className="newVacancy_label">Upload photo :
                {thumbnail ? <b> Done</b> : <></>}
              </label>
              <label htmlFor="upload" className="newVacancy_chooseFile">
                Choose Thumbnail
              </label>
              <input
                id="upload"
                className="newVacancy_div_top_right_file"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setThumbnail(e.target.files[0]);
                }}
              />
              <div style={{ color: "gray", fontSize: "12px", textAlign: "center" }}>
                {"For perfection use image of aspect ratio 19:26 (h/w)"}
              </div>
              <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
                {thumbnailError}
              </div>
            </div>
          </div>
          <div>
            <div className="newVacancy_label">Enter description : </div>
            <textarea
              className="newVacancy_description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            ></textarea>
            <div style={{ color: "red", fontSize: "12px" }}>
              {descriptionError}
            </div>

            <div>Select tags : </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              margin: "10px",
              flexWrap: "wrap"
            }}>{tags.map((t) => {
              return <Button sx={{ padding: "3px 20px", fontSize: "12px" }} variant="contained" color={selectedTags.includes(t) ? "success" : "secondary"} onClick={() => {
                if (selectedTags.includes(t)) {
                  let index = selectedTags.indexOf(t);
                  selectedTags.splice(index, 1);
                  setSelelectedTags([...selectedTags])
                } else {
                  setSelelectedTags([...selectedTags, t])
                }
              }}>{t}</Button>
            })}</div>

            {/* this is for preview section */}
            {vacancyData.map((v, index) => {
              switch (v.type) {
                case "image": {
                  return (
                    <div key={index} className="showImage">
                      <img
                        src={URL.createObjectURL(v.data)}
                        alt="uploaded pic"
                        className="review_image"
                      />
                      <div
                        className="cancel_image"
                        onClick={() => {
                          //deleting from local map...
                          vacancyData.splice(index, 1);
                          setVacancyData([...vacancyData]);

                        }}
                      >
                        <ClearIcon className="cancel_icon" />
                      </div>
                    </div>
                  );
                }
                case "paragraph": {
                  return (
                    <div key={index}>
                      <p style={{ whiteSpace: "pre-line" }}>{v.data}</p>
                      <Button
                        onClick={() => {
                          vacancyData.splice(index, 1);
                          setVacancyData([...vacancyData]);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                }
                case "subtitle": {
                  return (
                    <div key={index}>
                      <h3>{v.data}</h3>
                      <Button
                        onClick={() => {
                          vacancyData.splice(index, 1);
                          setVacancyData([...vacancyData]);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                }
                default: {
                  return "";
                }
              }
            })}
          </div>
        </div>
        {inputType === 0 ? (
          <div>
            <label className="newVacancy_label">Enter subtitle :</label>
            <input
              className="newVacancy_input"
              type="text"
              value={inputData}
              onChange={(e) => {
                setInputData(e.target.value);
              }}
            />
          </div>
        ) : inputType === 1 ? (
          <div>
            <label className="newVacancy_label">Enter paragraph :</label>
            <textarea
              className="newVacancy_paragraph_input newVacancy_input "
              value={inputData}
              onChange={(e) => {
                setInputData(e.target.value);
              }}
            />
          </div>
        ) : (
          ""
        )}
        {inputType === 0 || inputType === 1 ? (
          <div>
            <Button
              onClick={() => {
                setVacancyData([
                  ...vacancyData,
                  {
                    type: inputTitle,
                    data: inputData,
                  },
                ]);
                setInputType(-1);
              }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setInputType(-1);
                setInputTitle("");
                setInputData("");
              }}
            >
              Cancel
            </Button>
          </div>
        ) : (
          ""
        )}
        <div className="newVacancy_div_bottom">
          <div className="newVacancy_div_bottom_left">
            <button
              className="newVacancy_div_bottom_left_button"
              onClick={() => {
                if (inputType === 0) {
                  setInputType(-1);
                  setInputData("");
                } else {
                  setInputType(0);
                  setInputTitle("subtitle");
                  setInputData("");
                }
              }}
            >
              <AddCircleIcon className="icon" /> Add subtitle
            </button>
            <button
              className="newVacancy_div_bottom_left_button"
              onClick={() => {
                if (inputType === 1) {
                  setInputType(-1);
                  setInputTitle("");
                  setInputData("");
                } else {
                  setInputType(1);
                  setInputTitle("paragraph");
                  setInputData("");
                }
              }}
            >
              <AddCircleIcon className="icon" /> Add paragraph
            </button>
            <button className="newVacancy_div_bottom_left_button">
              <label style={{ cursor: "pointer" }} htmlFor="file">
                <AddCircleIcon className="icon" /> Add photo
              </label>
            </button>
          </div>
          <input
            type="file"
            hidden
            id="file"
            accept="image/*"
            onChange={async (e) => {
              setVacancyData([
                ...vacancyData,
                {
                  type: "image",
                  data: e.target.files[0],
                },
              ]);
            }}
          />

          <div className="newVacancy_div_bottom_right">
            {
              loading ?
                <button className="newVacancy_div_bottom_right_confirm">
                  <DoneIcon className="newVacancy_div_bottom_right_icon" /> Loading...
                </button>
                :
                <button onClick={handleSubmit} className="newVacancy_div_bottom_right_confirm">
                  <DoneIcon className="newVacancy_div_bottom_right_icon" /> Confirm
                </button>
            }
            <button className="newVacancy_div_bottom_right_cancel">
              <ClearIcon className="newVacancy_div_bottom_right_icon" /> Cancel
            </button>
          </div>
        </div>

        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={() => {
          setOpen(false)
          setMessage("")
          setSeverity("info")
          setAutoHideDuration(60 * 1000)

        }}>
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>

      </div>
    </div>
  );
};

export default NewVacancy;
