import style from './TagsStyle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

let Tags = (props) => {
    let [expanded, setExpanded] = useState(false);
    let [selected, setSelected] = useState("Select the tags");
    let [tags, setTags] = useState([...props.tags]);
    return (
        <div style={style.tagContainer}>
            <div>Choose Tag : </div>
            <div style={style.tagBlock}>
                <div style={{ ...style.tagBox, borderTop: "none" }} onClick={(click) => {
                    setExpanded(!expanded)
                }}>
                    <div>{selected}{(selected !== "Select the tags") ? <input type="checkbox" style={{ margin: "0 20px" }} onChange={(event) => {
                        props.checkboxHandler(selected)
                    }} /> : ""}</div>
                    <div style={style.expandStyle}>{(expanded) ? <ExpandLess /> : <ExpandMore />}</div>
                </div>
                {expanded ? tags.map((t) => {
                    return <div style={style.tagBox}><div style={style.tagData}
                        onClick={(event) => {
                            if (selected === "Select the tags") {
                                setSelected(t);
                                let ta = tags.filter((d) => {
                                    if (t === d) return false;
                                    else return true;
                                })
                                setTags([...ta])
                                setExpanded(false);
                            } else {
                                setSelected(t);
                                let da = tags.map((d) => {
                                    if (d === t) return selected;
                                    else return d
                                })
                                setTags([...da])
                                setExpanded(false)
                            }
                            props.divHandler(t, props.index)
                        }}>{t}</div><input type="checkbox" checked={props.tagList.includes(t)}
                            onChange={() => {
                                props.checkboxHandler(t)
                            }} /></div>
                }) : ""}
            </div>
        </div>
    )
}

export default Tags