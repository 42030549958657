import { db } from "../../../../firebase";
import { doc, getDoc } from "firebase/firestore";
// import { collection, query, where, getDocs } from "firebase/firestore";

async function fetchQuestion({ id, setQuestion, setLoading }) {
    const docRef = doc(db, "question", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        setQuestion(docSnap.data());
    } else {
        console.log("Question not found!");
    }

    // const q = query(collection(db, "question"), where("question", "==", "Testing image:"));

    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     // console.log(doc.id, " => ", doc.data());
    //     setQuestion(doc.data());
    // });

    setLoading(false)
}

export default fetchQuestion;
