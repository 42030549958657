import { useState } from "react";
import style from "./ExamQuestionStyle";
import { useDispatch } from "react-redux";
import practiceExamSlice from "../../../../state/slices/practiceExamSlice";
import { useSelector } from "react-redux";

let PracticeExamQuestion = (props) => {
  let dispatch = useDispatch();
  let [color, setColor] = useState({
    backgroundColor: "rgb(155,133,255)",
    color: "white",
  });
  let clicked = useSelector((state) => {
    let option = state.practiceExam.answerData[props.data.index]?.option;
    return option;
  });

  return (
    <div style={style.liveExamQuestion}>
      <div style={style.questionStyle}>
        Q {props.data.index + 1}. {props.data.question}
      </div>
      {/* Images for questions here... */}
      <div className="displayImg">
        {
          props.data.questionImages ? props.data.questionImages.map((imgData, index) => {
            return (
              <a key={index} href={imgData.data} target="_blank" style={{ textDecoration: " none", color: "black" }}>
                <img key={index} src={imgData.data} alt="question" className="questionImg" />
              </a>
            );
          }) : ""
        }
      </div>
      <div style={style.answerStyle}>
        {props.data.answer.map((q, i) => {
          return (
            <div
              key={i}
              style={
                clicked === i
                  ? { ...style.answerOptionStyle, ...color }
                  : { ...style.answerOptionStyle }
              }
              onClick={() => {
                if (i === clicked) {
                  dispatch(
                    practiceExamSlice.actions.updateAnswered({ answered: -1 })
                  );
                  dispatch(
                    practiceExamSlice.actions.updateAnswerOption({
                      index: props.data.index,
                      option: -1,
                    })
                  );
                } else {
                  if (clicked === -1) {
                    dispatch(
                      practiceExamSlice.actions.updateAnswered({ answered: 1 })
                    );
                  }
                  dispatch(
                    practiceExamSlice.actions.updateAnswerOption({
                      index: props.data.index,
                      option: i,
                    })
                  );
                }
                setColor({
                  backgroundColor: "rgb(155,133,255)",
                  color: "white",
                });
              }}
            >
              {q}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PracticeExamQuestion;
