import { parse } from 'csv-parse/browser/esm'

let csvFileHandler = (event, setQuestions, setFileError) => {
    let reader = new FileReader();
    reader.onload = async (data) => {
        parse(data.target.result.trim(), {
            delimiter: ",",
            relax_quotes: true,
            columns: true,
            group_columns_by_name: true,
            cast: (field, context) => {
                if (context.header) {
                    return field.replaceAll(" ", "").replaceAll('"', '').replaceAll("'", "");
                } else {
                    return field;
                }
            }
        }, (err, data) => {
            if (err) {
                setFileError("there is problem in csv file")
            } else {
                setQuestions(data);
                return data;
            }
        })
    }
    reader.readAsText(event.target.files[0])
}

export default csvFileHandler;