import { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { NavLink, useNavigate } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import CopyRight from '../../../components/CopyRight';
import Snackbar from '@mui/material/Snackbar';
import "./signup.css"

let SignUp = () => {

    let navigate = useNavigate();

    let [open, setOpen] = useState(false);
    let [snackBarMessage, setSnackBarMessage] = useState("");
    let [email, setEmail] = useState("")
    let [name, setName] = useState("");
    let [password, setPassword] = useState("");
    let [contactNumber, setContactNumber] = useState("");
    let [isLoading, setIsLoading] = useState(false);
    let [error, setError] = useState({});
    let [showPassword, setShowPassword] = useState(false);

    let validateEmail = (mail) => {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail)) {
            return true;
        } else {
            return false;
        }
    }

    const signUpClickHandler = async (_) => {
        if (!isLoading) {
            setIsLoading(true);
            // console.log("clicked");
            if (validateEmail(email)) {
                setError({})
                if (contactNumber.length === 10 && !isNaN(contactNumber)) {
                    if (password.length >= 8) {
                        try {
                            // console.log(email, password);
                            let a = await createUserWithEmailAndPassword(auth, email, password);
                            // console.log(a);
                            a = await updateProfile(auth.currentUser, {
                                displayName: `${name}:student`
                            })
                            // console.log(a);
                            await addDoc(collection(db, "student"), {
                                name, email, contactNumber
                            })
                            setOpen(true)
                            setSnackBarMessage("User has been created successfully.")
                            setIsLoading(false);
                        } catch (error) {
                            if (error.message === "Firebase: Error (auth/email-already-in-use).") setError({
                                type: "server error",
                                message: "User with the email already exists."
                            })
                            // console.log(error)
                            setIsLoading(false);
                        }
                    } else {
                        setError({
                            type: "password",
                            message: "Password must be 8 characters long"
                        })
                        setIsLoading(false);
                    }
                } else {
                    setError({
                        type: "contactNumber",
                        message: "Enter a valid contact Number"
                    })
                    setIsLoading(false);
                }
            } else {
                setError({
                    type: "email",
                    message: "Enter a valid email address"
                })
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="signin">
            <div className="signin_div">
                <div className="signin_div_top">
                    <NavLink to="/" >
                        <img src={logo} alt="" className="project_logo" />
                    </NavLink>
                </div>
                <div className="signin_div_mid">
                    <p>Sign Up</p>
                </div>
                <div className="signin_div_form">
                    <div className="signin_div_form_item">
                        <input
                            value={name}
                            onChange={(event) => { setName(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input margin-right"
                            placeholder="Full Name*"
                        />
                        <input
                            value={contactNumber}
                            onChange={(event) => { setContactNumber(event.target.value) }}
                            type="number"
                            className="signin_div_form_item_input "
                            placeholder="Phone Number*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={email}
                            onChange={(event) => { setEmail(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input"
                            placeholder="Email Address*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                            type={showPassword ? "text" : "password"}
                            className="signin_div_form_item_input"
                            placeholder="Password*"
                        />
                        <div className='signUp_show_password_div'>
                            <input className='signUp_show_password_checkbox'
                                type="checkbox"
                                onClick={(e) => { setShowPassword(!showPassword); }} />
                            Show Password
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", color: "red", fontSize: "14px" }}>{error.message}</div>
                <div className="signin_div_button">
                    <button onClick={signUpClickHandler}>
                        {isLoading ? "Please Wait..." : "Sign Up"}
                    </button>
                </div>
                <div className="signin_div_bottom">
                    <div className="signin_div_bottom_right">
                        <a href="/login">Already Have Account. Login?</a>
                    </div>
                </div>

                <CopyRight />
                <Snackbar open={open} autoHideDuration={2000}
                    message={snackBarMessage}
                    onClose={() => {
                        navigate('/login')
                    }} />
            </div>
        </div>
    );
}

export default SignUp