import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import DisplayResults from "./components/displayQuestions";

const PracticeSetResult = () => {
  let { data, answerData } = useLocation().state.result;
  let totalCorrect = 0;
  let incorrectAns = 0;
  let obtainedMarks = 0;

  // console.log("question = ")
  // console.log(data.question)
  // console.log("answerData = ")
  // console.log(answerData)

  for (let i = 0; i < data.question.length; i++) {
    let choosedAns = answerData[i].option;
    if (choosedAns === -1) {
      continue;
    }

    if (data.question[i].correct === choosedAns) {
      totalCorrect += 1;
      obtainedMarks += parseInt(data.question[i].marks);
    } else {
      incorrectAns += 1;
    }
  }

  return (
    <>
      <Navbar />
      <div className="scoreboard">
        {/* <div className="scoreboard_header">
          <h1>{title}</h1>
        </div> */}
        <div className="scoreboard_top">
          <div className="scoreboard_top_left">
            <div className="scoreboard_top_left_part">
              <div className="scoreboard_top_left_top_title">Name: </div>
              <div className="scoreboard_top_left_top_value">{"Anonymous"}</div>
            </div>
            <div className="scoreboard_top_left_part">
              <div className="scoreboard_top_left_top_title">Exam: </div>
              <div className="scoreboard_top_left_top_value">{data.title.substring(0, 40)}</div>
            </div>
          </div>
          <div className="scoreboard_top_right">
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Obtained Marks
              </div>
              <div className="scoreboard_top_right_top_value">{obtainedMarks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Full Marks
              </div>
              <div className="scoreboard_top_right_top_value">{data.fullMarks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Pass Marks
              </div>
              <div className="scoreboard_top_right_top_value">{data.passMarks}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Total Questions
              </div>
              <div className="scoreboard_top_right_top_value">{data.question.length}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Correct Answers
              </div>
              <div className="scoreboard_top_right_top_value">{totalCorrect}</div>
            </div>
            <div className="scoreboard_top_right_box">
              <div className="scoreboard_top_right_top_title">
                Incorrect Answers
              </div>
              <div className="scoreboard_top_right_top_value">{incorrectAns}</div>
            </div>
          </div>
        </div>

        <div className="scoreboard_bottom">
          <div className="scoreboard_bottom_header">
            <h2>Your Results</h2>
          </div>
          <div className="scoreboard_bottom_mistakes">
            <div className="scoreboard_bottom_mistakes_div">
              <DisplayResults data={data.question} answerData={answerData} maxItemPerPage={3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PracticeSetResult;
