import { createSlice } from "@reduxjs/toolkit";

let studentliveExamSlice = createSlice({
    name: "studentLiveExams",
    initialState: {
        data: []
    },
    reducers: {
        update: (state, action) => {
            state.data = [...action.payload]
        }
    }
})

export default studentliveExamSlice;

