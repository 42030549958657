
const getTodayDate = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const d = new Date();
    let month = months[d.getMonth()];
    let day = d.getDate();
    let year = d.getFullYear();
    let date = `${day} ${month}, ${year}`;
    return date;
};

const simplifyDate = ({ dateObject }) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    try {
        const d = dateObject.toDate();
        let month = months[d.getMonth()];
        let day = d.getDate();
        let year = d.getFullYear();
        let date = `${day} ${month}, ${year}`;
        return date;
    } catch (error) {
        const d = dateObject;
        let month = months[d.getMonth()];
        let day = d.getDate();
        let year = d.getFullYear();
        let date = `${day} ${month}, ${year}`;
        return date;
    }
};

export { getTodayDate, simplifyDate };
