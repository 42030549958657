import React from "react";
import "./HomeExamCard.css";
import cardImg from "../../assets/card_image.png";
import { useState, useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const HomeExamCard = ({ id, time, title, description, price, icon }) => {
  let [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const docRef = doc(db, "examThumbnail", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setThumbnail(docSnap.data())
        } else {
          // console.log("No such document!");
          setThumbnail(null);
        }
      }
    }
    fetchData();
  }, [id]);

  return (
    <div className="homeExamCard">
      <div className="image_container">
        <img src={thumbnail ? thumbnail.thumbnail : cardImg}
          alt="" className="homeExamCard_image" />
      </div>
      <div className="homeExamCard_time">
        {icon === 0 ? <AccessTimeIcon className="homeExamCard_time_icon" />
          : <DateRangeIcon className="homeExamCard_time_icon" />}
        <span className="homeExamCard_time_text">{time}</span>
      </div>
      <h2 className="homeExamCard_title">{title}</h2>
      <span className="homeExamCard_content">{description}...</span>
      <div className="homeExamCard_price">
        <div href="#" className="homeExamCard_price_amount">
          {price}
        </div>
      </div>
    </div>
  );
};

export default HomeExamCard;
