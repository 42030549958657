import { useState } from 'react'
import { collection, addDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../../../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/logo.png";
import CopyRight from '../../../components/CopyRight';
import Snackbar from '@mui/material/Snackbar';
// import './addAdmin.css';
import useAuth from '../../../customHooks/useAuth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateDoc } from 'firebase/firestore';
import { doc } from 'firebase/firestore';


//TODO: to implement the authentication process;
let AddAdmin = ()=>{
    const currentUser = useAuth();
    let navigate = useNavigate()
    let authenticationSuccessed = setTimeout(() => {
      navigate('/admin');
    }, 3 * 1000)
    useEffect(() => {
      return () => {
        clearTimeout(authenticationSuccessed);
      }
    })
    if (currentUser) {
      let name = currentUser.displayName?.split(':');
      if (name ? name[1] === "admin" : false) {
        clearTimeout(authenticationSuccessed)
        return <AddAdminProtected />
      }
    }
}

let AddAdminProtected = () => {

    let navigate = useNavigate();

    let adminId =  useSelector((state)=>{
        return state.loginInfo.id;
    })

    let [open,setOpen] = useState(false);
    let [snackBarMessage,setSnackBarMessage]= useState("");
    let [email, setEmail] = useState("")
    let [name, setName] = useState("");
    let [password, setPassword] = useState("");
    let [contactNumber, setContactNumber] = useState("");
    let [error, setError] = useState({});
    let validateEmail = (mail) => {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail)) {
            return true;
        } else {
            return false;
        }
    }

    const signUpClickHandler = async (_) => {
        if (validateEmail(email)) {
            setError({})
            if (contactNumber.length === 10 && !isNaN(contactNumber)) {
                if (password.length >= 8) {
                    try {
                        // console.log(email, password);
                        await createUserWithEmailAndPassword(auth, email, password);
                        await updateProfile(auth.currentUser,{
                            displayName:`${name}:admin`
                        })
                        let result = await addDoc(collection(db, "admin"), {
                            name, email, contactNumber
                        })
                        await updateDoc(doc(collection(db,"admin"),adminId),{
                            addedAdmin:arrayUnion({
                                name:name,
                                email:email,
                                id:result.id
                            })
                        })
                        setOpen(true)
                        setSnackBarMessage("user created successfully")
                    } catch (error) {
                        if(error.message==="Firebase: Error (auth/email-already-in-use).") setError({
                            type:"server error",
                            message:"User with the email already exists"
                        })
                        // console.log(error)
                    }
                } else {
                    setError({
                        type: "password",
                        message: "Password must be 8 characters long"
                    })
                }
            } else {
                setError({
                    type: "contactNumber",
                    message: "Enter a valid contact Number"
                })
            }
        } else {
            setError({
                type:"email",
                message:"Enter a valid email address"
            })
        }
    }

    return (
        <div className="signin">
            <div className="signin_div">
                <div className="signin_div_top">
                    <img src={logo} alt="" className="project_logo" />
                </div>
                <div className="signin_div_mid">
                    <p>Add Admin</p>
                </div>
                <div className="signin_div_form">
                    <div className="signin_div_form_item">
                        <input
                            value={name}
                            onChange={(event) => { setName(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input margin-right"
                            placeholder="Full Name*"
                        />
                        <input
                            value={contactNumber}
                            onChange={(event) => { setContactNumber(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input "
                            placeholder="Phone Number*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={email}
                            onChange={(event) => { setEmail(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input"
                            placeholder="Email Address*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                            type="password"
                            className="signin_div_form_item_input"
                            placeholder="Password*"
                        />
                    </div>
                </div>
                <div style={{width:"100%",color:"red",fontSize:"14px"}}>{error.message}</div>
                <div className="signin_div_button">
                    <button onClick={signUpClickHandler}>Add Admin</button>
                </div>
                <CopyRight />
            <Snackbar open={open} autoHideDuration={2000}
            message={snackBarMessage}
            onClose={()=>{
                navigate('/login')
            }}/>
            </div>
        </div>
    );
}

export default AddAdmin