import React from "react";
import "./ExamPlace.css";
import LiveExamQuestion from "./components/ExamQuestion";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useLoaderData, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  liveExamSlice,
  fetchLiveExam,
} from "../../../state/slices/liveExamSlice";
import { useEffect, useMemo, useState } from "react";
import constants from "../../../constants";
import Popup from "reactjs-popup";
import useAuth from "../../../customHooks/useAuth";
import Navbar from "../../../components/Navbar/Navbar";
import { Typography } from "@mui/material";
import loginSlice from "../../../state/slices/loginSlice";
import { Alert, Snackbar, } from "@mui/material"

const ExamPlace = () => {

  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);

  let navigate = useNavigate();
  // const refresh = () => window.location.reload(true);
  useAuth();
  let dispatch = useDispatch();
  let loader = useLoaderData();

  let [examEnded, setExamEnded] = useState(false);
  let [loading, setLoading] = useState(false);

  //TODO: We need to implement the authentication and login infor here before getting the activity done
  let studentName = useSelector((state) => {
    return state.loginInfo.email;
  });

  let isInitialized = useSelector((state) => {
    return state.liveExam.isInitialized;
  });

  let questionData = useSelector((state) => {
    return state.liveExam.load.questions;
  });

  let answerData = useSelector((state) => {
    return state.liveExam.answerData;
  });

  let index = useSelector((state) => {
    return state.liveExam.index;
  });

  let fullMarks = useSelector((state) => {
    return state.liveExam.load.fullMarks;
  });

  let answeredNumbers = useSelector((state) => {
    return state.liveExam.answered;
  });

  let timeLeft = useSelector((state) => {
    return state.liveExam.timeLeft;
  });

  var date = new Date(null);
  date.setSeconds(timeLeft);
  var countDown = date.toISOString().substr(11, 8);

  let endOfExam = useSelector((state) => {
    return state.liveExam.endOfExam;
  });

  let resultId = useSelector((state) => {
    return state.liveExam.resultId;
  });

  useEffect(() => {
    if (loader.proceedForExam) {
      if (!isInitialized) {
        if (studentName !== "")
          dispatch(
            fetchLiveExam({
              loader: loader.load,
              student: studentName,
              id: loader.id,
            })
          );
      }
    }
  }, [
    isInitialized,
    dispatch,
    loader.load,
    loader.id,
    studentName,
    loader.proceedForExam,
  ]);

  useEffect(() => {
    let clear = setInterval(() => {
      dispatch(liveExamSlice.actions.updateLeftTime());
    }, 1000);
    return () => {
      clearInterval(clear);
    };
  }, [dispatch]);

  useMemo(() => {
    let clearTime = setInterval(async () => {
      let time = await fetch(constants.getTimeUrl);
      time = await time.json();
      dispatch(liveExamSlice.actions.updateTimeFromServer(time));
    }, 5 * 60 * 1000);
    return () => {
      clearTime();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!loader.proceedForExam) {
    return <div>{loader.message}</div>;
  } else if (endOfExam) {
    // fetch(constants.generateResult, {
    //   method: "PUT",
    //   body: JSON.stringify({
    //     id: resultId,
    //   }),
    // });
    return (
      <>
        <Navbar />
        <div className="exam_ended_page">
          <div className="ended_info" >This exam has ended.</div>
          <div className="liveExam_body_right_button">
            <Popup
              trigger={
                <div className="liveExam_body_right_button_submit">
                  Submit
                </div>
              }
              modal
            >
              {(close) => {
                return (
                  <div>
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        color: "black",
                        padding: "25px 18px",
                        borderRadius: "15px",
                        border: "2px solid grey",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5">
                        Are you sure you want to submit ?
                      </Typography>
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <button
                          className="exam_place_popup_sure_btn"
                          onClick={async () => {
                            if (!loading) {
                              setLoading(true);
                              try {
                                await fetch(constants.generateResult, {
                                  method: "POST",
                                  body: JSON.stringify({
                                    id: resultId,
                                  }),
                                });
                                setTimeout(() => {
                                  navigate("/profile");
                                  dispatch(loginSlice.actions.fetchResults())
                                  setExamEnded(true);
                                }, 3000);
                              } catch (error) {
                                setAutoHideDuration(3000)
                                setMessage("Please Try Again.");
                                setSeverity("error")
                                setOpen(true)
                                setLoading(false);
                                setLoading(false);
                              }
                            }
                          }}
                        >
                          {loading ? "Loading..." : "Yes, I am sure"}
                        </button>
                        <button
                          className="exam_place_popup_no_btn"
                          onClick={close}
                        >
                          Nope
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Popup>

            <Popup
              trigger={
                <div className="liveExam_body_right_button_cancel">
                  Leave
                </div>
              }
              modal
            >
              {(close) => {
                return (
                  <div>
                    <div
                      style={{
                        backgroundColor: "whitesmoke",
                        color: "black",
                        padding: "25px 18px",
                        borderRadius: "15px",
                        border: "2px solid grey",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h5">
                        Are you sure you want to Leave without submiting?
                      </Typography>
                      <Typography color="error" variant="h6" gutterBottom>
                        Your data will be lost if you do so.
                      </Typography>
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <button
                          className="exam_place_popup_sure_btn"
                          onClick={async () => {
                            navigate("/live-exam-page", {
                              state: { refresh: true }
                            });
                          }}
                        >
                          Yes, I am sure
                        </button>
                        <button
                          className="exam_place_popup_no_btn"
                          onClick={close}
                        >
                          Nope
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Popup>
          </div>
        </div>
      </>
    );
  } else {
    if (!isInitialized) {
      //Make it refresh after every 5 seconds to get server data...
      return (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" gutterBottom>
            Please Wait...
          </Typography>
          <Typography variant="h4">Connecting To Server...</Typography>
        </div>
      );
    } else {
      return (
        <>
          <Navbar />
          <div className="liveExam">
            <div className="liveExam_top"></div>
            <div className="liveExam_body">
              <div className="liveExam_body_left">
                <div className="liveExam_body_left_top">
                  <div className="liveExam_body_left_top_left">
                    <div className="liveExam_body_left_top_left_content green">
                      Full Marks: {fullMarks}
                    </div>
                    <div className="liveExam_body_left_top_left_content red">
                      Total: {questionData.length}
                    </div>
                    <div className="liveExam_body_left_top_left_content green">
                      Answered: {answeredNumbers}
                    </div>
                  </div>
                  <div className="liveExam_body_left_top_right">
                    {/* Timer: {parseInt(timeLeft / 60).toFixed(0)}:{(timeLeft % 60).toFixed(0)} */}
                    Timer : {countDown}
                  </div>
                </div>
                <div className="liveExam_body_left_mid">
                  <LiveExamQuestion
                    data={{
                      question: questionData[index].question,
                      answer: questionData[index].answer,
                      index: index,
                      questionImage: questionData[index].questionImages,
                    }}
                  />
                </div>
                <div className="liveExam_body_left_bottom">
                  <div
                    className="liveExam_body_left_bottom_left"
                    onClick={() => {
                      dispatch(
                        liveExamSlice.actions.updateIndex({
                          index: index,
                          position: -1,
                        })
                      );
                    }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                    Previous
                  </div>
                  <div
                    className="liveExam_body_left_bottom_mid"
                    onClick={() => {
                      dispatch(liveExamSlice.actions.updateMarkForReview());
                    }}
                  >
                    Mark For Review
                  </div>
                  <div
                    className="liveExam_body_left_bottom_right"
                    onClick={() => {
                      dispatch(
                        liveExamSlice.actions.updateIndex({
                          index: index,
                          position: 1,
                        })
                      );
                    }}
                  >
                    Next
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </div>
              </div>
              <div className="liveExam_body_right">
                <div
                  style={{ textAlign: "center", padding: "25px 0px 10px 0px" }}
                >
                  <h2 className="liveExam_body_right_header">Questions</h2>
                </div>
                <div className="liveExam_body_right_grid">
                  {questionData.map((_, i) => (
                    <div
                      key={i}
                      className="liveExam_body_right_item"
                      onClick={() => {
                        dispatch(
                          liveExamSlice.actions.updateIndexByPosition({
                            index: i,
                          })
                        );
                      }}
                      style={{
                        border: i === index ? "solid 3px purple" : "",
                        backgroundColor: answerData[i].markedForReview
                          ? "gold"
                          : answerData[i].option === -1
                            ? ""
                            : "green",
                        color: answerData[i].markedForReview
                          ? "white"
                          : answerData[i].option === -1
                            ? ""
                            : "white",
                      }}
                    >
                      {i + 1}
                    </div>
                  ))}
                </div>

                <div className="liveExam_body_right_button">
                  <Popup
                    trigger={
                      <div className="liveExam_body_right_button_submit">
                        Submit
                      </div>
                    }
                    modal
                  >
                    {(close) => {
                      return (
                        <div>
                          <div
                            style={{
                              backgroundColor: "whitesmoke",
                              color: "black",
                              padding: "25px 18px",
                              borderRadius: "15px",
                              border: "2px solid grey",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography variant="h5">
                              Are you sure you want to submit ?
                            </Typography>
                            <div
                              style={{
                                marginTop: "15px",
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <button
                                className="exam_place_popup_sure_btn"
                                onClick={async () => {
                                  if (!loading) {
                                    setLoading(true);
                                    try {
                                      setTimeout(() => {
                                        navigate("/profile");
                                        dispatch(loginSlice.actions.fetchResults())
                                        setExamEnded(true);
                                      }, 3000);
                                      await fetch(constants.generateResult, {
                                        method: "POST",
                                        body: JSON.stringify({
                                          id: resultId,
                                        }),
                                      });
                                    } catch (error) {
                                      // console.log("error =");
                                      // console.log(error);
                                      setAutoHideDuration(3000)
                                      setMessage("Please Try Again.");
                                      setSeverity("error")
                                      setOpen(true)
                                      setLoading(false);
                                    }
                                  }
                                }}
                              >
                                {loading ? "Loading..." : "Yes, I am sure"}
                              </button>
                              <button
                                className="exam_place_popup_no_btn"
                                onClick={close}
                              >
                                Nope
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Popup>
                  <Popup
                    trigger={
                      <div className="liveExam_body_right_button_cancel">
                        Leave
                      </div>
                    }
                    modal
                  >
                    {(close) => {
                      return (
                        <div>
                          <div
                            style={{
                              backgroundColor: "whitesmoke",
                              color: "black",
                              padding: "25px 18px",
                              borderRadius: "15px",
                              border: "2px solid grey",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography variant="h5">
                              Are you sure you want to Leave without submiting?
                            </Typography>
                            <Typography color="error" variant="h6" gutterBottom>
                              Your data will be lost if you do so.
                            </Typography>
                            <div
                              style={{
                                marginTop: "15px",
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <button
                                className="exam_place_popup_sure_btn"
                                onClick={async () => {
                                  navigate("/live-exam-page", {
                                    state: { refresh: true }
                                  });
                                  setExamEnded(true);
                                }}
                              >
                                Yes, I am sure
                              </button>
                              <button
                                className="exam_place_popup_no_btn"
                                onClick={close}
                              >
                                Nope
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Popup>
                </div>
              </div>
            </div>
            <Snackbar open={open} autoHideDuration={autoHideDuration}
              onClose={() => {
                setOpen(false)
                setMessage("")
                setSeverity("info")
                setAutoHideDuration(60 * 3000)
              }}>
              <Alert severity={severity}>
                <Typography variant='h6'>{message}</Typography>
              </Alert>
            </Snackbar>
          </div>
        </>
      );
    }
  }
};

export default ExamPlace;
