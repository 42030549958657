import "./AskQuestion.css"
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import fetchQuestion from "./utils/fetchQuestion";
import Navbar from "../../../components/Navbar/Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AskQuestion = () => {
    //get question id here
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState({});

    useEffect(() => {
        fetchQuestion({ setLoading, setQuestion, id });
    }, [id]);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title> {`${question.question} - Find Question | LoksewaBook`}</title>
                    <meta
                        name="description"
                        content={`Get insights into a specific MCQ from Loksewa Vacancy, along with its correct answer and comprehensive explanation on LoksewaBook. Question: ${question.question}`}
                    />
                    <meta
                        name="keywords"
                        content={`${question.question},
                        ${question?.answer?.[0] ?? ""}, ${question?.answer?.[1] ?? ""}, 
                        ${question?.answer?.[2] ?? ""}, ${question?.answer?.[3] ?? ""}, MCQ, Correct Answer, Detailed Explanation, Loksewa preparation`}
                    />

                    {/* Open Graph */}
                    <meta property="og:title" content={`${question.question} - Find Question | LoksewaBook`} />
                    <meta property="og:description" content={`Get insights into a specific MCQ from Loksewa Vacancy, along with its correct answer and comprehensive explanation on LoksewaBook. Question: ${question.question}`} />
                </Helmet>
                <Navbar />
                <div className="ask_question_main">
                    {loading ? "Loading" :
                        <div>
                            <div className="aq_question_div">
                                Q) {question.question}
                            </div>
                            {/* Images for questions here... */}
                            <div className="displayImg">
                                {
                                    question.questionImage ? question.questionImage.map((imgData, index) => {
                                        return (
                                            <a href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                                                <img key={index} src={imgData.data} alt="question" className="questionImg" />
                                            </a>
                                        );
                                    }) : ""
                                }
                            </div>
                            <div className="aq_answers_div">
                                {question?.answer?.map((ans, index) => (
                                    <div className="aq_answer_box" key={index}>
                                        {ans}
                                    </div>
                                ))}
                            </div>
                            <div className="aq_correct_ans_div">
                                <b>Correct Answer:</b>
                                <div className="aq_explanation_box">
                                    {question?.answer[question?.correct] ?? ""}
                                </div>
                            </div>
                            <div className="aq_explanation_div">
                                <b>Explanation:</b>
                                <div className="aq_explanation_box">
                                    {question.explanation}
                                </div>
                                {/* Images for explanation here... */}
                                <div className="displayImg">
                                    {
                                        question.explanationImage ? question.explanationImage.map((imgData, index) => {
                                            return (
                                                <a href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                                                    <img key={index} src={imgData.data} alt="question" className="questionImg" />
                                                </a>
                                            );
                                        }) : ""
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </HelmetProvider>
        </div>
    );
};

export default AskQuestion;