import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import NoQrImg from "../../../assets/no_qr.jpg";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Alert, Snackbar } from "@mui/material"
import Popup from "reactjs-popup";

const AddThumbnailToExam = () => {
    let [open, setOpen] = useState(false);
    let [message, setMessage] = useState("");
    let [severity, setSeverity] = useState("info");
    let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);
    let [error, setError] = useState("");
    let [pickedPic, setPickedPic] = useState(null);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [isLoadingCancel, setIsLoadingCancel] = useState(false);
    let type = useLocation().state.type;
    let examId = useLocation().state.examId;
    let navigate = useNavigate();

    //fetching data form firebase...
    let [data, setData] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const docRef = doc(db, "examThumbnail", examId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                setData(docSnap.data())
            } else {
                // console.log("No such document!");
                setData(null);
            }
        }
        fetchData();
    }, []);

    //uploading imgae in firebase...
    let uploadedImage = async ({ file, name }) => {
        try {
            let referece = ref(storage, `image/examThumbnail/${name}`);
            await uploadBytes(referece, file);
            let result = await getDownloadURL(referece);
            return result;
        } catch (error) {
            throw (error);
        }
    };

    let handleSubmit = async () => {
        if (pickedPic === null) {
            setError("Please Select New Thumbnail First");
            setAutoHideDuration(3000)
            setMessage("Please Select New Thumbnail First");
            setSeverity("error")
            setOpen(true)
            setIsLoadingSubmit(false);
            return;
        } else {
            setError("");
        }

        //uploading images...
        let imgName = `${type}---${examId}`;
        uploadedImage({ file: pickedPic, name: imgName }).then((d) => {
            //writing in docs...
            const thumbnailRef = doc(db, "examThumbnail", examId);
            setDoc(
                thumbnailRef,
                {
                    type: type,
                    examId: examId,
                    thumbnail: d,
                },
                { merge: true }
            );
            if (type === "liveExam") {
                navigate("../live-exam-page");
            } else {
                navigate("../practice-set-page");
            }
        }).catch((e) => {
            setAutoHideDuration(2000)
            setMessage("Failed to add. Try Again");
            setSeverity("error")
            setOpen(true)
            setIsLoadingSubmit(false);
        });
    }

    return (
        <div className="add_qr_code_page">
            <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={() => {
                setOpen(false)
                setMessage("")
                setSeverity("info")
                setAutoHideDuration(60 * 3000)
            }}>
                <Alert severity={severity}>{message}</Alert>
            </Snackbar>
            <Typography sx={{ textTransform: "capitalize" }} gutterBottom variant="h2">
                {type}
            </Typography>
            <label htmlFor="add_btn" className="change-pic-btn">
                Add Thumbnail
            </label>
            <div style={{ color: 'red' }} >{error}</div>
            <input
                id="add_btn"
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                    setPickedPic(e.target.files[0]);
                }}
            />
            <img
                src={pickedPic ? URL.createObjectURL(pickedPic) :
                    (data === null ? NoQrImg : data.thumbnail)
                }
                className="add_qr_img"
                alt="Selected QR Code"
            />
            <div className="add_qr_btns">
                <Popup
                    trigger={
                        <div className="add_qr_btn green_btn">
                            <DoneIcon sx={{ fontSize: "2rem" }} />
                            {isLoadingSubmit ? "Loading..." : "Submit"}
                        </div>
                    }
                    modal
                >
                    {(close) => {
                        return (
                            <div className='pending_card_popup'>
                                <Typography variant="h6" sx={{ mb: "15px" }}>
                                    {data === null ?
                                        "Are you sure you want to Upload this Image?" :
                                        "Do You Want To Replace This QR Code?"
                                    }
                                </Typography>
                                <div style={{
                                    display: "flex", justifyContent: "center"
                                }}>
                                    <div className="pending_card_btn pending_card_green_btn"
                                        onClick={async () => {
                                            if (!isLoadingSubmit) {
                                                setIsLoadingSubmit(true);
                                                handleSubmit();
                                            }
                                            close();
                                        }}
                                    >
                                        Yes
                                    </div>
                                    <div className="pending_card_btn pending_card_red_btn"
                                        onClick={async () => {
                                            close();
                                        }}
                                    >
                                        Close
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Popup>
                <div className="add_qr_btn red_btn"
                    onClick={() => {
                        if (!isLoadingCancel) {
                            setIsLoadingCancel(true)
                            if (type === "liveExam") {
                                navigate("../live-exam-page");
                            } else {
                                navigate("../practice-set-page");
                            }
                        }
                    }}
                >
                    {
                        isLoadingCancel ?
                            "Loading..." :
                            <><ClearIcon sx={{ fontSize: "2rem" }} /> Cancel</>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddThumbnailToExam;
