import React from "react";
import "./Home.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Carousel from "react-bootstrap/Carousel";
import { Typography } from "@mui/material";
import Navbar from "../../../components/Navbar/Navbar";
import HomeHero from "../../../components/HomeHero/HomeHero";
import SuccessComponent from "../../../components/SuccessComponent/SuccessComponent";
import AboutLokSewa from "../../../components/AboutLokSewa/AboutLokSewa";
import PracticeSetComponent from "../../../components/PracticeSetComponent/PracticeSetComponent";
import LiveExamComponent from "../../../components/LiveExamComponent/LiveExamComponent";
import Testimonial from "../../../components/Testimonial/Testimonial";
import { useNavigate } from "react-router";
import VacancyComponent from "./components/vacancyComponent/vacancyComponent";
import SyllabusComponent from "./components/syllabusComponents/syllabusComponent";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
  let navigate = useNavigate();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>LoksewaBook: Elevating Education | Loksewa MCQ Exam Platform</title>
          <meta name="description"
            content="Explore LoksewaBook, Nepal’s premier MCQ exam platform, dedicated to quality, accessibility, and relevance. Join our vibrant community for Loksewa exam preparation. Excel in exams with our curated question database!" />

          <meta name="keywords" content="LoksewaBook, Loksewa exam platform, MCQ exam, Loksewa exam preparation, Nepal education, Loksewa MCQ, exam success, Nepali students, professionals, question database" />

          <script type="application/ld+json">
            {`{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Practice Loksewa MCQs for Free - LoksewaBook",
        "description": "Explore LoksewaBook, Nepal’s premier MCQ exam platform, dedicated to quality, accessibility, and relevance. Join our vibrant community for Loksewa exam preparation. Excel in exams with our curated question database!",
        "url": "https://loksewabook.com",
        "inLanguage": "en",
        "datePublished": "2022-12-13",
        "dateModified": "2023-10-31",
        "author": {
          "@type": "Person",
          "name": "Your Name"
        },
        "publisher": {
          "@type": "Organization",
          "name": "LoksewaBook",
          "logo": {
            "@type": "ImageObject",
            "url": "https://loksewabook.com/logo.png"
          }
        }
        }`}
          </script>

        </Helmet>
        <Navbar />
        <HomeHero />
        <SuccessComponent />
        <AboutLokSewa />
        <PracticeSetComponent />
        <LiveExamComponent />
        <SyllabusComponent />
        <VacancyComponent />
        <Testimonial />
      </HelmetProvider>
    </>
  );
};

export default Home;
