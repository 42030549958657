let style = {
  mainPage: {
    display: "flex",
    flexDirection: "column",
    fontSize: "20px",
    gap: "5%",
    margin: "2% 1% 1% 1%",
    padding: "3% 8%",
    backgroundColor: "white",
  },
  infoSection:{
    position:"fixed",
    color:"white",
    fontSize:"16px",
    minWidth:"100px",
    top:"8px",
    left:"10px",
    padding:"10px",
    width:"15vw",
    zIndex:"1000"
  },
  bttnContainer:{
    width:"100%",
    textAlign:"center",
    margin:"2% 0"
  },
  errElement:{
    fontSize:"18px",
    color:"red",
    width:"100%",
    textAlign:"center",
    marginTop:"-30px"
  }
};

export default style;
