import React, { useState } from "react";
import Pagination from '@mui/material/Pagination';
import Question from "../../../../components/Question/Question";
import "./DisplayQuestions.css";
import { Typography } from "@mui/material";
// import ShuffleGivenList from "../../../../utils/shuffleGivenList";

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function DisplayQuestions({ maxItemPerPage, data, admin, id, setSnackBar, setSnackBarMessage }) {
    let [page, setPage] = useState(1);

    //data = ShuffleGivenList(data);

    const count = Math.ceil(data.length / maxItemPerPage);
    const _DATA = usePagination(data, maxItemPerPage);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    if (count === 0) {
        return (
            <>
                <Typography variant="h6" >
                    No Questions Avialable Right Now.
                </Typography>
            </>
        );
    }

    return (
        <>
            {_DATA.currentData().map((q, i) => {
                let actualNumber;
                if (i === 1) {
                    actualNumber = page * 2;
                }
                if (i === 0) {
                    actualNumber = page * 2 - 1;
                }

                // console.log("q 1 q = ", q)

                return (
                    <Question
                        admin={admin}
                        key={actualNumber}
                        data={q}
                        document={q["id"]}
                        index={actualNumber}
                        setSnackBar={setSnackBar}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                );
            })}

            {data.length > 1 ? (
                <>
                    {/* this pagination will be hidden for small device */}
                    <Pagination
                        className="pagination-for-large-device"
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 3.5 }}
                    />
                    {/* this pagination will be hidden for large device */}
                    <Pagination
                        className="pagination-for-small-device"
                        count={count}
                        size="small"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 2 }}
                    />
                </>
            ) : (<></>)}
        </>
    );
}
