import { db } from "../../../firebase";
import { doc,updateDoc } from "firebase/firestore";

let updateAnswerHandler = async (id,position,option,ans)=>{
    // console.log("Updated answer in given index");
    ans[position] = option
    await updateDoc(doc(db,"liveExamResult",id),{
        answerData:ans
    });
}

let updateMarkForReviewHandler = async (id,position,markForReview,ans)=>{
    ans[position] = markForReview
    await updateDoc(doc(db,"liveExamResult",id),{
        answerData:ans
    });
}

export default updateAnswerHandler;
export { updateAnswerHandler, updateMarkForReviewHandler}