import React from "react";
import FilterPopup from "../../../components/FilterPopup/FilterPopup";
import "./LiveExamPage.css";
import { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { db } from "../../../firebase";
import { getDataFromServer } from "./handler";
import Navbar from "../../../components/Navbar/Navbar";
import {
  getCountFromServer,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import DisplayLiveExamCard from "./component/DisplayLiveExamCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const LiveExamPage = (props) => {
  let [page, setPage] = useState(1);
  let [total, setTotal] = useState(0);
  let load = useLoaderData();
  let [liveExamData, setLiveExamData] = useState(load[1]);
  const tags = load[0];
  let [filter, setFilter] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    getDataFromServer(page, setLiveExamData);
  }, [page]);

  useEffect(() => {
    getCountFromServer(query(collection(db, "liveExam"))).then((data) => {
      setTotal(data._data.count);
    });
  });

  let applyFilter = async () => {
    let rs;
    if (filter.length === 0) {
      rs = await getDocs(query(collection(db, "liveExam")));
    } else {
      rs = await getDocs(
        query(
          collection(db, "liveExam"),
          where("tags", "array-contains-any", filter)
        )
      );
    }

    let exam = [];
    rs.forEach((d) => {
      let a = d.data();
      a.id = d.id;
      exam.push(a);
    });
    setLiveExamData(exam);
  };

  let admin = props.admin || false;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Live Loksewa Exams - LoksewaBook</title>
          <meta name="description" content="Explore and practice live Loksewa exams on LoksewaBook. Participate in timed exams, receive real-time results, and boost your exam readiness. Join our vibrant LoksewaBook community of students and professionals on a journey to success." />
          <meta name="keywords" content="Live Loksewa Exams, Practice Live Exams, LoksewaBook Live Tests" />
          <script type="application/ld+json">
            {`{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Live Loksewa Exams - LoksewaBook",
        "description": "Explore and practice live Loksewa exams on LoksewaBook. Participate in timed exams, receive real-time results, and boost your exam readiness. Join our vibrant LoksewaBook community of students and professionals on a journey to success.",
        "url": "https://loksewabook.com",
        "inLanguage": "en",
        "datePublished": "2022-12-13",
        "dateModified": "2023-10-31",
        "author": {
          "@type": "Person",
          "name": "Your Name"
        },
        "publisher": {
          "@type": "Organization",
          "name": "LoksewaBook",
          "logo": {
            "@type": "ImageObject",
            "url": "https://loksewabook.com/logo.png"
          }
        }
        }`}
          </script>
        </Helmet>
        <Navbar admin={admin} />
        <div className="searchExam">
          <div className="searchExam_container">
            <div className="searchExam_bottom">
              <div className="searchExam_bottom_Title">
                <span className="searchExam_bottom_header">
                  Available Live Exams
                </span>
                <div>
                  {admin ? (
                    <button
                      className="searchExam_bottom_filterbtn"
                      onClick={() => {
                        navigate("/admin/add-live-exam");
                      }}
                    >
                      Add New
                    </button>
                  ) : (
                    <></>
                  )}
                  <FilterPopup
                    trigger={
                      <button className="searchExam_bottom_filterbtn">
                        Filter
                      </button>
                    }
                    content={tags}
                    select={filter}
                    setSelect={setFilter}
                    applyFilter={applyFilter}
                  />
                </div>
              </div>
              <div>
                <DisplayLiveExamCard
                  maxItemPerPage={3}
                  data={liveExamData}
                  admin={admin}
                />
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default LiveExamPage;
