import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './slices/loginSlice';
import resultSlice from "./slices/resultSlice";
import chapterSlice from './slices/chapterSlice';
import findQuestionSlice from './slices/findQuestionSlice';
import liveExamSlice from './slices/liveExamSlice';
import studentliveExamSlice from './slices/studentLiveExamSlice';
import practiceExamSlice from './slices/practiceExamSlice';
const store = configureStore({
    reducer: {
        studentLiveExam: studentliveExamSlice.reducer,
        loginInfo: loginSlice.reducer,
        resultInfo: resultSlice.reducer,
        chapterInfo: chapterSlice.reducer,
        findQuestion: findQuestionSlice.reducer,
        liveExam: liveExamSlice.reducer,
        practiceExam: practiceExamSlice.reducer
    }
})


export default store;

