import { addDoc, collection, arrayUnion, where, limit } from 'firebase/firestore';
import { db, storage } from '../../../firebase'
import store from '../../../state/store';
import { updateDoc, } from 'firebase/firestore';
import { doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

let getChapterPreFormat = (chapters) => {
    let result = {};
    for (let data of chapters) {
        let { field } = data;
        let inter = result;
        for (let f of field) {
            if (!inter[f]) {
                inter[f] = {};
            }
            inter = inter[f];
        }
    }
    return result;
}

let getTagList = (load, arr, i) => {
    // console.log("load : ", load, arr)
    let l = load;
    for (let j = 0; j <= i; j++) {
        l = l[arr[j]]
    }
    try {
        return Object.keys(l)
    } catch (error) {
        return []
    }
}

let uploadedImage = async ({ file, name }) => {
    try {
        let referece = ref(storage, `image/liveExamQuestions/${name}`);
        await uploadBytes(referece, file);
        let result = await getDownloadURL(referece);
        return result;
    } catch (error) {
        throw (error);
    }
};

let performDatabaseAction = async (data) => {
    try {
        for await (const question of data.questions) {
            if (question.questionImages) {
                // console.log("our questions1 = ")
                // console.log(question.questionImages);
                question.questionImages = await Promise.all(
                    question.questionImages.map(async (imageFile, index) => {
                        let imgName = `${crypto.randomUUID()}---${imageFile.name}`;
                        return {
                            name: imgName,
                            data: await uploadedImage({ file: imageFile, name: imgName }),
                        }
                    })
                );
                // console.log("our questions2 = ")
                // console.log(question.questionImages);
            }
            if (question.explanationImages) {
                // console.log("our explanation = ")
                // console.log(question.explanationImages);
                question.explanationImages = await Promise.all(
                    question.explanationImages.map(async (imageFile, index) => {
                        let imgName = `${crypto.randomUUID()}---${imageFile.name}`;
                        return await uploadedImage({ file: imageFile, name: imgName });
                    })
                );
                // console.log("our explanation2 = ")
                // console.log(question.explanationImages);
            }
        }
        let result = await addDoc(collection(db, "liveExam"), data)
        let id = store.getState().loginInfo.id
        await updateDoc(doc(collection(db, "admin"), id), {
            addedLiveExam: arrayUnion(result.id)
        })
        return true;
    } catch (error) {
        // console.log(error);
        throw (error)
    }
}

export { getChapterPreFormat, getTagList, performDatabaseAction };