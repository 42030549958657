import { initializeApp, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getStorage} from "firebase/storage"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
let fire = initializeApp({
  apiKey: "AIzaSyBRvFSSoZVRln_6osOm5HXDnhpQlkBHLxE",
  authDomain: "loksewamcq-f8491.firebaseapp.com",
  projectId: "loksewamcq-f8491",
  storageBucket: "loksewamcq-f8491.appspot.com",
  messagingSenderId: "494361884561",
  appId: "1:494361884561:web:fba77081fc6f92efa34c30",
  measurementId: "G-BN94G6DV8M",
});

let auth = getAuth(fire);
let db = getFirestore(fire);
let functions = getFunctions(getApp());
let storage = getStorage(fire)


// if (window.location.hostname.includes("localhost")) {
//   connectFunctionsEmulator(functions, "localhost", 5000);
//   // connectAuthEmulator(auth,"localhost",9100)
//   connectFirestoreEmulator(db, 'localhost', 8090)
// }

export { auth, db, functions ,storage};

export default fire;
