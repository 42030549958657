import React, { useState } from "react";
import "./Slider.css";
import BtnSlider from "./BtnSlider";
import dataSlider from "./dataSlider";
import StarIcon from "@mui/icons-material/Star";

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  };
  setTimeout(function () {
    nextSlide();
  }, 8000);

  return (
    <div className="container-slider">
      {dataSlider.map((obj, index) => {
        return (
          <div
            key={obj.id}
            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
          >
            <img src={obj.img} />
            <div className="review">
              <div className="review_left"></div>
              <div className="review_right">
                <div className="review_right_top">{obj.review.msg}</div>
                <div className="review_right_bottom">
                  <div className="review_right_bottom_left">
                    {obj.review.name}
                  </div>
                  <div className="review_right_bottom_right">
                    <div className="review_right_bottom_right_top">
                      {Array(obj.review.rating)
                        .fill(0)
                        .map((item, index) => (
                          <StarIcon key={index} className="star_icon" />
                        ))}
                    </div>
                    <div className="review_right_bottom_right_bottom">
                      {obj.review.totalReview} reviews at Loksewa Book
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <BtnSlider moveSlide={nextSlide} direction={"next"} />
      {/* <BtnSlider moveSlide={prevSlide} direction={"prev"} /> */}
    </div>
  );
}
