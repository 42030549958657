import { useState } from "react";
import { Button } from "@mui/material";

import style from "./LiveQuestionsStyle"
import DeleteIcon from '@mui/icons-material/Delete';

let LiveQuestions = (props) => {
    let { question, handler, bttnHandler, addNew } = props;

    let checkCompletion = () => {
        if (question.question === "") {
            setErrorMessage("Question field can not be empty");
            return false;
        }
        let answerErr = false;
        question.answer.forEach((a) => {
            if (a === "") answerErr = true
        })
        if (answerErr) {
            setErrorMessage("Answer field can not be empty")
            return false
        }
        if (question.correct < 0 || question.correct > 3) {
            setErrorMessage("Please set a correct field")
            return false
        }
        setErrorMessage("")
        return true;
    }

    let [errorMessage, setErrorMessage] = useState('')

    return (
        <div style={style.mainStyle}>
            <div>{props.question.index + 1}</div>
            <div style={style.contentStyle}>
                <div style={style.questionBlockStyle}>
                    <div style={style.questionBlockLeftStyle}>
                        <div>Enter Question : </div>
                        <input style={style.inputStyle} value={question.question} onChange={(event) => {
                            handler(props.question.index, { question: event.target.value })
                        }} />
                    </div>
                    <div style={style.questionBlockRightStyle}>
                        <div>Enter Mark : </div>
                        <input style={style.inputStyle} type="number" min={0} value={props.question.marks} onChange={(event) => {
                            handler(question.index, { marks: event.target.value })
                        }} />
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <span>Upload Question Image :</span>
                        <input type="file" accept="image/*" multiple onChange={(e) => {
                            const selectedFiles = e.target.files;
                            const selectedFilesArray = Array.from(selectedFiles);
                            handler(question.index, { questionImages: selectedFilesArray })
                            e.target.value = "";
                        }} />
                    </div>
                    {props.question.questionImages ?
                        props.question.questionImages.map((imgFile, index) => {
                            return (
                                <div key={index} style={{ display: "flex" }}>
                                    <img src={URL.createObjectURL(imgFile)} alt="uploaded"
                                        style={{ objectFit: "contain", height: "80px" }} />
                                    <div style={{ display: "flex", cursor: "pointer" }}
                                        onClick={() => {
                                            const newImgList =
                                                props.question.questionImages.filter((e) => e !== imgFile);
                                            handler(question.index, { questionImages: newImgList })
                                        }}
                                    >
                                        <DeleteIcon color="error" />
                                    </div>
                                </div>
                            );
                        }) : ""}
                </div>
                <div style={style.answerBlockStyle}>
                    <div>Enter Answers: (Tick correct answer)</div>
                    <div style={style.answerStyle}>
                        {props.question.answer.map((d, i) => {
                            return (
                                <div key={i} style={style.answerOption}>
                                    <input type="checkbox" style={style.checkboxStyle}
                                        checked={i === parseInt(question.correct)}
                                        onChange={() => { }}
                                        onClick={(event) => {
                                            handler(props.question.index, { correct: i })
                                        }} />
                                    <input type="text" style={style.inputStyle} value={d} onChange={(event) => {
                                        props.question.answer[i] = event.target.value;
                                        handler(props.question.index, { answer: props.question.answer })
                                    }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={style.explanationBlockStyle}>
                    <div>Explanation : </div>
                    <textarea style={style.inputStyle} value={props.question.description} onChange={(event) => {
                        handler(question.index, { description: event.target.value })
                    }} />
                </div>
                {/* <div style={style.questionBlockStyle}>
                    {props.question.picture ? <img src={URL.createObjectURL(props.question.picture)} alt="question img" /> : ""}
                    <input type="file" accept="image/*" onChange={(e) => {
                        handler(question.index, { picture: e.target.files[0] })
                    }} />
                </div> */}
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <span>Upload Explanation Image :</span>
                        <input type="file" accept="image/*" multiple onChange={(e) => {
                            const selectedFiles = e.target.files;
                            const selectedFilesArray = Array.from(selectedFiles);
                            handler(question.index, { explanationImages: selectedFilesArray })
                            e.target.value = "";
                        }} />
                    </div>
                    {props.question.explanationImages ?
                        props.question.explanationImages.map((imgFile, index) => {
                            return (
                                <div key={index} style={{ display: "flex" }}>
                                    <img src={URL.createObjectURL(imgFile)} alt="uploaded"
                                        style={{ objectFit: "contain", height: "80px" }} />
                                    <div style={{ display: "flex", cursor: "pointer" }}
                                        onClick={() => {
                                            const newImgList =
                                                props.question.explanationImages.filter((e) => e !== imgFile);
                                            handler(question.index, { explanationImages: newImgList })
                                        }}
                                    >
                                        <DeleteIcon color="error" />
                                    </div>
                                </div>
                            );
                        }) : ""}
                </div>
                <div><Button variant="contained" color={(addNew) ? "success" : "error"} sx={{ fontSize: "13px", padding: "3px 10px" }} onClick={(event) => {
                    if (addNew) {
                        if (checkCompletion()) {
                            bttnHandler(props.question.index)
                        }
                    } else {
                        bttnHandler(props.question.index)
                    }
                }}>{(addNew) ? "Add" : "Remove"}</Button></div>
                <div style={style.errStyle}>{errorMessage}</div>
            </div>
        </div>
    )
}

export default LiveQuestions