import React from "react";
import "./Testimonial.css";
import Slider from "../Slider/Slider";

const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="testimonial_left">
        <div className="textimonial_left_div">
          {" "}
          <div className="testimonial_left_topHead">
            <div className="testimonial_left_topHead_line"></div>{" "}
            <span className="testimonial_left_topHead_text">TESTIMONIAL</span>
          </div>
          <h1 className="testimonial_header">What They Say?</h1>
          <div className="paragraph">
            <span className="paragraph_text">
              LoksewaBook has got more than 100k positive ratings from our users around
              the world.{" "}
            </span>
            <span className="paragraph_text">
              Some of the students and teachers were greatly helped by the
              Skilline.
            </span>
            <span className="paragraph_text">
              Are you too? Please give your assessment
            </span>
          </div>
          <div className="testimonial_bottom">
            <a href="#">Write your assessment</a>
          </div>
        </div>
      </div>
      <div className="testimonial_right">
        <Slider />
      </div>
    </div>
  );
};

export default Testimonial;
