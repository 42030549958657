let style = {
  adminLiveExam: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: "20px",
    gap: "5%",
    margin: "2% 1%",
    padding: "3% 10%",
    backgroundColor: "#f4f4f4",
  },
  leftInputBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "10px 10px 0 10px",
    flex: "10 10 50%",
  },
  rightInputBlock: {
    display: "flex",
    flexDirection: "column",
    minWidth: "150px",
    gap: "10px",
    margin: "10px 0px 10px 10px",
    flex: "1 1 5%",
  },
  centerInputBlock: {
    display: "flex",
    flexDirection: "row",
    minWidth: "150px",
    gap: "10px",
    margin: "10px 0px 10px 10px",
    flex: "10 10 50%",
  },
  centerInputBlockChild: {
    display: "flex",
    flex: "1 1 20%",
    gap: "10px",
    flexDirection: "column",
  },
  inputStyle: {
    outline: "none",
    border: "solid 1px black",
    padding: "4px 10px",
    appearance: "none",
    resize: "none",
  },
  centerStyle: {
    margin: "25px 0",
    alignItems: "center",
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    gap: "20px"
  },
  buttonStyle: {
    backgroundColor: "#9B85FF",
    height: "40px",
    borderRadius: "8px",
    textAlign: "center",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "snow",
    cursor: "pointer"
  },
  labelStyle: {
    width: "100%",
    fontWeight: "bold",
    cursor: "pointer",
    textDecoration: "none"
  },
  errorStyle: {
    color: "red",
    width: "100%",
    fontSize: "13px",
    margin: "-20px 0px 0px 25px"
  }
};

export default style;
