import React from "react";
import "./PracticeSetComponent.css";
import HomeExamCard from "../HomeExamCard/HomeExamCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { getDocs, collection, limit, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";

const PracticeSetComponent = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1500, min: 993 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 993, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let [practiceSet, setPracticeSet] = useState([]);
  useEffect(() => {
    let q = query(
      collection(db, "practiceSet"),
      orderBy("timeStamp"),
      limit(8)
    );
    getDocs(q).then((data) => {
      let dataShot = [];
      data.forEach((d) => {
        let x = d.data();
        x.id = d.id;
        dataShot.push(x);
      });
      setPracticeSet(dataShot);
    });
  }, []);

  if (practiceSet.length < 1) {
    return;
  }

  return (
    <div className="practiceSetComponent">
      <div className="practiceSetComponent_top">
        <h2 className="practiceSetComponent_top_header">
          Practice Sets For You
        </h2>
        <Link to="./practice-set-page"
          className="practiceSetComponent_link Link">
          See All
        </Link>
      </div>
      <Carousel
        responsive={responsive}
        infinite={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        className="carousel"
      >
        {
          practiceSet.map((set, index) => {
            return (

              <Link className="Link" key={index}
                to={`/practice-set-detail/${set.id}`} >
                <HomeExamCard
                  key={index}
                  id={set.id}
                  time={`${set.time} mins`}
                  title={set.title}
                  description={set.description.toString().substring(0, 80)}
                  price={"Free"}
                  icon={0}
                />
              </Link>
            );
          })
        }
      </Carousel>
    </div>
  );
};

export default PracticeSetComponent;
