import { createSlice } from '@reduxjs/toolkit';

let findQuestionSlice = createSlice({
    name: "findQuestion",
    initialState: false,
    reducers: {
        update: (state, action) => {
            // console.log("fq called");
            return action.payload
        }
    }
})

export default findQuestionSlice