import React from "react";
import "./PracticeExam.css";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import practiceExamSlice from "../../../state/slices/practiceExamSlice";
import PracticeExamQuestion from "./components/ExamQuestion";
import DisplayResultQuestion from "./components/DisplayResult";
import Navbar from "../../../components/Navbar/Navbar";
import { Typography } from "@mui/material";
import Popup from "reactjs-popup";

const PracticeExamPage = () => {
  let dispatch = useDispatch();
  let loader = useLocation().state;
  useEffect(() => {
    dispatch(practiceExamSlice.actions.initializeQuestion(loader.question));
    dispatch(practiceExamSlice.actions.initializeLeftTime(loader.time));
  }, [dispatch, loader.question, loader.time]);
  let practiceExam = useSelector((state) => {
    return state.practiceExam;
  });
  let practiceExamIndex = useSelector((state) => {
    return state.practiceExam.index;
  });
  useEffect(() => {
    dispatch(
      practiceExamSlice.actions.initializeAnswer(loader.question.length)
    );
  }, [loader, dispatch]);

  useEffect(() => {
    let clear = setInterval(() => {
      dispatch(practiceExamSlice.actions.updateLeftTime());
    }, 1000);
    return () => {
      clearInterval(clear);
    };
  }, [dispatch]);
  let { answerData, obtainedMarks } = useSelector((state) => {
    return {
      answerData: state.practiceExam.answerData,
      obtainedMarks: state.practiceExam.obtainedMarks,
    };
  });

  let endOfExam = useSelector((state) => {
    return state.practiceExam.endOfExam;
  });

  let navigate = useNavigate();

  if (endOfExam) {
    dispatch(practiceExamSlice.actions.generateResult());
    navigate('../practice-set-result', {
      state: {
        result: {
          data: loader,
          answerData: answerData,
        },
      },
    })
  } else {

    // Timer: { parseInt(practiceExam.timeLeft / 60) }:
    // { practiceExam.timeLeft % 60 }
    var date = new Date(null);
    date.setSeconds(practiceExam.timeLeft);
    var countDown = date.toISOString().substr(11, 8);

    return (
      <>
        <Navbar />
        <div className="PracticeExam">
          <div className="PracticeExam_body">
            <div className="PracticeExam_body_left">
              <div className="PracticeExam_body_left_top">
                <div className="PracticeExam_body_left_top_left">
                  <div className="PracticeExam_body_left_top_left_content green">
                    Full Marks: {loader.fullMarks}
                  </div>

                  <div className="liveExam_body_left_top_left_content red">
                    Pass Marks: {loader.passMarks}
                  </div>
                  <div className="PracticeExam_body_left_top_left_content green">
                    {/* Answered: {answeredNumbers} */}
                  </div>
                </div>

                <div style={{ fontWeight: "bold" }} className="liveExam_body_left_top_right">
                  {/* Timer: {parseInt(practiceExam.timeLeft / 60)}:
                  {practiceExam.timeLeft % 60} */}
                  Timer: {countDown}
                </div>
              </div>
              <div className="PracticeExam_body_left_mid">
                <PracticeExamQuestion
                  data={{
                    question: loader.question[practiceExamIndex].question,
                    questionImages: loader.question[practiceExamIndex].questionImage,
                    answer: loader.question[practiceExamIndex].answer,
                    index: practiceExamIndex,
                  }}
                />
              </div>
              <div className="liveExam_body_left_bottom">
                <div
                  className="liveExam_body_left_bottom_left"
                  onClick={() => {
                    dispatch(
                      practiceExamSlice.actions.updateIndex({
                        index: practiceExamIndex,
                        position: -1,
                      })
                    );
                  }}
                >
                  <KeyboardDoubleArrowLeftIcon />
                  Previous
                </div>
                <div
                  className="liveExam_body_left_bottom_mid"
                  onClick={() => {
                    dispatch(practiceExamSlice.actions.updateMarkForReview());
                  }}
                >
                  Mark For Review
                </div>
                <div
                  className="liveExam_body_left_bottom_right"
                  onClick={() => {
                    dispatch(
                      practiceExamSlice.actions.updateIndex({
                        index: practiceExamIndex,
                        position: 1,
                      })
                    );
                  }}
                >
                  Next
                  <KeyboardDoubleArrowRightIcon />
                </div>
              </div>
            </div>
            <div className="PracticeExam_body_right">
              <h2 className="PracticeExam_body_right_header">Questions</h2>
              <div className="PracticeExam_body_right_grid">
                {loader.question.map((_, i) => (
                  <div
                    key={i}
                    className="liveExam_body_right_item"
                    onClick={(event) => {
                      dispatch(
                        practiceExamSlice.actions.updateIndexByPosition({
                          index: i,
                        })
                      );
                    }}
                    style={{
                      border: i === practiceExamIndex ? "solid 3px purple" : "",
                      backgroundColor: practiceExam?.answerData[i]
                        ?.markedForReview
                        ? "gold"
                        : practiceExam.answerData[i]?.option === -1
                          ? ""
                          : "green",
                      color: practiceExam.answerData[i]?.markedForReview
                        ? "white"
                        : practiceExam.answerData[i]?.option === -1
                          ? ""
                          : "white",
                    }}
                  >
                    {i + 1}
                  </div>
                ))}
              </div>

              <div className="liveExam_body_right_button">
                <Popup
                  trigger={
                    <div className="liveExam_body_right_button_submit">
                      Submit
                    </div>
                  }
                  modal
                >
                  {(close) => {
                    return (
                      <div>
                        <div
                          style={{
                            backgroundColor: "whitesmoke",
                            color: "black",
                            padding: "25px 18px",
                            borderRadius: "15px",
                            border: "2px solid grey",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5">
                            Are you sure you want to submit ?
                          </Typography>
                          <div
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <button
                              className="exam_place_popup_sure_btn"
                              onClick={() => {
                                dispatch(practiceExamSlice.actions.endOfExam());
                              }}
                            >
                              Yes, I am sure
                            </button>
                            <button
                              className="exam_place_popup_no_btn"
                              onClick={close}
                            >
                              Nope
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Popup>

                <Popup
                  trigger={
                    <div className="liveExam_body_right_button_cancel">
                      Leave
                    </div>
                  }
                  modal
                >
                  {(close) => {
                    return (
                      <div>
                        <div
                          style={{
                            backgroundColor: "whitesmoke",
                            color: "black",
                            padding: "25px 18px",
                            borderRadius: "15px",
                            border: "2px solid grey",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5">
                            Are you sure you want to Leave without submiting?
                          </Typography>
                          <Typography color="error" variant="h6" gutterBottom>
                            Your data will be lost if you do so.
                          </Typography>
                          <div
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <button
                              className="exam_place_popup_sure_btn"
                              onClick={async () => {
                                navigate("../practice-set-page");
                              }}
                            >
                              Yes, I am sure
                            </button>
                            <button
                              className="exam_place_popup_no_btn"
                              onClick={close}
                            >
                              Nope
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Popup>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PracticeExamPage;
