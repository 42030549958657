import style from './AdminLiveExamStyle';
import csvFileHandler from './handler';
import { useState, useEffect } from 'react';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../customHooks/useAuth';
import Navbar from '../../../components/Navbar/Navbar';


let AdminLiveExam = () => {
    const currentUser = useAuth();
    let navigate = useNavigate()
    let authenticationSuccessed = setTimeout(() => {
        navigate('/admin');
    }, 3 * 1000)
    useEffect(() => {
        return () => {
            clearTimeout(authenticationSuccessed);
        }
    })
    if (currentUser) {
        let name = currentUser.displayName?.split(':');
        if (name ? name[1] === "admin" : false) {
            clearTimeout(authenticationSuccessed)
            return (
                <>
                    <Navbar admin={true} />
                    <AdminLiveExamProtected />
                </>
            );
        }
    }
}

let AdminLiveExamProtected = () => {

    let [title, setTitle] = useState("");
    let [price, setPrice] = useState(0);
    let [date, setDate] = useState("");
    let [passMarks, setPassMarks] = useState(0);
    let [startTime, setStartTime] = useState("");
    let [endTime, setEndTime] = useState("");
    let [fullMarks, setFullMarks] = useState(0);
    let [author, setAuthor] = useState("");
    let [negativeMarks, setNegativeMarks] = useState(0);
    let [description, setDescription] = useState("");
    let [fileName, setFileName] = useState("Upload CSV");
    let [fileError, setFileError] = useState("");
    let [questions, setQuestions] = useState([]);
    let [error, setError] = useState({
        value: true,
        type: "",
        message: "",
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (questions.length !== 0 && error.value === false) {
            navigate('/admin/live-exam-question', {
                state: {
                    questions, price, fullMarks, negativeMarks, author, date,
                    startTime, endTime, description, title, passMarks
                }
            });
        }
    }, [author, date, description, endTime, error.value, fullMarks, navigate, negativeMarks, passMarks, price, questions, startTime, title]);

    const clearError = () => {
        setError({
            value: false,
            type: "",
            message: "",
        });
    };

    const handelError = () => {
        if (title === "") {
            setQuestions([]);
            setError({
                value: true,
                type: "title",
                message: "Please Enter title.",
            });
            return;
        } else {
            clearError();
        }
        // if (price === 0) {
        //     setQuestions([]);
        //     setError({
        //         value: true,
        //         type: "price",
        //         message: "Please Enter Price.",
        //     });
        //     return;
        // } else {
        //     clearError();
        // }
        if (date === "") {
            setQuestions([]);
            setError({
                value: true,
                type: "date",
                message: "Please Select Date.",
            });
            return;
        } else {
            clearError();
        }
        if (passMarks === 0) {
            setQuestions([]);
            setError({
                value: true,
                type: "passMarks",
                message: "Please Enter Pass Mark.",
            });
            return;
        } else {
            clearError();
        }
        if (startTime === "" || endTime === "") {
            setQuestions([]);
            setError({
                value: true,
                type: "time",
                message: "Please Enter Time.",
            });
            return;
        } else {
            clearError();
        }
        if (fullMarks === 0) {
            setQuestions([]);
            setError({
                value: true,
                type: "fullMarks",
                message: "Please Enter Full Mark.",
            });
            return;
        } else {
            clearError();
        }
        if (author === "") {
            setQuestions([]);
            setError({
                value: true,
                type: "author",
                message: "Please Enter Author.",
            });
            return;
        } else {
            clearError();
        }
        if (description === "") {
            setQuestions([]);
            setError({
                value: true,
                type: "description",
                message: "Please Enter Description.",
            });
            return;
        } else {
            clearError();
        }
    };

    return (
        <div style={style.adminLiveExam}>
            <div style={style.leftInputBlock}>
                <div>Exam Title : </div>
                <input type="text" style={style.inputStyle} value={title} onChange={(event) => { setTitle(event.target.value) }} />
                {(error.value === true && error.type === "title") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.rightInputBlock}>
                <div>Exam Price : </div>
                <input type="number" min={0} style={{ ...style.inputStyle }} value={price} onChange={(event) => { setPrice(event.target.value) }} />
                {(error.value === true && error.type === "price") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.leftInputBlock}>
                <div>Exam Date : </div>
                <input type="date" style={style.inputStyle} value={date} onChange={(event) => { setDate(event.target.value) }} />
                {(error.value === true && error.type === "date") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.rightInputBlock}>
                <div>Pass Marks : </div>
                <input type="number" style={style.inputStyle} value={passMarks} onChange={(event) => { setPassMarks(event.target.value) }} />
                {(error.value === true && error.type === "passMarks") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.centerInputBlock}>
                <div style={style.centerInputBlockChild}>
                    <div>Start Time:</div>
                    <input type="time" style={style.inputStyle} value={startTime} onChange={(event) => { setStartTime(event.target.value) }} />
                    {(error.value === true && error.type === "time") ?
                        <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
                </div>
                <div style={style.centerInputBlockChild}>
                    <div>End Time : </div>
                    <input type="time" style={style.inputStyle} value={endTime} onChange={(event) => { setEndTime(event.target.value) }} />
                </div>
            </div>
            <div style={style.rightInputBlock}>
                <div>Full Marks : </div>
                <input type="number" style={style.inputStyle} value={fullMarks} onChange={(event) => { setFullMarks(event.target.value) }} />
                {(error.value === true && error.type === "fullMarks") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.leftInputBlock}>
                <div>Enter Author : </div>
                <input type="text" style={style.inputStyle} value={author} onChange={(event) => { setAuthor(event.target.value) }} />
                {(error.value === true && error.type === "author") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.rightInputBlock}>
                <div>Negative mark : </div>
                <input style={style.inputStyle} type="number" value={negativeMarks} min={0} onChange={(event) => { setNegativeMarks(event.target.value) }} />
            </div>
            <div style={style.leftInputBlock}>
                <div>Enter description: </div>
                <textarea style={style.inputStyle} value={description} onChange={(event) => { setDescription(event.target.value) }} />
                {(error.value === true && error.type === "description") ?
                    <span style={{ color: "red", fontSize: "13px" }} >{error.message}</span> : ""}
            </div>
            <div style={style.centerStyle}>
                <div>Upload CSV : </div>
                <button style={style.buttonStyle} onClick={handelError}><label htmlFor="csv-file" style={style.labelStyle}>{fileName}</label></button>
                <input hidden type="file" id="csv-file"
                    onChange={async (event) => {
                        if (error.value === false) {
                            setFileName(event.target.files[0].name)
                            let type = event.target.files[0].type;
                            let extension = type.substring(type.lastIndexOf('/') + 1);
                            if (extension === "csv") {
                                setFileError("");
                                csvFileHandler(event, setQuestions, setFileError);
                            } else {
                                setFileError("*Please enter a csv file")
                            }
                        }
                        event.target.value = "";
                    }}
                />
                <div style={style.errorStyle}>{fileError}</div>
            </div>
            <div style={{ ...style.centerStyle }}>
                <div>Enter question manually : </div>
                <button style={style.buttonStyle} onClick={() => {
                    handelError()
                    // console.log(error)
                    if (!error.value) {
                        // console.log(error)
                        navigate("../live-exam-question", {
                            state: {
                                questions, price, fullMarks, negativeMarks, author, date,
                                startTime, endTime, description, title, passMarks
                            }
                        })
                    }
                }}>
                    <span style={{ ...style.labelStyle, textDecoration: "none", color: "white" }}>
                        Enter question manually
                    </span>
                </button>
                <input hidden type="file" id="csv-file" />
            </div>
        </div>
    )
}

export default AdminLiveExam;