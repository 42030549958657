let style={
    mainStyle:{
        width:"100vw",
        height:"100vh",
        backgroundColor:"snow",
        display:'flex',
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        fontSize:"25px"
    },
    inputBlock:{
        width:"100%",
        display:"flex",
        flexWrap:"wrap",
        margin:"12px 0"
    },
    inputBlockChild1:{
        flex:"0.2 1 70px"
    },
    inputBlockChild2:{
        flex:"1 1 300px"
    },
    inputStyle:{
        width:"100%",
        fontSize:"25px"
    }
}

export default style;