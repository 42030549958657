let style = {
    tagContainer:{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    tagBlock:{
        display:"flex",
        flexDirection:"column"
    },
    tagBox:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        padding:"3px 20px",
        backgroundColor:"#EEEEE4",
        borderTop:"solid 1px black",
        cursor:"pointer"
    },
    tagData:{
        flex:"1 1 400px"
    },
}

export default style