import { createSlice} from "@reduxjs/toolkit";

let practiceExamSlice = createSlice({
    name:"practiceExamSlice",
    initialState: {
        index: 0,
        answerData: [],
        isInitialized: false,
        load: {},
        timeLeft:0,
        answered: 0,
        resultId:"",
        endOfExam:false,
        correct:[],
        obtainedMarks:0
      },
      reducers: {
        initializeQuestion:(state,action)=>{
          state.correct = action.payload.map((d)=>{
            return {
              correct : d.correct,
              marks : d.marks
            }
          })
        },
        generateResult:(state)=>{
          let m = 0
          state.answerData.forEach((d,index)=>{
            // console.log(typeof(state.correct[index].marks),state.correct[index].marks)
            if(state.correct[index].correct === d.option){
              m += parseInt(state.correct[index].marks)
              console.group("m",m)
            }
          })
          state.obtainedMarks = m
        },
        initializeLeftTime:(state,action)=>{
          state.timeLeft = action.payload*60
        },
        initializeAnswer: (state,action)=>{
          let ans =[]
          for(let i =0;i<action.payload;i++){
            ans.push({
              markedForReview:false,
              option:-1
            })
          }
          
          state.answerData = ans
        },
        updateIndex: (state, action) => {
          // console.log("do we reach the dispatch")
          let inter = state.index + action.payload.position;
          if (inter >= 0 && inter < state.correct.length) {
            state.index = inter;
          }
        },
        updateIndexByPosition: (state,action)=>{
          state.index = action.payload.index
        },
        updateAnswered:(state,action)=>{
          state.answered= state.answered+action.payload.answered;
        },
        updateAnswerOption: (state, action) => {
          let x = [...state.answerData];
          if (action.payload.option > -1) {
            x[state.index] = {
              ...x[state.index],
              option: action.payload.option,
            };
          } else {
            x[state.index] = {
              ...x[state.index],
              option: action.payload.option,
            };
          }
          state.answerData = x;
        },
        updateMarkForReview: (state, _) => {
          let x = [...state.answerData];
          let y = !x[state.index].markedForReview
          x[state.index] = {
            ...x[state.index],
            markedForReview: y,
          };
          state.answerData = x;
        },
        updateLeftTime:(state,_)=>{
          //TODO: to perfrom automatic submission when the timer is up;
          state.timeLeft = state.timeLeft -1 ;
          if(state.timeLeft === 0){
            state.endOfExam = true
          }
        },
        endOfExam:(state,_)=>{
          // console.log("end of exam")
          state.endOfExam = true
        }
      },
    });

export default practiceExamSlice;