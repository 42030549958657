import { useState } from "react";
import "./ImageSlider.css"

const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    let images = [
        "https://firebasestorage.googleapis.com/v0/b/loksewamcq-f8491.appspot.com/o/image%2Fdefault%2Fbanner1.png?alt=media&token=c52eafd1-9b7f-48ae-bcc0-75d83be3fde7",
        "https://firebasestorage.googleapis.com/v0/b/loksewamcq-f8491.appspot.com/o/image%2Fdefault%2Fbanner2.png?alt=media&token=4e0c811d-5ef3-4056-84bd-41922b01ac35",
        "https://firebasestorage.googleapis.com/v0/b/loksewamcq-f8491.appspot.com/o/image%2Fdefault%2Fbanner3.png?alt=media&token=a59deb41-47d5-4703-8ae3-953e262b50cb",
    ]

    const autoSlideImages = () => {
        if (currentIndex + 1 !== images.length) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    setTimeout(function () {
        autoSlideImages();
    }, 4000);

    return (
        <div className="sliderStyles">
            <img src={images[currentIndex]}
                alt="" className="hero_container_right_image" />
            <div className="dotsContainerStyles">
                {images.map((slide, slideIndex) => (
                    <div
                        className={slideIndex === currentIndex ? "dotStyle dotSelected" : "dotStyle"}
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                    >
                        ●
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;