import {
  collection,
  query,
  where,
  getDocs,
  limit,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import { db, storage } from "../../../firebase";
import { Alert, Button, Snackbar } from "@mui/material";
import Popup from "reactjs-popup";
import { useLoaderData, useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import { simplifyDate } from "../../../customHooks/dateHook";
import "./vacancy.css";
import Filter from "../../../components/FilterPopup/FilterPopup";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Typography } from "@mui/material";

let Vacancy = () => {
  const refresh = () => window.location.reload(true);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let tags = useLoaderData();
  let navigate = useNavigate();
  let [vacancy, setVancacy] = useState([]);
  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);
  let [selectedTags, setSelelectedTags] = useState([]);
  let admin = window.location.href.includes("admin");
  useEffect(() => {
    let q;
    if (selectedTags.length === 0) {
      q = query(collection(db, "vacancy"), limit(12));
    } else {
      q = query(
        collection(db, "vacancy"),
        where("tags", "array-contains-any", selectedTags),
        limit(12)
      );
    }
    getDocs(q)
      .then((data) => {
        let dataShot = [];
        data.forEach((d) => {
          let snap = d.data();
          dataShot.push(snap);
        });
        setVancacy(dataShot);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [selectedTags]);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Loksewa Vacancies - Find Job Openings | LoksewaBook</title>
          <meta
            name="description"
            content="Discover the latest job openings and vacancies at Loksewa Aayog. LoksewaBook provides trusted information about government job opportunities in Nepal. Filter job listings by your field of interest and apply with confidence."
          />
          <meta name="keywords" content="Loksewa vacancies, Loksewa job openings, government jobs in Nepal, LoksewaBook, job search in Nepal, trusted job information, lok sewa aayog vacancy" />
        </Helmet>
        <Navbar admin={admin} />
        <div className="page">
          <div className="page_container">
            <div className="page_header">
              <span className="heading">Vacancy :</span>
              <div>
                {admin ? (
                  <button
                    className="filter_btn"
                    onClick={() => {
                      navigate("/admin/addVacancy");
                    }}
                  >
                    Add New
                  </button>
                ) : (
                  <></>
                )}
                <Filter
                  trigger={<button className="filter_btn">Filter</button>}
                  content={tags}
                  select={selectedTags}
                  setSelect={setSelelectedTags}
                  applyFilter={() => { }}
                />
              </div>
            </div>

            <div className="vacancyBody">
              {
                vacancy.length < 1 ?
                  <div style={{ height: "60vh" }}>
                    <Typography marginY={5} variant="h5" sx={{ textAlign: "center" }} >
                      There are no Vacancy right now. Please Contact Us For Further Information. Thank You !!!
                    </Typography>
                  </div>
                  :
                  vacancy.map((v, i) => {
                    // v.title = v.title + "kdfja aldsfja alsdfj alsdkjf sld flskdjf dsfadf"
                    let titleLength = 80;
                    let descriptionLength = 165;

                    if (width > 1195) {
                      titleLength = 125;
                      descriptionLength = 335;
                    } else if (width < 1195 && width > 1050) {
                      titleLength = 100;
                      descriptionLength = 325;
                    } else if (width < 1050 && width > 900) {
                      descriptionLength = 280;
                    } else if (width < 900 && width > 850) {
                      titleLength = 85;
                      descriptionLength = 275;
                    } else if (width < 850 && width > 600) {
                      titleLength = 68;
                      descriptionLength = 155;
                    } else if (width < 284) {
                      titleLength = 50;
                      descriptionLength = 140;
                    }

                    v.title = v.title.length < titleLength ?
                      v.title : `${v.title.substring(0, titleLength)}...`;
                    v.description = v.description.length < descriptionLength ?
                      v.description : `${v.description.substring(0, descriptionLength)}...`;

                    return (
                      <div
                        key={i}
                        className="vacancySnap"
                        onClick={() => {
                          if (!admin) {
                            // navigate(`/vacancy-detail-page/${v.id}`);
                            const cleanTitle = v.title.replace(/ /g, '-');
                            const encodedTitle = encodeURIComponent(cleanTitle);
                            navigate(`/vacancy-detail-page/${v.id}?title=${encodedTitle}`);
                          }
                        }}
                      >
                        <div className="vacancy_img_div">
                          <img src={v.thumbnail.data} className="vacancyImage" alt="" />
                        </div>
                        <div className="vacancyDescription">
                          <div className="vacancyTitle">{v.title}</div>
                          <div className="vacancyDate">
                            {simplifyDate({ dateObject: v.date })}
                          </div>
                          <div>{v.description}</div>
                        </div>
                        {admin ? (
                          <Popup
                            trigger={
                              <DeleteIcon
                                color="error"
                                sx={{
                                  fontSize: "20px",
                                  marginLeft: "30px",
                                  cursor: "pointer",
                                }}
                              ></DeleteIcon>
                            }
                            modal
                          >
                            {(close) => {
                              return (
                                <div className="delete_popup">
                                  <p>
                                    Are you sure you want to delete the vacancy ?
                                  </p>
                                  <div>
                                    <Button
                                      variant="contained"
                                      sx={{ marginRight: "15px" }}
                                      color="error"
                                      onClick={async () => {
                                        try {
                                          //first lets delete thumbnail img...
                                          const thumbnailRef = ref(
                                            storage,
                                            `image/vacancy/${v.thumbnail.name}`
                                          );
                                          deleteObject(thumbnailRef);
                                          v.content.map((c, index) => {
                                            if (c.type === "image") {
                                              // console.log(c.name);
                                              const imgRef = ref(
                                                storage,
                                                `image/vacancy/${c.name}`
                                              );
                                              deleteObject(imgRef);
                                            }
                                          });

                                          //now delete the document...
                                          await deleteDoc(doc(db, "vacancy", v.id));
                                          setAutoHideDuration(2000);
                                          setMessage("Deleted successfully");
                                          setSeverity("success");
                                          setOpen(true);
                                          close();
                                          refresh();
                                        } catch (error) {
                                          setAutoHideDuration(2000);
                                          setMessage("Failed to delete");
                                          setSeverity("error");
                                          setOpen(true);
                                          close();
                                        }
                                      }}
                                    >
                                      Yes , I am sure
                                    </Button>
                                    <Button onClick={close} variant="contained">
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              );
                            }}
                          </Popup>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
              }
            </div>
          </div>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={() => {
            setOpen(false);
            setMessage("");
            setSeverity("info");
            setAutoHideDuration(60 * 1000);
          }}
        >
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
      </HelmetProvider>
    </div>
  );
};

export default Vacancy;
