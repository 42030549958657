import React, { useState } from "react";
import "../McqBookPage.css";
import { Button } from "@mui/material";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import { db, storage } from "../../../../firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";


const PopupCreator = (props) => {
  let [questionError, setQuestionError] = useState(false);
  let [questionErrorMessage, setQuestionErrorMessage] = useState("");
  let [marksError, setMarksError] = useState(false);
  let [marksErrorMessage, setMarksErrorMessage] = useState("");
  let [answerError, setAnswerError] = useState(false);
  let [answerErrorMessage, setAnswerErrorMessage] = useState("");
  let [question, setQuestion] = useState("");
  let [answer, setAnswer] = useState(["", "", "", ""]);
  let [correct, setCorrect] = useState(-1);
  let [explanation, setExplanation] = useState("");
  let [marks, setMarks] = useState(1);
  let [questionImgFile, setQuestionImgFile] = useState([]);
  let [explanationImgFile, setExplanationImgFile] = useState([]);


  let findQuestion = useSelector((state) => state.findQuestion);
  let chapter = useSelector((state) => state.chapterInfo);

  let inputStyle = {
    margin: "5px 0 0 0",
    width: "10vw",
    height: "40px",
    backgroundColor: "snow",
    fontSize: "20px",
    padding: "5px",
    outline: "none",
    border: "2px solid rgb(155,133,255)",
  };
  let questionInputStyle = {
    margin: "5px 0 10px 0",
    border: "2px solid rgb(155,133,255)",
    outline: "none",
    backgroundColor: "snow",
    width: "75vw",
    height: "10vh",
    resize: "none",
    padding: "3px 7px",
  };
  let explanationInputStyle = {
    margin: "5px 0 10px 0",
    border: "2px solid rgb(155,133,255)",
    outline: "none",
    backgroundColor: "snow",
    width: "45vw",
    height: "10vh",
    resize: "none",
    padding: "3px 7px",
  };

  let uploadedImage = async ({ file, name }) => {
    try {
      let referece = ref(storage, `image/question/${name}`);
      await uploadBytes(referece, file);
      let result = await getDownloadURL(referece);
      return result;
    } catch (error) {
      throw error;
    }
  };

  let onSubmit = async () => {
    let error = false;
    if (question === "") {
      setQuestionError(true);
      setQuestionErrorMessage("Question can not be empty");
      error = true;
    } else {
      error = false;
    }

    answer.forEach((ans) => {
      if (ans === "") {
        setAnswerError(true);
        setAnswerErrorMessage("All answer field should be filled");
        error = true;
      } else {
        if (!error) error = false;
      }
    });

    if (!(correct >= 0 && correct <= 3)) {
      setAnswerError(true);
      setAnswerErrorMessage("Check the correct answer");
      error = true;
    } else {
      if (!error) error = false;
    }

    if (!(marks > 0 && marks < 5)) {
      setMarksError(true);
      setMarksErrorMessage("Marks field must be filled");
      error = true;
    } else {
      if (!error) error = false;
    }

    if (!findQuestion) {
      error = true;
    } else {
      if (!error) error = false;
    }

    if (!error) {
      let questionImages = [];
      questionImages = await Promise.all(
        questionImgFile.map(async (imgFile) => {
          let fileName = `${crypto.randomUUID()}---${imgFile.name}`;
          return {
            name: fileName,
            data: await uploadedImage({ file: imgFile, name: fileName }),
          };
        })
      );

      let explanationImages = [];
      explanationImages = await Promise.all(
        explanationImgFile.map(async (imgFile) => {
          let fileName = `${crypto.randomUUID()}---${imgFile.name}`;
          return {
            name: fileName,
            data: await uploadedImage({ file: imgFile, name: fileName }),
          };
        })
      );

      let result = await addDoc(collection(db, "question"), {
        question: question,
        answer: answer,
        correct: correct,
        marks: marks,
        explanation: explanation,
        chapter: chapter,
        timestamp: Date.now(),
        questionImage: [...questionImages],
        explanationImage: [...explanationImages],
      });
      if (result) {
        props.setSnackBar(true);
        props.setSnackBarMessage("Question added");
      }

      setAnswer(["", "", "", ""]);
      setAnswerError(false);
      setQuestion("");
      setQuestionError(false);
      setMarks(1);
      setQuestionErrorMessage("");
      setCorrect(-1);
      setExplanation("");
      setQuestionImgFile([]);
      setExplanationImgFile([]);
      setAnswerErrorMessage("");
      setMarksError(false);
      setMarksErrorMessage("");
    }
  };

  return (
    <Popup
      modal
      trigger={(open) => {
        return (
          <Button
            variant="outlined"
            style={{
              border: "2px solid blue",
              fontSize: "15px",
              fontWeight: 800,
            }}
          >
            Add New
          </Button>
        );
      }}
    >
      {(close) => {
        return (
          <div
            style={{
              marginTop: "0.6rem",
              height: "80vh",
              width: "90vw",
              backgroundColor: "snow",
              display: "flex",
              border: "solid 1px black",
              fontSize: "20px",
              borderRadius: "14px",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: "2%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Enter Question :</div>
                <textarea
                  style={questionInputStyle}
                  value={question}
                  onChange={(event) => {
                    setQuestion(event.target.value);
                  }}
                ></textarea>
                {questionError ? (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {questionErrorMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>Enter Marks :</div>
                <input
                  type="number"
                  style={{ ...inputStyle, textAlign: "center" }}
                  min="0"
                  max="4"
                  value={marks}
                  onChange={(event) => {
                    setMarks(event.target.value);
                  }}
                ></input>
                {marksError ? (
                  <div
                    style={{
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {marksErrorMessage}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Upload Question Image :</span>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ margin: "5px", border: "none" }}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    const selectedFilesArray = Array.from(selectedFiles);
                    setQuestionImgFile((previousImages) =>
                      previousImages.concat(selectedFilesArray)
                    );
                    event.target.value = "";
                  }}
                />
              </div>
              {questionImgFile.map((imgFile, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={URL.createObjectURL(imgFile)}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = questionImgFile.filter(
                          (e) => e !== imgFile
                        );
                        setQuestionImgFile(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>Enter Answers : </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 0}
                    onChange={(value) => {
                      setCorrect(0);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[0]}
                    onChange={(event) => {
                      answer[0] = event.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 1}
                    onChange={(event) => {
                      setCorrect(1);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    value={answer[1]}
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    onChange={(event) => {
                      answer[1] = event.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={2 === correct}
                    onChange={(value) => {
                      setCorrect(2);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[2]}
                    onChange={(val) => {
                      answer[2] = val.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={correct === 3}
                    onChange={() => {
                      setCorrect(3);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      accentColor: "purple",
                    }}
                  />
                  <input
                    type="text"
                    style={{ ...inputStyle, width: "30vw", margin: "5px 5px" }}
                    value={answer[3]}
                    onChange={(val) => {
                      answer[3] = val.target.value;
                      setAnswer([...answer]);
                    }}
                  />
                </div>
              </div>
              {answerError ? (
                <div style={{ color: "red", fontSize: "10px" }}>
                  {answerErrorMessage}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div>Explanation :</div>
                <textarea
                  style={{ ...explanationInputStyle, width: "100%" }}
                  value={explanation}
                  onChange={(val) => {
                    try {
                      setExplanation(val.target.value);
                    } catch (err) {
                      // console.log(err);
                    }
                  }}
                ></textarea>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>Upload Explanation Image :</span>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ margin: "5px", border: "none" }}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    const selectedFilesArray = Array.from(selectedFiles);
                    setExplanationImgFile((previousImages) =>
                      previousImages.concat(selectedFilesArray)
                    );
                    event.target.value = "";
                  }}
                />
              </div>
              {explanationImgFile.map((imgFile, index) => {
                return (
                  <div key={index} style={{ display: "flex" }}>
                    <img
                      src={URL.createObjectURL(imgFile)}
                      alt="uploaded"
                      style={{ objectFit: "contain", height: "80px" }}
                    />
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => {
                        const newImgList = explanationImgFile.filter(
                          (e) => e !== imgFile
                        );
                        setExplanationImgFile(newImgList);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onSubmit();
                  close();
                }}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="error"
                style={{ margin: "0 6px" }}
                onClick={() => {
                  setAnswer(["", "", "", ""]);
                  setAnswerError(false);
                  setQuestion("");
                  setQuestionError(false);
                  setMarks(1);
                  setQuestionErrorMessage("");
                  setCorrect(-1);
                  setExplanation("");
                  setAnswerErrorMessage("");
                  setMarksError(false);
                  setMarksErrorMessage("");
                  close();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      }}
    </Popup>
  );
};


export default PopupCreator;