import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import loginSlice from "../state/slices/loginSlice";
import { limit } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { query } from "firebase/firestore";
import { where } from "firebase/firestore";
import { getDocs } from "firebase/firestore";
import { db } from "../firebase";
import studentliveExamSlice from "../state/slices/studentLiveExamSlice";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState({});
  let dispatch = useDispatch();
  let userId = useSelector((state) => {
    return state.loginInfo.id;
  });
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        let [name, as] = user?.displayName?.split(":");
        dispatch(
          loginSlice.actions.login({
            loginAs: as,
            email: user.email,
            name: name,
          })
        );
        if (userId === "" && as === "student") {
          let q = query(
            collection(db, "student"),
            where("email", "==", user.email),
            limit(1)
          );
          getDocs(q).then((dataShot) => {
            let snapShot = [];
            let dataField = [];
            let contactNum = "";
            let profilePic = "";
            let DOB = [];
            let address = "";
            let gender = "";
            let name = "";
            let liveExams = [];
            let results = [];
            dataShot.forEach((d) => {

              if (d.id) {
                snapShot.push(d.id);
                dispatch(loginSlice.actions.setId({ id: snapShot[0] }));
              }

              if (d.data().name) {
                name = d.data().name;
                dispatch(loginSlice.actions.setName({ name: name }));
              }

              if (d.data().profilePic) {
                profilePic = d.data().profilePic;
                dispatch(loginSlice.actions.setProfilePic({ profilePic: profilePic }));
              }

              if (d.data().DOB) {
                DOB.push(d.data().DOB);
                dispatch(loginSlice.actions.setDOB({ DOB: DOB[0] }));
              }

              if (d.data().contactNumber) {
                contactNum = d.data().contactNumber;
                dispatch(loginSlice.actions.setContactNo({ contactNo: contactNum }));
              }

              if (d.data().gender) {
                gender = d.data().gender;
                dispatch(loginSlice.actions.setGender({ gender: gender }));
              }

              if (d.data().address) {
                address = d.data().address;
                dispatch(loginSlice.actions.setAddress({ address: address }));
              }

              if (d.data().results) {
                results = d.data().results
                dispatch(loginSlice.actions.setResults({
                  results
                }))
              }

              if (d.data().liveExams) {
                liveExams = d.data().liveExams
                dispatch(loginSlice.actions.setLiveExams({
                  liveExams
                }))
              }

              if (d.data().liveExams) {
                dataField.push(d.data().liveExams);
                dispatch(studentliveExamSlice.actions.update(dataField[0]));
              }

            });
          });
        } else if (userId === "" && as === "admin") {
          let q = query(
            collection(db, "admin"),
            where("email", "==", user.email),
            limit(1)
          );
          getDocs(q).then((dataShot) => {
            let snapShot = [];
            dataShot.forEach((d) => {
              snapShot.push(d.id);
            });
            dispatch(loginSlice.actions.setId({ id: snapShot[0] }));
          });
        }
      } else {
        setCurrentUser(null);
      }
    });
  });

  return currentUser;
};

export default useAuth;
