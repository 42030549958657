import { useState } from 'react';
import style from './ExamQuestionStyle';
import { useDispatch } from 'react-redux';
import liveExamSlice from '../../../../state/slices/liveExamSlice';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, CardMedia } from '@mui/material'

let LiveExamQuestion = (props) => {
    let dispatch = useDispatch();
    let [color, setColor] = useState({ backgroundColor: "rgb(155,133,255)", color: "white" })
    let clicked = useSelector((state) => {
        let option = state.liveExam.answerData[props.data.index].option
        return option;
    })
    return <div style={style.liveExamQuestion}>
        <div style={style.questionStyle}>{props.data.index + 1}. {props.data.question}</div>
        {/* Images for questions here... */}
        <div className="displayImg">
            {
                props.data.questionImage ? props.data.questionImage.map((imgData, index) => {
                    return (
                        <a key={index} href={imgData.data} target="_blank" rel="noreferrer" style={{ textDecoration: " none", color: "black" }}>
                            <img key={index} src={imgData.data} alt="question" className="questionImg" />
                        </a>
                    );
                }) : ""
            }
        </div>
        <div style={style.answerStyle}>
            {props.data.answer.map((q, i) => {
                return <div key={i} style={(clicked === i) ? { ...style.answerOptionStyle, ...color } : { ...style.answerOptionStyle }} onClick={() => {
                    if (i === clicked) {
                        dispatch(liveExamSlice.actions.updateAnswered({ answered: -1 }))
                        dispatch(liveExamSlice.actions.updateAnswerOption({
                            index: props.data.index,
                            option: -1
                        }))
                    } else {
                        if (clicked === -1) {
                            dispatch(liveExamSlice.actions.updateAnswered({ answered: 1 }))
                        }
                        dispatch(liveExamSlice.actions.updateAnswerOption({
                            index: props.data.index,
                            option: i
                        }))
                    }
                    setColor({ backgroundColor: "rgb(155,133,255)", color: "white" })
                }}>{q}</div>
            })}
        </div>
    </div >
}

export default LiveExamQuestion;
