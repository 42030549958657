import Popup from "reactjs-popup";
import style from "./PopUpContentStyle";
import { Button, Snackbar } from "@mui/material";
import { useState } from "react";
import Tags from "./Tags";
import { getChapterPreFormat, getTagList, performDatabaseAction } from "../utils";
import { useNavigate } from 'react-router-dom';

let PopUpContent = (props) => {
  let loader = getChapterPreFormat(props.listOfTags);
  let [choosen, setChoosen] = useState([]);
  let [tags, setTags] = useState([]);
  let [snackBarOpen, setSnackBarOpen] = useState(false);
  let [snackBarMessage, setSnackBarMessage] = useState("");
  let [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();
  let divHandler = (x, i) => {
    choosen = choosen.slice(0, i);
    setChoosen([...choosen, x]);
  }

  let checkboxHandler = (x) => {
    if (tags.includes(x)) {
      let t = tags.filter((d) => {
        return !(x === d);
      });
      setTags(t);
    } else {
      setTags([...tags, x]);
    }
  };
  return (
    <Popup
      modal
      trigger={
        <Button variant="contained" size="large" color="primary">
          Continue
        </Button>
      }
    >
      {(close) => {
        // console.log(props.data.questions[0])
        return (
          <div style={style.fullStyle}>
            <div style={style.mainStyle}>
              <Tags
                tags={Object.keys(loader)}
                checkboxHandler={checkboxHandler}
                tagList={tags}
                divHandler={divHandler}
                index={0}
              />
              {choosen.map((_, i) => {
                let dat = getTagList(loader, choosen, i)
                if (dat.length === 0) return ''
                return (
                  <Tags
                    keys={i}
                    tags={dat}
                    checkboxHandler={checkboxHandler}
                    tagList={tags}
                    divHandler={divHandler}
                    index={i + 1}
                  />
                );
              })}
              <div>
                <div>Tags choosed : </div>
                <div style={style.tagStyle}>
                  {tags.map((t) => {
                    return <Button variant="outlined">{t}</Button>;
                  })}
                </div>
              </div>
              <Button
                variant="contained"
                color="success"
                onClick={async (event) => {

                  if (!isLoading) {
                    setIsLoading(true);
                    try {
                      await performDatabaseAction({ ...props.data, tags: tags })
                      setSnackBarOpen(true)
                      setSnackBarMessage("Live exam has been created")
                    } catch (error) {
                      setSnackBarOpen(true)
                      // console.log(error)
                      setSnackBarMessage("Failed to add live exam");
                      setIsLoading(false);
                    }
                  }
                }}
              >
                {isLoading ? "Please Wait..." : "Submit"}
              </Button>
            </div>
            <Snackbar open={snackBarOpen} message={snackBarMessage} autoHideDuration={3000} onClose={() => {
              setSnackBarOpen(false)
              setSnackBarMessage("");
              navigate('/admin/profile')
            }}></Snackbar>
          </div>
        );
      }}
    </Popup>
  );
};

export default PopUpContent;
