import { useLoaderData, useNavigate } from "react-router";
import { db, } from "../../../firebase";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import "./VacancyDetail.css";
import Navbar from "../../../components/Navbar/Navbar";
import RecentVacancy from "../../../components/RecentVacancy/RecentVacancy";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,

} from "react-share";
import InstagramIcon from '@mui/icons-material/Instagram';

let VacancyDetail = ({ admin }) => {
  let navigate = useNavigate();
  let load = useLoaderData();
  let { title, description, content } = load;
  let [recentVacancyList, setRecentVacancyList] = useState([]);
  // let [open, setOpen] = useState(false);
  // let [message, setMessage] = useState("");
  // let [severity, setSeverity] = useState("info");
  // let [autoHideDuration, setAutoHideDuration] = useState(100000);

  useEffect(() => {
    function fetchOtherVacancies() {
      let q = query(
        collection(db, "vacancy"),
        where("id", "!=", load.id),
        limit(3)
      );

      getDocs(q).then((dataShot) => {
        let vList = [];
        dataShot.forEach((d) => {
          let data = d.data();
          vList.push(data);
        });
        setRecentVacancyList(vList);
      });
    }
    fetchOtherVacancies();
  }, [load]);

  // console.log("link = ", window.location.href)

  return (
    <div className="vacancyDetail">
      <HelmetProvider>
        <Helmet>
          <title>{title} - Loksewa Vacancy | LoksewaBook</title>
          <meta
            name="description"
            content={
              description.length > 160
                ? description.slice(0, 160)
                : description
            }
          />
          <meta
            name="keywords"
            content={
              `${title}, Loksewa vacancy, LoksewaBook, government jobs in Nepal, job openings in Nepal, latest job vacancies`
            }
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:site_name" content="LoksewaBook" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        <Navbar admin={false} />

        <div className="vacancyDetail_div">
          <div className="vacancyDetail_left">
            <h1 className="vacancyDetail_left_header">{title}</h1>
            <div>
              {content.map((c, i) => {
                switch (c.type) {
                  case "subtitle": {
                    return (
                      <h2 key={i} className="vacancyDetail_left_subtitle">
                        {c.data}
                      </h2>
                    );
                  }
                  case "image": {
                    return (
                      <a key={i} href={c.data} target="_blank" rel="noreferrer"
                        style={{ textDecoration: " none", color: "black" }}>
                        <img
                          src={c.data}
                          className="vacancyDetail_left_image"
                          alt="vacancy"
                        />
                      </a>
                    );
                  }
                  case "paragraph": {
                    return (
                      <p key={i} className="vacancyDetail_left_paragraph">
                        {c.data}
                      </p>
                    );
                  }

                  default: {
                    return "";
                  }
                }
              })}
            </div>
          </div>

          <div className="vacancyDetail_right">

            {/* Social Media Sharing Buttons */}
            <h1 className="vacancyDetail_right_header"> Share </h1>
            <div className="vd_share_buttons" style={{ marginBottom: "25px" }} >
              <FacebookShareButton
                url={window.location.href} // Share the current URL
                quote={title} // Title of the shared post
                className="vd_share_button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href} // Share the current URL
                title={title} // Title of the shared post
                className="vd_share_button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <InstapaperShareButton
                url={window.location.href} // Share the current URL
                title={title} // Title of the shared post
                className="vd_share_button"
              >
                <InstagramIcon className="vd_insta_share_btn" />
              </InstapaperShareButton>
            </div>

            <h1 className="vacancyDetail_right_header"> Recent Vacancies</h1>
            <div className="vacancyDetail_right_div">
              {recentVacancyList.length === 0 ? "" : recentVacancyList.map((vac, i) => {
                let { id, title, thumbnail } = vac;

                return (
                  <div key={i}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/vacancy-detail-page/${id}`);
                    }}
                  >
                    <RecentVacancy thumbnail={thumbnail} title={title} />
                  </div>
                );
              })}
            </div>
            <a href="/vacancy-page">Explore More...</a>
          </div>
        </div>
      </HelmetProvider>
    </div>
  );
};

export default VacancyDetail;
