import React from "react";
import Select from "react-select";
const SelectComponent = (props) => {
  const options = props.options
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fafafa",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  return (
    <Select
      onChange={(value) => {
        let a = props.selected.slice(0, props.index);
        a[props.index] = value.value
        props.setSelected([...a])
      }}
      selected={false}
      value={{ value: props.selected[props.index], label: props.selected[props.index] || "Select Options" }}
      defaultValue={null}
      styles={colourStyles}
      placeholder="Select Options"
      defaultOptions={options[0]}
      options={options}
      className="select"
      maxMenuHeight={130}
    />
  );
}
export default SelectComponent;
