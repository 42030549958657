import React from "react";
import "./PracticeDetail.css";
import { useLoaderData, useNavigate } from "react-router";
import Navbar from "../../../components/Navbar/Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
const PracticeDetail = () => {
  let load = useLoaderData();
  let navigate = useNavigate();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{load.title} - Practice Set Details | LoksewaBook</title>
          <meta
            name="description"
            content={
              load.description.length > 160
                ? load.description.slice(0, 160)
                : load.description
            }
          />
          <meta
            name="keywords"
            content={
              "Practice Set Details, LoksewaBook Practice Sets, " +
              (load.title || "") +
              ", Loksewa exam preparation, MCQ practice sets, exam duration, pass marks"
            }
          />
        </Helmet>
        <Navbar />
        <div className="practiceDetail">
          <div className="practiceDetial_header">Practice Set Details</div>
          <div className="practiceDetial_body">
            <div className="practiceDetail_body_left">
              <div className="practiceDetail_body_left_title">{load.title}</div>
              <div className="practiceDetail_body_left_body">
                {load.description}
              </div>
              <div className="practiceDetail_body_left_detail">
                <div className="practiceDetail_body_left_detail_item">
                  Total Questions: {load?.question?.length || 0}
                </div>
                <div className="practiceDetail_body_left_detail_item">
                  Full Marks: {load?.fullMarks || 0}
                </div>
                <div className="practiceDetail_body_left_detail_item">
                  Pass Marks: {Math.floor(load?.passMarks) || 0}
                </div>
                <div className="practiceDetail_body_left_detail_item">
                  Exam Duration: {Math.floor(load?.time) || 0} mins
                </div>
              </div>
            </div>
            <div className="practiceDetail_body_right">
              <div className="practiceDetail_body_right_top">
                <div className="practiceDetail_body_right_top_price">
                  Price:
                </div>
                <div className="practiceDetail_body_right_top_value">Free</div>
                <div className="practiceDetail_body_right_top_button">
                  <button
                    onClick={() => {
                      navigate("/practice-exam-page", { state: load });
                    }}
                  >
                    Give Exam
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default PracticeDetail;
