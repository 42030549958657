import React, { useState } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../Logo";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useDispatch } from "react-redux";
import loginSlice from "../../state/slices/loginSlice";
import SideNav from "../SideNav/SideNav";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const NavbarStudent = ({ admin }) => {
  let dispatch = useDispatch();
  const login = useSelector((state) => state.loginInfo.login);

  const [icon, setIcon] = useState("nav__toggler");
  const [openNav, setOpenNav] = useState(false);

  const navToggle = () => {
    // // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
    setOpenNav((prev) => !prev);
  };
  // if (windowSize.width <= 1060) {
  //   setSize(1060);
  // }

  return (
    <nav className="nav">
      {openNav && <SideNav Close={navToggle} admin={admin} />}
      <div className="nav_logo">
        <Link to="/">
          <Logo width={70} />
        </Link>
      </div>
      <div className="nav__menu__mid">
        <li className="nav__item">
          <Link to="/">Home</Link>
        </li>
        <li className="nav__item">
          <Link
            to="/mcq-book-page"
            className={
              window.location.pathname == "/mcq-book-page" ? "active" : ""
            }
          >
            MCQ Book
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/practice-set-page"
            className={
              window.location.pathname == "/practice-set-page" ? "active" : ""
            }
          >
            Practice Page
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/live-exam-page"
            className={
              window.location.pathname == "/live-exam-page" ? "active" : ""
            }
          >
            Live Exam
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/syllabus-page"
            className={
              window.location.pathname == "/syllabus-page" ? "active" : ""
            }
          >
            Syllabus
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/vacancy-page"
            className={
              window.location.pathname == "/vacancy-page" ? "active" : ""
            }
          >
            Vacancy
          </Link>
        </li>
      </div>
      <div className="nav__menu__right">
        <li className="nav__item login_gap">
          <Link to={login ? "/profile" : "/login"} className="flex_item">
            {login ? (
              <AccountCircleIcon className="nav_icon" />
            ) : (
              <LoginIcon className="nav_icon" />
            )}
            <div>{login ? "Profile" : "Login"}</div>
          </Link>
        </li>
        <li className="nav__item">
          <Link to={login ? "/" : "/signup"} className="flex_item">
            {login ? (
              <LogoutIcon className="nav_icon" />
            ) : (
              <PersonAddIcon className="nav_icon" />
            )}
            <div
              onClick={() => {
                dispatch(loginSlice.actions.logOut());
                signOut(auth);
              }}
            >
              {login ? "Log Out" : "Sign Up"}
            </div>
          </Link>
        </li>
      </div>

      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};
export default NavbarStudent;
