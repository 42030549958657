import React from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useState } from 'react'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "./login.css";
import loginSlice from '../../../state/slices/loginSlice';
import CopyRight from '../../../components/CopyRight';
import logo from "../../../assets/logo.png";
import { Snackbar } from "@mui/material";
import { getDocs, query, where, limit } from 'firebase/firestore';
import { db } from "../../../firebase";
import { collection } from 'firebase/firestore';
import ResetPassword from '../../../components/ResetPassword/resetPassword';

let Login = () => {

    let [open, setOpen] = useState(false);
    let [snackBarMessage, setSnackBarMessage] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [error, setError] = useState("");
    let [isLoading, setIsLoading] = useState(false);
    let [showPassword, setShowPassword] = useState(false);

    let navigate = useNavigate();
    let dispatch = useDispatch();

    const clickHandler = (event) => {
        event.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            // console.log("Submit has been clicked");
            if (email === "") {
                setError("Email cannot be empty.")
                setIsLoading(false);
            } else if (password === "") {
                setError("Password cannot be empty.");
                setIsLoading(false);
            } else {
                setError("")
                handleLogin()
            }
        }
    }

    const handleLogin = async () => {
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            // dispatch(loginSlice.actions.login({
            //     login: true,
            //     loginAs: "student",
            //     email: email
            // }))
            setOpen(true);
            setSnackBarMessage("Logged in successfully you will be redirected to profile page.");
        } catch (error) {
            // console.log(error.message)
            if (error.message === "Firebase: Error (auth/wrong-password).") setError("Email or Password did not match.");
            else if (error.message === "Firebase: Error (auth/user-not-found).") setError("User not found for this email.")
            else setError("Some server error was occured.");
            setIsLoading(false);
        }
    }

    return (
        <div className="signin">
            <div className="signin_div">
                <div className="signin_div_top">
                    <NavLink to="/" >
                        <img src={logo} alt="" className="project_logo" />
                    </NavLink>
                </div>
                <div className="signin_div_mid">
                    <p>Sign In</p>
                </div>
                <div className="signin_div_form">
                    <div className="signin_div_form_item">
                        <input
                            value={email}
                            onChange={(event) => { setEmail(event.target.value) }}
                            type="text"
                            className="signin_div_form_item_input"
                            placeholder="Email Address or Phone Number*"
                        />
                    </div>
                    <div className="signin_div_form_item">
                        <input
                            value={password}
                            onChange={(event) => { setPassword(event.target.value) }}
                            type={showPassword ? "text" : "password"}
                            className="signin_div_form_item_input"
                            placeholder="Password*"
                        />
                        <div className='signUp_show_password_div'>
                            <input className='signUp_show_password_checkbox'
                                type="checkbox"
                                onClick={(e) => { setShowPassword(!showPassword); }} />
                            Show Password
                        </div>
                    </div>
                </div>
                <div style={{ color: "red", fontSize: "14px", width: "100%" }}>
                    {error}
                </div>
                <div className="signin_div_button">
                    <button onClick={clickHandler}>
                        {isLoading ? "Please Wait..." : "Sign In"}
                    </button>
                </div>
                <div className="signin_div_bottom">
                    <div className="signin_div_bottom_left">
                        <ResetPassword trigger={<span>Forget Password?</span>} />
                        {/* //TODO: to handle the forget password */}
                    </div>
                    <div className="signin_div_bottom_right">
                        <Link to="/signup">Don't have an account? Signup</Link>
                    </div>
                </div>
                <CopyRight />
                <Snackbar open={open} message={snackBarMessage} autoHideDuration={1000} onClose={() => {
                    navigate('/profile')
                }} />
            </div>
        </div>
    )
}

export default Login; 