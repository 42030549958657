import { db } from '../../../firebase';
import { doc, limit } from 'firebase/firestore';
import { getDocs, getDoc, collection, where } from 'firebase/firestore';
import { query } from 'firebase/firestore';
import loginSlice from '../../../state/slices/loginSlice';
import store from '../../../state/store';
import constants from '../../../constants';
import studentliveExamSlice from '../../../state/slices/studentLiveExamSlice';

export let getStudentData = async (user, setter) => {
    if (!user.id) {
        let q = query(collection(db, "student"), where("email", "==", user.email), limit(1))
        let student = await getDocs(q)
        let snapshot = [];
        student.forEach((d) => {
            snapshot.push(d.id);
        })
        store.dispatch(loginSlice.actions.setId({ id: snapshot[0] }));
        if (snapshot[0]) {
            let data = await getDoc(doc(db, "student", snapshot[0]))
            data = data.data();
            store.dispatch(loginSlice.actions.setResults({ results: data.results || [] }));
        }
    } else {
        // let data = await getDoc(doc(db, "student", user.id))
        // data = data.data();
        // store.dispatch(studentliveExamSlice.actions.update(data.liveExams || []))
        // if (!data.liveExams || data.liveExams.length === 0) {
        //     return
        // }
        // let result = await fetch(constants.getLiveExamDetailFromList, {
        //     "method": "POST",
        //     "headers": {
        //         "Content-Type": "application/json"
        //     },
        //     "body": JSON.stringify({
        //         list: data.liveExams
        //     })
        // })
        // result = await result.json();
        // setter(result);
    }
}