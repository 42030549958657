import { createSlice } from '@reduxjs/toolkit';

let chapterSlice = createSlice({
    name: "chapterInfo",
    initialState: [],
    reducers: {
        update: (state, action) => {
            return [...action.payload]
        }
    }
})

export default chapterSlice