let style = {
  liveExamQuestion: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "10px 5%",
    overflowY:"scroll"
  },
  questionStyle: {
    fontSize: "20px",
    fontWeight: "800",
    marginTop: "2rem",
  },
  answerStyle: {
    display: "flex",
    flexDirection: "row",
    gap: "5%",
    flexWrap: "wrap",
    padding: "3%",
  },
  answerOptionStyle: {
    flex: "1 1 40%",
    border: "solid 1px black",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    margin: "12px 12px",
    cursor: "pointer",
    minWidth: "300px",
    fontSize: "20px",
  },
};
export default style;
