import Navbar from "../../../components/Navbar/Navbar";
import "./syllabus.css";
import Popup from "reactjs-popup";
import { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { Button } from "@mui/material";
import {
  collection,
  query,
  limit,
  getDocs,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase";
import Filter from "../../../components/FilterPopup/FilterPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { ref, deleteObject } from "firebase/storage";
import { simplifyDate } from "../../../customHooks/dateHook";
import { Typography } from "@mui/material";
import { HelmetProvider, Helmet } from "react-helmet-async";

export default function Syllabus(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const refresh = () => window.location.reload(true);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");
  let [severity, setSeverity] = useState("info");
  let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);
  let tags = useLoaderData();
  let navigate = useNavigate();
  let setOfTags = new Set();
  for (let i = 0; i < tags.length; i++) {
    for (let j = 0; j < tags[i].length; j++) {
      setOfTags.add(tags[i][j]);
    }
  }
  let [selectedTags, setSelectedTags] = useState([]);

  let [syllabus, setSyllabus] = useState([]);
  // console.log(syllabus);

  useEffect(() => {
    let q;
    if (selectedTags.length === 0) {
      q = query(collection(db, "Syllabus"), limit(6));
    } else {
      q = query(collection(db, "Syllabus"), where, limit(6));
    }
    getDocs(q).then((data) => {
      let dataShot = [];
      data.forEach((d) => {
        let snap = d.data();
        snap.id = d.id;
        dataShot.push(snap);
      });
      setSyllabus(dataShot);
    });
  }, [selectedTags]);

  function applyFilter() {
    return;
  }
  let admin = props.admin || false;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Loksewa Syllabus - LoksewaBook</title>
          <meta
            name="description"
            content="Explore the comprehensive Loksewa syllabus for various posts on LoksewaBook. Tailor your exam preparation to your field with our detailed syllabus information. Join our LoksewaBook community to access curated syllabi, ensuring your success in the exams."
          />
          <meta
            name="keywords"
            content="Loksewa Syllabus, Loksewa Exam Preparation, LoksewaBook Syllabus Info, lok sewa aayog exam syllabus, loksewa aayog syllabus, lok seva ayog syllabus"
          />
        </Helmet>
        <Navbar admin={admin} />
        <div className="page">
          <div className="page_container">
            <div className="page_header">
              <span className="heading">Syllabus :</span>

              <div>
                {admin ? (
                  <button
                    className="filter_btn"
                    onClick={() => {
                      navigate("/admin/add-syllabus");
                    }}
                  >
                    Add New
                  </button>
                ) : (
                  <></>
                )}

                {/* make this part  attractive*/}
                <Filter
                  trigger={<button className="filter_btn">Filter</button>}
                  content={Array.from(setOfTags)}
                  select={selectedTags}
                  setSelect={setSelectedTags}
                  applyFilter={applyFilter}
                />
              </div>
            </div>
            <div className="syllabus_body">
              {
                syllabus.length < 1 ?
                  <div style={{ height: "60vh" }}>
                    <Typography marginY={5} variant="h5" sx={{ textAlign: "center" }} >
                      There are no Syllabus right now. Please Contact Us For Further Information. Thank You !!!
                    </Typography>
                  </div> :
                  syllabus.map((s, i) => {
                    // v.title = v.title + "kdfja aldsfja alsdfj alsdkjf sld flskdjf dsfadf"
                    let titleLength = 80;
                    let descriptionLength = 185;

                    if (width > 1195) {
                      titleLength = 125;
                      descriptionLength = 335;
                    } else if (width < 1195 && width > 1050) {
                      titleLength = 100;
                      descriptionLength = 325;
                    } else if (width < 1050 && width > 900) {
                      descriptionLength = 280;
                    } else if (width < 900 && width > 850) {
                      titleLength = 85;
                      descriptionLength = 275;
                    } else if (width < 850 && width > 600) {
                      titleLength = 100;
                      descriptionLength = 125;
                    } else if (width < 284) {
                      titleLength = 50;
                      descriptionLength = 140;
                    }

                    s.title = s.title.length < titleLength ?
                      s.title : `${s.title.substring(0, titleLength)}...`;
                    s.description = s.description.length < descriptionLength ?
                      s.description : `${s.description.substring(0, descriptionLength)}...`;


                    return (
                      <div key={i} className="syllabusSnap">
                        <div className="vacancy_img_div">
                          <img src={s.photoUrl} className="vacancyImage" alt="" />
                        </div>
                        <a
                          href={s.pdfUrl}
                          target="_blank" rel="noreferrer"
                          style={{ textDecoration: " none", color: "black" }}
                        >
                          <div className="syllabusDescription">
                            <div className="syllabusTitle">{s.title}</div>
                            <div className="syllabusDate">
                              {simplifyDate({ dateObject: s.date })}
                            </div>
                            <div>{s.description}</div>
                          </div>
                        </a>
                        {admin ? (
                          <Popup
                            trigger={
                              <DeleteIcon
                                color="error"
                                sx={{
                                  fontSize: "20px",
                                  marginLeft: "30px",
                                  cursor: "pointer",
                                }}
                              ></DeleteIcon>
                            }
                            modal
                          >
                            {(close) => {
                              return (
                                <div className="delete_popup">
                                  <p>Are you sure you want to delete the vacancy ?</p>
                                  <div>
                                    <Button
                                      variant="contained"
                                      sx={{ marginRight: "15px" }}
                                      color="error"
                                      onClick={async () => {
                                        try {
                                          //first lets delete img...
                                          const imgRef = ref(
                                            storage,
                                            `image/syllabus/${s.photoName}`
                                          );
                                          deleteObject(imgRef);

                                          //then delete pdf...
                                          const pdfRef = ref(
                                            storage,
                                            `pdf/${s.pdfName}`
                                          );
                                          deleteObject(pdfRef);

                                          //now delete docs...
                                          await deleteDoc(doc(db, "Syllabus", s.id));
                                          setAutoHideDuration(2000);
                                          setMessage("Deleted successfully");
                                          setSeverity("success");
                                          setOpen(true);
                                          close();
                                          refresh();
                                        } catch (error) {
                                          setAutoHideDuration(2000);
                                          setMessage("Failed to delete");
                                          setSeverity("error");
                                          setOpen(true);
                                          close();
                                        }
                                      }}
                                    >
                                      Yes , I am sure
                                    </Button>
                                    <Button onClick={close} variant="contained">
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              );
                            }}
                          </Popup>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
              }
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
}
