import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../../customHooks/useAuth";
import { useSelector } from "react-redux";
import "./editProfile.css";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { deleteObject } from "firebase/storage";
import Navbar from "../../../components/Navbar/Navbar";

let EditProfile = () => {
  const currentUser = useAuth();
  let navigate = useNavigate();
  let as = useSelector((state) => {
    return state.loginInfo.loginAs;
  });
  let authenticationSuccessed = setTimeout(() => {
    if (as === "admin") navigate("/admin");
    else navigate("/login");
  }, 3 * 1000);
  useEffect(() => {
    return () => {
      clearTimeout(authenticationSuccessed);
    };
  });
  if (currentUser) {
    let name = currentUser.displayName?.split(":");
    if (name ? name[1] === "student" : false) {
      clearTimeout(authenticationSuccessed);
      return <EditProfileProtected />;
    }
  }
};

let EditProfileProtected = () => {
  let navigate = useNavigate();
  let { id, name, email, type, contactNo, gender, DOB, address, profilePic } =
    useSelector((state) => {
      return {
        id: state.loginInfo.id,
        name: state.loginInfo.name,
        email: state.loginInfo.email,
        type: state.loginInfo.loginAs,
        contactNo: state.loginInfo.contactNo,
        gender: state.loginInfo.gender,
        DOB: state.loginInfo.DOB,
        address: state.loginInfo.address,
        profilePic: state.loginInfo.profilePic,
      };
    });
  let [nameVal, setNameVal] = useState(name);
  let [contactNoVal, setContactNoVal] = useState(contactNo);
  let [pickedPic, setPickedPic] = useState(null);
  let [newDOB, setNewDOB] = useState(DOB ? DOB : "");
  let [newGender, setNewGender] = useState(gender ? gender : "");
  let [newAddress, setNewAddress] = useState(address);
  let [isLoading, setIsLoading] = useState(false);
  let [hasError, setHasError] = useState({
    value: false,
    message: "",
    where: "",
  });

  let checkErrors = () => {
    let msg = "***Please Check Again";
    // console.log("checking errors");
    if (nameVal === "") {
      msg = "***Name can't be empty.";
      setHasError({ value: true, message: msg, where: "name" });
      throw "Error found in user Input";
    }
    if (contactNoVal === "") {
      msg = "***Contact Num can't be empty.";
      setHasError({ value: true, message: msg, where: "contactNo" });
      throw "Error found in user Input";
    }
    if (contactNoVal.length !== 10) {
      setHasError({ value: true, message: msg, where: "contactNo" });
      throw "Error found in user Input";
    }
    if (newDOB === "") {
      msg = "***Please Select Your DOB.";
      setHasError({ value: true, message: msg, where: "DOB" });
      throw "Error found in user Input";
    }
    if (newGender === "") {
      msg = "***Please Select Your Gender.";
      setHasError({ value: true, message: msg, where: "gender" });
      throw "Error found in user Input";
    }
    if (newAddress === "") {
      msg = "***Address can't be empty.";
      setHasError({ value: true, message: msg, where: "address" });
      throw "Error found in user Input";
    }
    //no error (condition when error handled by user)
    if (hasError) {
      setHasError({ value: false, message: "", where: "" });
    }
  };

  let uploadedImage = async ({ file, name }) => {
    try {
      let referece = ref(storage, `image/student/${name}`);
      await uploadBytes(referece, file);
      let result = await getDownloadURL(referece);
      return result;
    } catch (error) {
      throw error;
    }
  };

  let OnSubmit = async ({
    newName,
    newContactNo,
    newPic,
    newGender,
    newAddress,
    newDOB,
  }) => {
    setIsLoading(true);
    try {
      checkErrors();
      if (newPic !== null) {
        let picName = `${id}---${name}`;
        //Note: Delete old pic from database...
        // const picRef = ref(storage, `image/student/${picName}`);
        // deleteObject(picRef);

        //upload newPic...
        uploadedImage({ file: newPic, name: picName }).then((d) => {
          //writing img url in docs...
          const studentRef = doc(db, "student", id);
          setDoc(
            studentRef, { profilePic: d },
            { merge: true }
          );
        });
      }

      //writing other datas in docs...
      const studentRef = doc(db, "student", id);
      setDoc(
        studentRef,
        {
          name: newName,
          contactNumber: newContactNo,
          gender: newGender,
          address: newAddress,
          DOB: newDOB,
        },
        { merge: true }
      );
      navigate("/profile");
    } catch (error) {
      // console.log("some Error occured here");
      // console.log(error);
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className="edit_profile">
      <Navbar />
      <div className="ep-main-page">
        <div className="ep-left-box">
          <div className="profilePage_left_top">
            {pickedPic ? (
              <img
                src={URL.createObjectURL(pickedPic)}
                className="ep-profile-pic"
                alt=""
              />
            ) : (
              <>
                {profilePic ? (
                  <img src={profilePic} className="ep-profile-pic" alt="" />
                ) : (
                  <div className="profilePage_left_top_letter">{name[0]}</div>
                )}
              </>
            )}
            <div
              style={{
                display: "flex",
                fontSize: "large",
                marginTop: "1.5rem",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {email}
              <label htmlFor="pp" className="change-pic-btn">
                Change Profile Picture
              </label>
              <input
                id="pp"
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setPickedPic(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>
        <div className="ep-right-box">
          <label htmlFor="ep-name">Your Name :</label>
          <input
            type="text"
            style={{ borderRadius: "5px" }}
            onChange={(val) => {
              setNameVal(val.target.value);
            }}
            value={nameVal}
          />
          <div className="ep-error-text">
            {hasError.value && hasError.where === "name"
              ? hasError.message
              : ""}
          </div>

          <div className="ep-input-gap"></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "49%" }}>
              <label htmlFor="ep-name">Contact Number :</label>
              <input
                type="number"
                style={{ borderRadius: "5px" }}
                onChange={(val) => {
                  setContactNoVal(val.target.value);
                }}
                value={contactNoVal}
              />
              <div className="ep-error-text">
                {hasError.value && hasError.where === "contactNo"
                  ? hasError.message
                  : ""}
              </div>
            </div>
            <div style={{ width: "49%" }}>
              <label htmlFor="ep-gender">Gender :</label>
              <select
                id="ep-gender"
                value={newGender}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  padding: "0.7rem 0rem",
                }}
                onChange={(event) => {
                  setNewGender(event.target.value);
                }}
              >
                <option value=""></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
              <div className="ep-error-text">
                {hasError.value && hasError.where === "gender"
                  ? hasError.message
                  : ""}
              </div>
            </div>
          </div>

          <div className="ep-input-gap"></div>
          <label htmlFor="ep-name">Date Of Birth :</label>
          <input
            type="date"
            value={newDOB}
            style={{ borderRadius: "5px" }}
            onChange={(event) => {
              setNewDOB(event.target.value);
            }}
          />
          <div className="ep-error-text">
            {hasError.value && hasError.where === "DOB" ? hasError.message : ""}
          </div>

          <div className="ep-input-gap"></div>
          <label htmlFor="ep-name">Address :</label>
          <input
            type="text"
            style={{ borderRadius: "5px" }}
            onChange={(event) => {
              setNewAddress(event.target.value);
            }}
            value={newAddress}
          />
          <div className="ep-error-text">
            {hasError.value && hasError.where === "address"
              ? hasError.message
              : ""}
          </div>

          <div className="ep-input-gap"></div>
          {/* submit and cancel buttons here */}
          <div className="ep-buttons">
            <div
              className="ep-submit-button ep-button"
              onClick={(event) => {
                if (!isLoading) {
                  // console.log("submit clicked...");
                  OnSubmit({
                    newName: nameVal,
                    newContactNo: contactNoVal,
                    newPic: pickedPic,
                    newAddress: newAddress,
                    newGender: newGender,
                    newDOB: newDOB,
                  });
                  event.preventDefault();
                }
              }}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </div>
            <div className="ep-buttons-gap"></div>
            <div
              className="ep-cancel-button ep-button"
              onClick={() => {
                navigate("/profile");
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
