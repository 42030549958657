import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { getDocs, collection, limit, query, orderBy } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { Link } from "react-router-dom";
import { simplifyDate } from "../../../../../customHooks/dateHook";
import VacancyCard from "../vacancyComponent/vacancyCard";

const SyllabusComponent = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1500 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1500, min: 993 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 993, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    let [syllabus, setSyllabus] = useState([]);
    useEffect(() => {
        let q = query(collection(db, "Syllabus"), limit(7));
        getDocs(q).then((data) => {
            let dataShot = [];
            data.forEach((d) => {
                let snap = d.data();
                snap.id = d.id;
                dataShot.push(snap);
            });
            setSyllabus(dataShot);
        });
    }, []);

    if (syllabus.length < 1) {
        return;
    }

    return (
        <div className="practiceSetComponent">
            <div className="practiceSetComponent_top">
                <h2 className="practiceSetComponent_top_header">
                    Syllabus For You
                </h2>
                <Link to="./syllabus-page"
                    className="practiceSetComponent_link Link">
                    See All
                </Link>
            </div>
            <Carousel
                responsive={responsive}
                infinite={false}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                className="carousel"
            >
                {
                    syllabus.map((v, index) => {
                        return (
                            <Link className="Link" key={index}
                                to="./syllabus-page" >
                                <VacancyCard
                                    time={simplifyDate({ dateObject: v.date })}
                                    thumbnail={v.photoUrl}
                                    title={v.title}
                                    description={v.description}
                                />
                            </Link>
                        );
                    })
                }
            </Carousel>
        </div>
    );
};

export default SyllabusComponent;
