import * as React from 'react';
import { useLocation } from "react-router"
import { useEffect, useState } from "react"
import { collection, getDoc, doc } from "firebase/firestore"
import { db } from "../../../firebase"
import Navbar from "../../../components/Navbar/Navbar";
import {
    Paper, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Typography
} from "@mui/material"
import TablePagination from '@mui/material/TablePagination';
import "./overallResult.css"

let OverAllResult = () => {
    let { id } = useLocation().state
    let [examData, setExamData] = useState({});
    let [items, setItems] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getDoc(doc(collection(db, "liveExam"), id)).then((data) => {
            data = data.data()
            let result = data.results;
            result.sort((a, b) => b.marks - a.marks);
            // console.log("sorted :", result)
            setItems(result);

            data.totalQuestion = data.questions.length;
            delete data.results;
            delete data.questions;
            delete data.tags;
            delete data.description;
            delete data.price;
            setExamData(data);
        }).catch((error) => {
            // console.log(error)
        })
    }, [id])

    // console.log("data = ", examData);
    // let dummyList = [
    //     { name: "Pratik Lama", email: "Kukipratik123@gmail.com", marks: 2 },
    //     { name: "Pratik Lama", email: "Kukipratik123@gmail.com", marks: 2 },
    //     { name: "Pratik Lama", email: "Kukipratik123@gmail.com", marks: 2 },
    //     { name: "Pratik Lama", email: "Kukipratik123@gmail.com", marks: 2 },
    // ];

    return (
        <>
            <Navbar />
            <div className="overall_result_page">
                <div className="overall_top_part">
                    <div className="overall_top_left">
                        {examData.title}
                        <div style={{ fontSize: "medium" }} >
                            <span className="overall_fieldName"> Author:</span> {examData.author}
                        </div>
                    </div>
                    <div className="overall_vr_line_top"></div>
                    <div className="overall_hr_line_top"></div>
                    <div className="overall_top_right">
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Available Date:</span> {examData.date}
                        </div>
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Available Time:</span> {examData.startTime} to {examData.endTime}
                        </div>
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Full Marks:</span> {examData.fullMarks}
                        </div>
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Pass Marks:</span> {examData.passMarks}
                        </div>
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Negative Marking: </span>-{examData.negativeMarks}
                        </div>
                        <div className="overall_top_right_item">
                            <span className="overall_fieldName"> Total Questions: </span>{examData.totalQuestion}
                        </div>
                    </div>
                </div>
                <div className="overall_hr_line"></div>
                <div className="overall_result_show_table">
                    <Typography variant="h3" gutterBottom>Overall Result</Typography>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 540 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: "15px", fontWeight: "bolder" }}>Rank</TableCell>
                                        <TableCell sx={{ fontSize: "15px", fontWeight: "bolder" }}>Name</TableCell>
                                        <TableCell sx={{ fontSize: "15px", fontWeight: "bolder" }}>Email</TableCell>
                                        <TableCell sx={{ fontSize: "15px", fontWeight: "bolder" }}>Marks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell sx={{ fontSize: "1.35rem" }}>{index + rowsPerPage * page + 1}</TableCell>
                                                <TableCell sx={{ fontSize: "1.35rem" }}>{row.name}</TableCell>
                                                <TableCell sx={{ fontSize: "1.35rem" }}>{row.email}</TableCell>
                                                <TableCell sx={{ fontSize: "1.35rem" }}>{row.marks}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10]}
                            component="div"
                            count={items.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ fontSize: "10px" }}
                        />
                    </Paper>
                </div>
            </div>
        </>
    )

}

export default OverAllResult