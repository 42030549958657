import { Button, Input } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { auth } from '../../firebase';
import style from './resetPasswordStyle'

const ResetPassword = (props) => {
    let [email, setEmail] = useState("");
    let [emailError, setEmailError] = useState("");
    let [message, setMessage] = useState("");
    let validateEmail = (mail) => {
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail)) {
            return true;
        } else {
            return false;
        }
    }
    return <Popup trigger={props.trigger} modal>
        {(close) => {
            return <div style={style.mainStyle}><div>
                We will be sending you a link to reset your password.
                <div style={style.inputBlock}>
                    <label htmlFor="email" style={style.inputBlockChild1}>Email :</label>
                    <div style={style.inputBlockChild2}><Input sx={style.inputStyle} type='text' id="email" value={email} onChange={(event) => {
                        setEmail(event.target.value);
                    }} /></div>
                </div>
                <span>{emailError}</span>
                <span>{message}</span>
                <Button sx={{fontSize:"15px",fontWeight:"bold",borderWidth:"2px"}} variant="outlined" onClick={async () => {
                    if (validateEmail(email)) {
                        try {
                            // console.log(email);
                            let result = await sendPasswordResetEmail(auth, email)
                            // console.log(result)
                            setMessage("A link to reset your password was send to your mail");
                            setTimeout(() => {
                                setMessage("");
                                setEmailError("");
                                close();
                            }, 2000)
                        } catch (error) {
                            // console.log(error);
                            // console.log("Some error occured");
                            setEmailError("Error type ", error.message);
                        }
                    } else {
                        setEmailError("Enter a valid email address")
                    }
                }}>Send Email</Button></div>
            </div>
        }}
    </Popup>
}

export default ResetPassword