import React from "react";
import "./SideNav.css";
import Logo from "../Logo";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { auth } from "../../firebase";
import loginSlice from "../../state/slices/loginSlice";
import { signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const SideNav = ({ Close, admin }) => {
  let dispatch = useDispatch();

  const login = useSelector((state) => state.loginInfo.login);

  return (
    <div className="sidebar_container">
      <div className="sidebar_container_div">
        <div className="sidebar_container_div_item_header">
          <Link to="/">
            <Logo width={70} />
          </Link>
        </div>
        <div className="sidebar_container_div_item">
          {" "}
          <Link to="/">Home</Link>
        </div>
        <div className="sidebar_container_div_item">
          <Link to="/mcq-book-page">MCQ Book</Link>
        </div>
        <div className="sidebar_container_div_item">
          {" "}
          <Link to="/practice-set-page">Practice Page</Link>
        </div>
        <div className="sidebar_container_div_item">
          <Link to="/live-exam-page">Live Exam</Link>
        </div>
        <div className="sidebar_container_div_item">
          <Link to="/syllabus-page">Syllabus</Link>
        </div>
        <div className="sidebar_container_div_item">
          <Link to="/vacancy-page">Vacancy</Link>
        </div>
        {admin ? (
          <>
            <div className="sidebar_container_div_item special_links">
              <Link
                to={login ? "/admin/profile" : "/admin"}
                className="side_nav_logs"
              >
                <div>{login ? "Profile" : "Login"}</div>
                <div>{login ? <AccountCircleIcon /> : <LoginIcon />}</div>
              </Link>
            </div>
            <div className="sidebar_container_div_item">
              <Link
                to={login ? "/admin" : "/admin/signup"}
                className="side_nav_logs"
              >
                <div
                  onClick={() => {
                    dispatch(loginSlice.actions.logOut());
                    signOut(auth);
                  }}
                >
                  {login ? "Log Out" : "Sign Up"}
                </div>
                <div>{login ? <LogoutIcon /> : <PersonAddIcon />}</div>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="sidebar_container_div_item special_links">
              <Link
                to={login ? "/profile" : "/login"}
                className="side_nav_logs"
              >
                <div>{login ? "Profile" : "Login"}</div>
                <div>{login ? <AccountCircleIcon /> : <LoginIcon />}</div>
              </Link>
            </div>
            <div className="sidebar_container_div_item">
              <Link to={login ? "/" : "/signup"} className="side_nav_logs">
                <div
                  onClick={() => {
                    dispatch(loginSlice.actions.logOut());
                    signOut(auth);
                  }}
                >
                  {login ? "Log Out" : "Sign Up"}
                </div>
                <div>{login ? <LogoutIcon /> : <PersonAddIcon />}</div>
              </Link>
            </div>
          </>
        )}
      </div>
      <div onClick={Close} className="backdrop_nav"></div>
    </div>
  );
};

export default SideNav;
