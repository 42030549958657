let style={
    fullStyle:{
        width:"100vw",
        height:"100vh",
        backgroundColor:"rgba(255,255,255,12)",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        fontSize:"20px",
    },
    mainStyle:{
        width:"90vw",
        height:"90vh",
        backgroundColor:"white",
        padding:"5% 10%",
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    tagStyle:{
        display:"flex",
        gap:"10px"
    }
}

export default style;