import React from "react";
import "./ExamDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import useAuth from "../../../customHooks/useAuth";
import { Snackbar, Typography } from "@mui/material";
import Navbar from "../../../components/Navbar/Navbar";
import { doc, collection, getDoc, getDocs, updateDoc, arrayUnion, setDoc } from "firebase/firestore";
import { db, } from "../../../firebase";
import { query, where } from "firebase/firestore";

const ExamDetail = () => {
  let auth = useAuth();
  let navigate = useNavigate();
  let authenticationSuccessed = setTimeout(() => {
    navigate("/login");
  }, 3 * 1000);
  useEffect(() => {
    return () => {
      clearTimeout(authenticationSuccessed);
    };
  });
  if (auth && auth?.displayName?.includes(":student")) {
    clearTimeout(authenticationSuccessed);
    return <ProtectedExamDetail />;
  }
};

const ProtectedExamDetail = () => {
  let state = useLocation().state;
  // console.log(useLocation())
  // console.log(state)
  const refresh = () => window.location.reload(true);
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [paidLoading, setPaidLoading] = useState(false);
  let [id] = useState(state.id);
  let { user, liveExams } = useSelector((state) => {
    return { user: state.loginInfo, liveExams: state.studentLiveExam.data };
  });
  let examDate = new Date(`${state.date}`);
  let start = new Date(`${state.date}:${state.startTime}`);
  let end = new Date(`${state.date}:${state.endTime}`);
  let now = new Date(Date.now());
  let [giveExam] = useState(() => {
    if ((now - start) * (now - end) < 0) return true;
    else return false;
  });
  let [canPurchase] = useState(() => {
    if (now < end) return true;
    else return false;
  });

  //fetching qrcode form firebase...
  let [qrCodeData, setQrCodeData] = useState(null);
  useEffect(() => {
    async function fetchQRCode() {
      const docRef = doc(db, "qrCodes", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQrCodeData(docSnap.data())
      } else {
        setQrCodeData(null);
      }
    }
    fetchQRCode();
  }, [id]);

  useEffect(() => {
    function fetchIfPending() {
      const q = query(collection(db, "pendingPayment"),
        where("stdId", "==", user.id), where("examId", "==", id));
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((_doc) => {
          setPaidLoading(true);
        });
      });
    }
    fetchIfPending();
  }, [id, user]);

  let handleFreePurchase = async () => {
    let student = doc(db, "student", user.id)
    await updateDoc(student, {
      liveExams: arrayUnion(id)
    });
    refresh();
  };

  let handlePaid = async () => {
    if (!paidLoading) {
      setPaidLoading(true);
      //writing in docs...
      let examId = id;
      const pendingRef = doc(collection(db, "pendingPayment"));
      setDoc(
        pendingRef,
        {
          stdId: user.id,
          date: new Date(),
          examId: examId,
        }
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="examDetail">
        <div className="examDetial_header">Exam Details</div>
        <div className="examDetial_body">
          <div className="examDetail_body_left">
            <div className="examDetail_body_left_title">{state.title}</div>
            <div className="examDetail_body_left_body">
              {state.description.split("\n").map((d, i) => {
                return (
                  <div style={{ whiteSpace: "pre-wrap" }} key={i}>
                    {d}
                  </div>
                );
              })}
            </div>
            <div className="examDetail_body_left_detail">
              <div className="examDetail_body_left_detail_item">
                Full Marks: {state.fullMarks}
              </div>
              <div className="examDetail_body_left_detail_item">
                Pass Marks: {state.passMarks}
              </div>
              {/* <div className="examDetail_body_left_detail_item">
                Total Questions: 100
              </div> */}
              <div className="examDetail_body_left_detail_item">
                Negative Marking: -{state.negativeMarks}
              </div>
              <div className="examDetail_body_left_detail_item">
                Available Date: {examDate.toDateString()}
              </div>
              <div className="examDetail_body_left_detail_item">
                Available Time:{" "}
                {start.toLocaleTimeString().toString().slice(0, 5) +
                  start.toLocaleTimeString().toString().slice(8)}{" "}
                to{" "}
                {end.toLocaleTimeString().toString().slice(0, 5) +
                  end.toLocaleTimeString().toString().slice(8)}
              </div>
            </div>
          </div>
          <div className="examDetail_body_right">
            <div className="examDetail_body_right_top">
              <div className="examDetail_body_right_top_price">Price:</div>
              <div className="examDetail_body_right_top_value">
                Rs. {state.price}
              </div>
              <div className="examDetail_body_right_top_button">
                {liveExams.includes(id) && state.profile ? (
                  //give exam...
                  <button
                    className="examDetail_body_right_top_button"
                    onClick={async () => {
                      if (giveExam && !state.contains) {
                        setLoading(true);
                        navigate("/online-exam-place/" + id);
                      }
                    }}
                  >
                    {!state.contains ? giveExam && loading
                      ? "Loading..."
                      : giveExam
                        ? "Give Exam"
                        : "See Exam Time" : "See Exam Time"}
                  </button>
                ) : (
                  //do purchase...
                  <>
                    {
                      (qrCodeData === null) ?
                        <button className="examDetail_body_right_top_button"
                          onClick={async () => {
                            handleFreePurchase();
                          }}
                        >
                          {"Purchase"}
                        </button>
                        :
                        <Popup
                          trigger={
                            <button className="examDetail_body_right_top_button">
                              {paidLoading ? "Approving..." : "Purchase"}
                            </button>
                          }
                          modal
                        >
                          {(close) => {
                            return (
                              <div
                                style={{
                                  backgroundColor: "whitesmoke",
                                  color: "black",
                                  padding: "25px 18px",
                                  borderRadius: "15px",
                                  border: "2px solid grey",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100vh",
                                  width: "100vw",
                                }}
                              >
                                <Typography variant="h6" sx={{ mb: "15px" }}>
                                  {canPurchase ?
                                    <>
                                      {qrCodeData === null ?
                                        <div style={{ fontSize: "larger", textAlign: "center" }}>
                                          No QR Code Available.<br /> Please Contact Us For More Info.
                                        </div> :
                                        <>
                                          <img
                                            src={qrCodeData.qrCode}
                                            className="exam_detail_display_qr"
                                            alt="Selected QR Code"
                                          />
                                          {paidLoading ?
                                            <div style={{ fontSize: "larger", textAlign: "center" }}>
                                              Approving Your Payment.<br /> Please Wait.
                                            </div> : ""}
                                        </>
                                      }
                                    </>
                                    : "Sorry, This Exam Is Completed."}
                                </Typography>
                                <div className="examDetail_popup_btn_grp">
                                  {qrCodeData === null || !canPurchase ?
                                    <></> :
                                    <div className="examDetail_popup_btn green_btn"
                                      onClick={async () => {
                                        handlePaid();
                                        close();
                                      }}
                                    >
                                      {paidLoading ? "Pending..." : "Paid"}
                                    </div>
                                  }

                                  <div className="examDetail_popup_btn red_btn"
                                    onClick={async () => {
                                      close();
                                    }}
                                  >
                                    Close
                                  </div>
                                </div>
                              </div>
                            );
                            //TODO: from here
                          }}
                        </Popup>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Snackbar />
        {/* TODO: to implement the Snackbar */}
      </div>
    </>
  );
};

export default ExamDetail;
