import React from "react";
import "./SidebarMob.css";
import Sidebar from "../Sidebar/Sidebar";
import CloseIcon from "@mui/icons-material/Close";

const SidebarMob = ({ items, closeModal, onItemClick }) => {
  return (
    <div className="sidebarMob">
      <div className="sidebarMob_head">
        <span>Select Category</span>
        <div onClick={closeModal}>
          <CloseIcon className="sidebarMob_close" />
        </div>
      </div>
      <div className="sidebarMod_item">
        <Sidebar items={items} onItemClick={onItemClick} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default SidebarMob;
