import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { simplifyDate } from '../../../../customHooks/dateHook';
import { useNavigate } from 'react-router';
import { Alert, Snackbar, Typography } from "@mui/material"
import { useState } from 'react';

const columns = [
    { id: 'date', label: 'Date', minWidth: 80 },
    { id: 'exam', label: 'Exam', minWidth: 100 },
    { id: 'yourResult', label: 'Your Result', minWidth: 100 },
    { id: 'overallResult', label: 'Overall Result', minWidth: 100 },
];

function createRowData(date, exam, yourResult, overallResult) {
    return { date, exam, yourResult, overallResult };
}

export default function ResultTable({ results, liveExams }) {
    let [open, setOpen] = useState(false);
    let [message, setMessage] = useState("");
    let [severity, setSeverity] = useState("info");
    let [autoHideDuration, setAutoHideDuration] = useState(60 * 1000);

    const rows = [];
    let navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // console.log("liveExams = ", liveExams)

    for (let index = 0; index < results.length; index++) {
        const result = results[index];
        var realDeadLine = result.deadline - 20700000;
        const dateObj = new Date(realDeadLine);
        rows.push(createRowData(simplifyDate({ dateObject: dateObj }), result.title, "View Result", "View Result"));
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        fontSize: "15px",
                                        fontWeight: "bolder"
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowNum) => {
                                // var notRealDeadLine = new Date(results[rowNum].deadline);
                                // var realDeadLine = new Date(results[rowNum].deadline - 86400000);
                                // console.log("not real = ", notRealDeadLine);
                                // console.log("r dl = ", realDeadLine);

                                var realDeadLine = results[rowNum].deadline - 20700000;

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={rowNum}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell sx={{ fontSize: "1.35rem" }}
                                                    key={column.id} align={column.align}>
                                                    {(column.id === "yourResult") ?
                                                        <div onClick={() => {
                                                            if (Date.now() > realDeadLine) {
                                                                navigate('../score-board', {
                                                                    state: {
                                                                        result: results[rowNum],
                                                                    },
                                                                })
                                                            } else {
                                                                setAutoHideDuration(3000)
                                                                setMessage("Please Let The Exam Finish First.");
                                                                setSeverity("error")
                                                                setOpen(true)
                                                            }
                                                        }}>
                                                            <span style={{
                                                                textDecoration: "underline",
                                                                color: "blue",
                                                                cursor: "pointer"
                                                            }}>{value}</span>
                                                        </div>
                                                        :
                                                        <> {(column.id === "overallResult") ?
                                                            <span style={{
                                                                textDecoration: "underline",
                                                                color: "blue",
                                                                cursor: "pointer"
                                                            }} onClick={() => {
                                                                let examId = liveExams[rowNum];
                                                                if (examId && Date.now() > realDeadLine) {
                                                                    navigate("../overall-result", {
                                                                        state: { id: examId }
                                                                    })
                                                                } else {
                                                                    setAutoHideDuration(3000)
                                                                    setMessage("Please Let The Exam Finish First.");
                                                                    setSeverity("error")
                                                                    setOpen(true)
                                                                }
                                                            }}>{value}</span>
                                                            :
                                                            <>{value}</>
                                                        }</>
                                                    }
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ fontSize: "10px" }}
            />
            <Snackbar open={open} autoHideDuration={autoHideDuration}
                onClose={() => {
                    setOpen(false)
                    setMessage("")
                    setSeverity("info")
                    setAutoHideDuration(60 * 3000)
                }}>
                <Alert severity={severity}>
                    <Typography variant='h6'>{message}</Typography>
                </Alert>
            </Snackbar>
        </Paper >
    );
}