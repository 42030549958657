import React, { useState } from "react";
import Pagination from '@mui/material/Pagination';
import "./DisplayPracticeSetCard.css";
import { Typography } from "@mui/material";
import PracticeExamCard from "../../../../components/PracticeExamCard/PracticeExamCard";

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default function DisplayPracticeSetCard({ maxItemPerPage, data, admin = false }) {
    let [page, setPage] = useState(1);

    const count = Math.ceil(data.length / maxItemPerPage);
    const _DATA = usePagination(data, maxItemPerPage);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    if (count === 0) {
        return (
            <>
                <Typography marginTop={5} variant="h5" sx={{ textAlign: "center" }} >
                    There are no practice set right now. Please Contact Us if you have any request. Thank You !!!
                </Typography>
            </>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="practiceExam_button_exams_grid">
                {_DATA.currentData().map((data, i) => {

                    return (
                        <PracticeExamCard key={i} data={data} admin={admin} />
                    );
                })}
            </div>

            {data.length > 3 ? (
                <>
                    {/* this pagination will be hidden for small device */}
                    <Pagination
                        className="pagination-for-large-device"
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 3.5 }}
                    />
                    {/* this pagination will be hidden for large device */}
                    <Pagination
                        className="pagination-for-small-device"
                        count={count}
                        size="small"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ marginTop: 2 }}
                    />
                </>
            ) : (<></>)}
        </div>
    );
}
