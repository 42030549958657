import { createBrowserRouter, Outlet } from "react-router-dom";

import { query, collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

import React from "react";

import Home from "../pages/main/HomePage/Home";
import McqBookPage from "../pages/main/McqBookPage/McqBookPage";
import PracticeSetPage from "../pages/main/PracticeSetPage/PracticeSetPage";
import LiveExamPage from "../pages/main/LiveExamPage/LiveExamPage";
import ProfilePage from "../pages/main/ProfilePage/ProfilePage";

import ScoreBoard from "../pages/other/ScoreBoard/ScoreBoard";
import ExamDetail from "../pages/other/ExamDetail/ExamDetail";
import ExamPlace from "../pages/other/ExamPlace/ExamPlace";
import PracticeDetail from "../pages/other/PracticeDetial/PracticeDetail";
import Footer from "../components/Footer/Footer";
import Login from "../pages/main/Login/login";
import SignUp from "../pages/main/SignUp/signup";
import AdminLogin from "../pages/Admin/Login/login";
import Chapter from "../pages/Admin/Chapter/chapter";
import AdminLiveExam from "../pages/Admin/LiveExam/AdminLiveExam";
import LiveExamQuestionPage from "../pages/Admin/LiveExamQuestions/LiveExamQuestionPage";
import AddTags from "../pages/Admin/AddTags/AddTags";
import constants from "../constants";
import GenerateMCQPage from "../pages/Admin/GenerateMCQPage/GenerateMCQPage";
import AddAdmin from "../pages/Admin/AddAdmin/addAdmin";
import ChangePassword from "../pages/other/changePassword/changePassword";
import EditProfile from "../pages/other/EditProfile/EditProfile";
import AdminProfile from "../pages/Admin/AdminProfile/AdminProfile";
import PracticeExamPage from "../pages/other/PracticeExamPage/PracticeExamPage";
import Syllabus from "../pages/main/Syllabus/syllabus";
import CreateSyllabus from "../pages/Admin/CreateSyllabus/createSyllabus";
import Vacancy from "../pages/main/Vacancy/vacancy";
import NewVacancy from "../pages/Admin/NewVacancy/NewVacancy";
import VacancyDetail from "../pages/other/vacancyDetail/vacancyDetail";
import { Suspense } from "react";
import PracticeSetResult from "../pages/other/PracticeSetResult/PracticeSetResult";
import AddQrCode from "../pages/Admin/AddQrCode/AddQrCode";
import OverAllResult from "../pages/other/OverAllResult/Overallresult";
import ErrorElement from "../components/ErrorElement/ErrorElement";
import AddThumbnailToExam from "../pages/Admin/AddThumbnailToExam/AddThumbnailToExam";
import AllQuestions from "../pages/other/AllQuestions/AllQuestions";
import AskQuestion from "../pages/other/AskQuestion/AskQuestion";
import AddQuestionCSV from "../pages/Admin/AddQuestionCSV/add-question-csv";

const adminRouteElements = [
  {
    path: "",
    index: true,
    element: <AdminLogin />,
  },
  {
    path: "add-practice-set",
    index: true,
    loader: async () => {
      let q = query(collection(db, "chapter"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      instance = await getDocs(collection(db, "tags"))
      let tags = []
      instance.forEach((data) => {
        tags.push(data.data().field)
      })
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return { chapter: result, tags: retTag };
    },
    element: <GenerateMCQPage />,
  },
  {
    path: "add-question-csv",
    element: <AddQuestionCSV />,
  },
  // {
  //   path: "practice-exam-page",
  //   element: <AdminPracticeSetPage />,
  //   // errorElement: <ErrorPage />,
  // },
  {
    path: "question",
    loader: async () => {
      let q = query(collection(db, "chapter"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    element: <McqBookPage admin={true} />,
  },
  {
    path: "chapter",
    loader: async () => {
      let q = query(collection(db, "chapter"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    element: <Chapter />,
  },

  //for showing syllabus list for admin
  {
    loader: async () => {
      let tags = await getDocs(collection(db, "tags"));
      let result = [];
      tags.forEach((t) => {
        result.push(t.data().field);
      });

      return result;
    },
    path: "syllabus-page",
    element: <Syllabus admin={true} />,
  },

  //for adding new syllabus
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    path: "add-syllabus",
    element: <CreateSyllabus />,
  },

  //for showing vacancy list for admin
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    path: "vacancy-page",
    element: <Vacancy admin={true} />,
  },

  // {
  //   path: "vacancy",
  //   element: <VacancyDetail />
  // },

  //for adding new vacancy
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    path: "addVacancy",
    element: <NewVacancy />,
  },

  //For showing all live exams list...
  {
    loader: async () => {
      let [response, tags] = await Promise.all([
        fetch(constants.getLiveExamCardUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start: 0,
          }),
        }),
        getDocs(query(collection(db, "tags"))),
      ]);
      response = await response.json();
      let tagArray = [];
      tags.forEach((t) => {
        tagArray.push(t.data().field);
      });
      let retTag = new Set();
      while (tagArray.length > 0) {
        for (let i = 0; i < tagArray.length; i++) {
          if (tagArray[i].length === 0) {
            tagArray.splice(i, 1);
          } else {
            retTag.add(tagArray[i][0]);
            tagArray[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return [retTag, response.data];
    },
    path: "live-exam-page",
    element: <Suspense fallback={<div>Loading live exam .....</div>}>
      <LiveExamPage admin={true} />
    </Suspense>
  },

  //This path is used to add the live exam by the admin
  {
    path: "add-live-exam",
    element: <AdminLiveExam />,
  },

  {
    loader: async () => {
      let q = query(collection(db, "tags"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    path: "live-exam-question",
    element: <LiveExamQuestionPage />,
  },

  //for adding Qr code in live exam...
  {
    path: "add-qr-code",
    element: <AddQrCode />,
  },

  //for adding new admin...
  {
    path: "add-admin",
    element: <AddAdmin />,
  },

  //for practice set page
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    path: "practice-set-page",
    element: <PracticeSetPage admin={true} />,
    //errorElement: <ErrorPage />,
  },

  //for generating new Practice set
  {
    path: "add-practice-set",
    loader: async () => {
      let q = query(collection(db, "chapter"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    element: <GenerateMCQPage />,
  },

  {
    path: "change-password",
    element: <ChangePassword />,
  },

  {
    path: "add-tags",
    loader: async () => {
      let q = query(collection(db, "tags"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    element: <AddTags />,
  },

  {
    path: "profile",
    element: <AdminProfile />,
  },
  {
    path: "add-thumbnail",
    element: <AddThumbnailToExam />,
  },
];

const studentRouteElements = [
  //for home page
  {
    path: "/",
    index: true,
    element: <Home />,
  },
  //for MCQ Book Page
  {
    path: "mcq-book-page",
    loader: async () => {
      let q = query(collection(db, "chapter"));
      let instance = await getDocs(q);
      let result = [];
      instance.forEach((data) => {
        result.push(data.data());
      });
      return result;
    },
    element: <McqBookPage />,
    errorElement: <ErrorElement />,
  },
  //for practice set page
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    path: "practice-set-page",
    element: <PracticeSetPage />,
    errorElement: <ErrorElement />,
  },
  //for Live Exam page
  {
    loader: async () => {
      let [response, res] = await Promise.all([
        fetch(constants.getLiveExamCardUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            start: 0,
          }),
        }),
        getDocs(query(collection(db, "tags"))),
      ]);
      response = await response.json();
      let retTag = new Set();
      let tags = []
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      return [Array.from(retTag), response.data];
    },
    path: "live-exam-page",
    element: <LiveExamPage />,
    errorElement: <ErrorElement />,
  },
  //for syllabus list page
  {
    loader: async () => {
      let tags = await getDocs(collection(db, "tags"));
      let result = [];
      tags.forEach((t) => {
        result.push(t.data().field);
      });

      return result;
    },
    path: "syllabus-page",
    element: <Syllabus />,
    errorElement: <ErrorElement />,
  },
  //for profile page
  {
    path: "profile",
    element: <ProfilePage />,
    errorElement: <ErrorElement />,
  },
  //vacancy page
  {
    loader: async () => {
      let tags = [];
      let res = await getDocs(collection(db, "tags"));
      res.forEach((d) => {
        tags.push(d.data().field);
      });
      let retTag = new Set();
      while (tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].length === 0) {
            tags.splice(i, 1);
          } else {
            retTag.add(tags[i][0]);
            tags[i].splice(0, 1);
          }
        }
      }
      retTag = Array.from(retTag);
      return retTag;
    },
    //this will show all vacancies list...
    path: "vacancy-page",
    element: <Vacancy />,
    errorElement: <ErrorElement />,
  },
  // for vacancy details
  {
    path: "vacancy-detail-page/:id",
    element: <VacancyDetail admin={false} />,
    errorElement: <ErrorElement />,
    loader: async ({ params }) => {
      let snap = await getDoc(doc(collection(db, "vacancy"), params.id));
      return snap.data();
    },
  },
  //for practice set detail page
  {
    path: "practice-set-detail/:id",
    element: <PracticeDetail />,
    loader: async ({ params }) => {
      let load = await getDoc(doc(collection(db, "practiceSet"), params.id));
      return {
        id: load.id,
        ...load.data(),
      };
    },
    errorElement: <ErrorElement />,
  },
  //for Live exam Detail page
  {
    loader: async () => { },
    path: "live-exam-detail",
    element: <ExamDetail />,
    errorElement: <ErrorElement />,
  },
  {
    path: "overall-result",
    element: <OverAllResult />,
    errorElement: <ErrorElement />,
  },
  //for online exam page...where std will be giving exam
  {
    path: "practice-exam-page",
    element: <PracticeExamPage />,
    errorElement: <ErrorElement />,
  },
  {
    //TODO: We need to filter the content in the page we need to delete some content from the result
    path: "online-exam-place/:id",
    element: <ExamPlace />,
    loader: async ({ params }) => {
      let rest = await fetch(constants.getQuestionPageUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: params.id, //TODO: we will get this value from other places where the direction gets from
        }),
      });
      rest = await rest.json();
      if (rest.status) {
        return { load: rest.load, id: rest.id, proceedForExam: true };
      } else {
        return { proceedForExam: false, message: rest.message };
      }
    },
    errorElement: <ErrorElement />,
  },
  //for displaying all questions...
  {
    path: "all-questions",
    element: <AllQuestions />,
    errorElement: <ErrorElement />,
  },
  //for showing a question...
  {
    path: "ask-question/:id",
    element: <AskQuestion />,
    errorElement: <ErrorElement />,
  },
  //for result page of Practice Set
  {
    path: "practice-set-result",
    element: <PracticeSetResult />,
    errorElement: <ErrorElement />,
  },
  //for scorebord page
  {
    path: "score-board",
    element: <ScoreBoard />,
    errorElement: <ErrorElement />,
  },
];

const router = createBrowserRouter([
  //for Students...
  {
    path: "/",
    element: (
      <>
        <Outlet />
        <Footer />
      </>
    ),
    children: [...studentRouteElements],
  },

  //for Admin...
  {
    path: "admin",
    element: (
      <>
        <Outlet />
        <Footer />
      </>
    ),
    children: [...adminRouteElements],
  },
  //for login...(students)
  {
    path: "login",
    element: <Login />,
  },
  //for signup...(students)
  {
    path: "signup",
    element: <SignUp />,
  },
  //for changing password
  {
    path: "change-password",
    element: <ChangePassword />,
  },
  //for editing profile
  {
    path: "edit-profile",
    element: <EditProfile />,
  },
]);

export default router;
