import React from "react";
import "./PracticeSetPage.css";
import PracticeExamCard from "../../../components/PracticeExamCard/PracticeExamCard";
import { useState, useEffect } from "react";
import FilterPopup from "../../../components/FilterPopup/FilterPopup";
import Navbar from "../../../components/Navbar/Navbar";
import { getDocs, collection, limit, where } from "firebase/firestore";
import { query } from "firebase/firestore";
import { db } from "../../../firebase";
import { orderBy } from "firebase/firestore";
import { startAfter } from "firebase/firestore";
import { useLoaderData, useNavigate } from "react-router";
import DisplayPracticeSetCard from "./component/DisplayPracticeSetCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PracticeSetPage = (props) => {
  const tags = useLoaderData();
  let [page, setPage] = useState(1);
  let [practiceSet, setPracticeSet] = useState([]);
  let [filter, setFilter] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let q = query(
      collection(db, "practiceSet"),
      orderBy("timeStamp"),
      startAfter((page - 1) * 12),
      limit(12)
    );
    getDocs(q).then((data) => {
      // console.log(data);
      let dataShot = [];
      data.forEach((d) => {
        let x = d.data();
        x.id = d.id;
        dataShot.push(x);
      });
      // console.log(dataShot);
      setPracticeSet(dataShot);
    });
  }, [page]);

  let applyFilter = async () => {
    let rs;
    if (filter.length === 0) {
      rs = await getDocs(query(collection(db, "practiceSet")));
    } else {
      rs = await getDocs(
        query(
          collection(db, "practiceSet"),
          where("tags", "array-contains-any", filter)
        )
      );
    }
    let exam = [];
    rs.forEach((d) => {
      let a = d.data();
      a.id = d.id;
      exam.push(a);
    });
    // console.log(exam);
    setPracticeSet(exam);
  };

  let admin = props.admin || false;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Practice Sets for Loksewa Exam - LoksewaBook</title>
          <meta
            name="description"
            content="Elevate your Loksewa exam preparation with LoksewaBook's free practice sets. Access carefully curated practice sets designed to align with Loksewa exam patterns. These sets offer invaluable preparation material, ensuring your success in the Loksewa exams. Practice unlimited sets according to your field, whether you're a civil engineer or in another profession. LoksewaBook: Where knowledge knows no bounds and excellence is attainable."
            keywords="Loksewa Practice Sets, Loksewa Exam Preparation, Free Practice Sets, LoksewaBook"
          />
          <script type="application/ld+json">
            {`{
        "@context": "http://schema.org",
        "@type": "WebPage",
        "name": "Practice Loksewa MCQs for Free - LoksewaBook",
        "description": "Elevate your Loksewa exam preparation with LoksewaBook's free practice sets. Access carefully curated practice sets designed to align with Loksewa exam patterns. These sets offer invaluable preparation material, ensuring your success in the Loksewa exams. Practice unlimited sets according to your field, whether you're a civil engineer or in another profession. LoksewaBook: Where knowledge knows no bounds and excellence is attainable.",
        "url": "https://loksewabook.com/practice-set-page",
        "inLanguage": "en",
        "datePublished": "2022-12-13",
        "dateModified": "2023-10-31",
        "author": {
          "@type": "Person",
          "name": "Your Name"
        },
        "publisher": {
          "@type": "Organization",
          "name": "LoksewaBook",
          "logo": {
            "@type": "ImageObject",
            "url": "https://loksewabook.com/logo.png"
          }
        }
        }`}
          </script>

        </Helmet>
        <Navbar admin={admin} />
        <div className="practiceExam">
          <div className="practiceExam_container">
            <div className="practiceExam_bottom">
              <div className="practiceExam_bottom_Title">
                <span className="practiceExam_bottom_header">
                  Available Practice Exams
                </span>

                <div>
                  {admin ? (
                    <button
                      className="practiceExam_bottom_filterbtn"
                      onClick={() => {
                        navigate("/admin/add-practice-set");
                      }}
                    >
                      Add New
                    </button>
                  ) : (
                    <></>
                  )}
                  <FilterPopup
                    trigger={
                      <button className="practiceExam_bottom_filterbtn">
                        Filter
                      </button>
                    }
                    content={tags}
                    select={filter}
                    setSelect={setFilter}
                    applyFilter={applyFilter}
                  />
                </div>
              </div>
              <div>
                <DisplayPracticeSetCard
                  maxItemPerPage={3}
                  data={practiceSet}
                  admin={admin}
                />
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default PracticeSetPage;
